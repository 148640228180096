import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ProfileState, SessionSpaceState } from 'recoilTools';
import { SpaceType, ProfileType } from 'models';
import { useLocation, matchPath } from 'react-router';
import { publicRoutesKeys, publicRoutes } from 'router/routes';

/************************************/
/* HOOK : Session Space */

export const useSpaceDetection = () => {
  const profileState: ProfileType = useRecoilValue(ProfileState);
  const [sessionSpaceName, setSessionSpaceName] = useState<string>('');
  const location = useLocation();

  const getSpaceFromUrl = (url: string): string | undefined => {
    const tab: Array<string> = url.split('/');
    const pos: number = tab.indexOf('spaces');
    return pos !== -1 ? tab[pos + 1] : undefined;
  };

  const stripeSpaceFromUrl = (url: string): string => {
    const tab: Array<string> = url.split('/');
    const pos: number = tab.indexOf('spaces');
    if (pos === -1) {
      return url;
    } else {
      tab.shift();
      tab.shift();
      tab.shift();
      return '/' + tab.join('/');
    }
  };

  /* detect sessionSpaceName ************/

  const isPublicPage = !!publicRoutesKeys.find((key) => {
    return matchPath(
      { path: publicRoutes[key].path },
      stripeSpaceFromUrl(location.pathname),
    );
  });

  const spaceFromUrl: string | undefined = getSpaceFromUrl(location.pathname);

  const spaceFromProfile: string | undefined =
    profileState?.current_space_filter
      ? profileState.current_space_filter
      : undefined;

  const sessionSpaceState = useRecoilValue<SpaceType | undefined>(
    SessionSpaceState,
  );

  useEffect(() => {
    if (isPublicPage !== undefined) {
      if (isPublicPage) {
        if (spaceFromUrl) {
          setSessionSpaceName(spaceFromUrl);
        } else {
          setSessionSpaceName('default');
        }
      } else {
        if (profileState?.username) {
          // if profile is loaded
          if (profileState?.current_space_filter) {
            // if profile was connected with a space
            setSessionSpaceName(profileState?.current_space_filter);
          } else if (!sessionSpaceState) {
            // if profile was connected without space
            setSessionSpaceName('default');
          }
        } else {
          // profile is probably loading, wait...
        }
      }
    }
  }, [
    setSessionSpaceName,
    spaceFromUrl,
    spaceFromProfile,
    profileState?.current_space_filter,
    profileState?.username,
    isPublicPage,
    sessionSpaceState,
  ]);

  const spaceDetection = useCallback(() => {
    return sessionSpaceName;
  }, [sessionSpaceName]);

  return { spaceDetection, getSpaceFromUrl };
};
