import { useEffect } from 'react';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { checkSession } from 'api';
import { AuthState, ProfileState } from 'recoilTools';
import { routes } from 'router/routes';
import { useLocation } from 'react-router-dom';
import axiosAuthorized from 'api/axiosAuthorized';

const CHECKSESSION_DELAY = 60_000;

interface WithAuthProps {
  children: ReactJSXElement;
}

/** WithAuth COMPONENT *********************************************************/

const WithAuth = ({ children }: WithAuthProps) => {
  /* -- Const ------------------------------------------------------------- */
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();

  /* -- States ------------------------------------------------------------- */

  const setProfile = useSetRecoilState(ProfileState);
  const [authState, setAuthState] = useRecoilState(AuthState);

  /* -- Effects ------------------------------------------------------------- */

  // backend can force reload profile detail by redirection to this app with ?resetAuth param
  useEffect(() => {
    if (searchParams.get('resetAuth') !== null) {
      setProfile({});
      setAuthState(true);
    }
  }, [searchParams, setProfile, setAuthState]);

  // on page change, check if session has_pending_logout
  useEffect(() => {
    if (authState) {
      checkSession().then((data) => {
        if (data?.has_pending_logout) {
          navigate(routes.logoutPropagate.path);
        }
      });
    }
  }, [navigate, location.pathname, authState]);

  // regularly check if session has_pending_logout
  useEffect(() => {
    const timeout = setInterval(() => {
      if (authState) {
        checkSession().then((data) => {
          if (data.has_pending_logout) {
            navigate(routes.logoutPropagate.path);
          }
        });
      }
    }, CHECKSESSION_DELAY);
    return () => clearInterval(timeout);
  }, [navigate, authState]);

  // intercept 401 error on axios calls then clear profile and authState
  useEffect(() => {
    const interceptorId = axiosAuthorized.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error?.response?.status === 401) {
          if (authState) {
            setAuthState(null);
            setProfile({});
          }
        } else {
          return Promise.reject(error);
        }
      },
    );
    return () => axiosAuthorized.interceptors.response.eject(interceptorId);
  }, [setProfile, navigate, authState, setAuthState]);

  /* -- Return ------------------------------------------------------------- */

  return <>{children}</>;
};

export default WithAuth;
