import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  NewsletterEdugoPageStyled,
  TitleStyled,
  BackButtonStyled,
  BackImageStyled,
} from './NewsletterEdugo.styles';
import backIcon from 'assets/images/backIcon.svg';
import { routes } from 'router/routes';
import { PrivateLayout } from 'components/layout';
import { useMedia } from 'hooks';
import { useTheme } from 'styled-components';

type NewsletterDominoPropsType = {};

const NewsletterEdugo = (props: NewsletterDominoPropsType) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.newsletters',
  });
  const { isLargerThan767 } = useMedia();
  const theme = useTheme();
  const navigate = useNavigate();

  return (
    <PrivateLayout>
      <NewsletterEdugoPageStyled
        isLargerThan767={isLargerThan767}
        primaryColor={theme.colors.primary}
      >
        <BackButtonStyled
          id="ButtonBack"
          onClick={() => navigate(`/${routes.home.path}`)}
        >
          <BackImageStyled src={backIcon} />
          {t('return')}
        </BackButtonStyled>
        <TitleStyled>
          {t('subscribeToNewsletter', { product: 'Edugo' })}
        </TitleStyled>
        // @TODO: place here the iframe with the form
      </NewsletterEdugoPageStyled>
    </PrivateLayout>
  );
};

export default NewsletterEdugo;
