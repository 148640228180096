import { useEffect, useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupedSpaceType, GroupedStructureType } from 'models';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState, StructureState } from 'recoilTools';
import { getGroupedStructures } from 'api';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { useCustomMatomo } from 'hooks';
import {
  StructureService,
  SpacesModulesGrid,
  DominoSiteButton,
} from 'components/atoms';
import {
  StructuresContainerStyled,
  ServicesSectionStyled,
  ServicesListWrapper,
  ServicesListStyled,
  NoServicesAvailableLabelStyled,
  TitleButtonWrapper,
} from 'pages/private/Home/Home.styles';

const StructuresView = () => {
  /* -- Const ------------------------------------------------------------- */

  const theme = useTheme();
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const structure = useRecoilValue(StructureState);
  const [groupedStructure, setGroupedStructure] = useState<
    GroupedStructureType | undefined
  >(undefined);

  const { t } = useTranslation('translation', {
    keyPrefix: 'user.home',
  });
  const { trackMatomoEvent } = useCustomMatomo();

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const groupedStructuresQuery = useQuery(
    'getGroupedStructures',
    getGroupedStructures,
    {
      retry: false,
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onModuleAccess = useCallback(
    (props: { title: string; accessUri: string }) => {
      trackMatomoEvent({
        category: 'Usage',
        action: 'Access module',
        name: `${props.title}`, // optional
      });
    },
    [trackMatomoEvent],
  );

  /* -- Effects ------------------------------------------------------------- */

  useEffect(() => {
    setGroupedStructure(
      groupedStructuresQuery.data?.find(
        (ets) => ets.structure_id === structure?.structure_id,
      ),
    );
  }, [groupedStructuresQuery.data, structure]);

  useEffect(() => {
    groupedStructure?.spaces?.forEach((space) => {
      trackMatomoEvent({
        category: 'Usage',
        action: 'Display bouquet',
        name: `${space.space_name} (${space.space_key})`, // optional
      });
    });
  }, [
    groupedStructure?.structure_id,
    groupedStructure?.spaces,
    sessionSpace?.space_key,
    trackMatomoEvent,
  ]);

  /* -- Memos ------------------------------------------------------------- */

  const groupedStructureSpaceMatch: GroupedSpaceType | undefined =
    useMemo(() => {
      if (sessionSpace?.space_key === 'default') return undefined;
      return groupedStructure?.spaces?.find(
        (space) => space.space_key === sessionSpace?.space_key,
      );
    }, [groupedStructure, sessionSpace]);

  const hasModules: boolean = useMemo(
    () =>
      sessionSpace?.space_key === 'default' &&
      groupedStructure?.spaces
        .map((space) => space.modules.length)
        .reduce((acc, itemCount) => acc + itemCount, 0) !== 0,
    [sessionSpace, groupedStructure],
  );

  /* -- Return ------------------------------------------------------------- */

  return (
    <StructuresContainerStyled>
      <ServicesSectionStyled>
        <TitleButtonWrapper>
          {sessionSpace?.space_key === 'domino' && structure?.is_teacher && (
            <DominoSiteButton />
          )}
        </TitleButtonWrapper>
        {sessionSpace?.space_key === 'default' &&
          groupedStructure &&
          (hasModules ? (
            <SpacesModulesGrid
              structureIdentifier={groupedStructure?.structure_identifier}
              structureSpaces={groupedStructure?.spaces}
              onModuleAccess={onModuleAccess}
            />
          ) : (
            <NoServicesAvailableLabelStyled>
              {t('noServiceAvailable')}
            </NoServicesAvailableLabelStyled>
          ))}
        {sessionSpace &&
          sessionSpace.space_key !== 'default' &&
          groupedStructure &&
          (groupedStructureSpaceMatch ? (
            <ServicesListWrapper
              style={{
                backgroundColor: 'rgba(' + theme.colors.primary + ')',
              }}
            >
              <ServicesListStyled>
                {groupedStructureSpaceMatch.modules.map(
                  ({
                    module_title,
                    module_image_uri,
                    module_description,
                    module_access_uri,
                    module_information_file_uri,
                  }) => (
                    <li key={module_title}>
                      <StructureService
                        color={theme.colors.primary}
                        structureIdentifier={structure?.structure_identifier}
                        title={module_title}
                        description={module_description}
                        imageUri={module_image_uri}
                        accessUri={module_access_uri}
                        informationFileUri={module_information_file_uri}
                        onModuleAccess={onModuleAccess}
                      />
                    </li>
                  ),
                )}
              </ServicesListStyled>
            </ServicesListWrapper>
          ) : (
            <NoServicesAvailableLabelStyled>
              {t('noServiceAvailableWithSpace', {
                spaceName: sessionSpace.name,
              })}
            </NoServicesAvailableLabelStyled>
          ))}
      </ServicesSectionStyled>
    </StructuresContainerStyled>
  );
};

export default StructuresView;
