// Primary
export const primaryRegular = '#94C11F';
export const primaryLight = '#FBFFFA';
export const primaryMedium = '#ECF9F1';
export const primaryHover = '#ECFFBB;';

// Neutral
export const neutralWhite = '#FFFFFF';
export const neutralLighter = '#F9FAFB';
export const neutralLight = '#EAEDF1';
export const neutralMedium = '#BDBDBD';
export const neutralDark = '#585D62';
export const neutralDarker = '#1F262D';
export const neutralBlack = '#000000';

// System
export const infoColor = '#17A2B8';
export const error = '#C11F1F';
export const errorLight = '#FFFBFA';

// Other
export const secondaryText = '#4F6173';
export const primaryText = '#1F262D';
export const secondaryMain = '#302E35';

export const boxShadow = '#E0E4EB';
export const borderColor = '#EAEDF1';
