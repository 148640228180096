import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PublicLayout } from 'components/layout';
import {
  AccountActivatedPageStyled,
  ButtonsBlockStyled,
  ButtonStyled,
} from './AccountActivated.styles';
import { useSpacePath } from 'hooks';
import { routes } from 'router/routes';

const AccountActivated = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.accountActivated',
  });
  const spacePath: string = useSpacePath();
  const navigate = useNavigate();

  const urlParams = new URLSearchParams(window.location.search);
  const username = urlParams.get('username');

  useEffect(() => {
    if (!username) {
      navigate(`/${spacePath}${routes.login.path}`);
    }
  }, [navigate, spacePath, username]);

  const onClick = useCallback(() => {
    navigate(`/${spacePath}${routes.login.path}`);
  }, [navigate, spacePath]);

  return (
    <PublicLayout
      backButtonText={t('return')}
      title={t('title')}
      breadcrumb={[
        {
          name: t('title'),
        },
      ]}
    >
      <AccountActivatedPageStyled>
        <div className="instruction">
          <div>{t('instruction', { username: `${username}` })}</div>
        </div>
        <br />
        <br />
        <ButtonsBlockStyled>
          <ButtonStyled
            state="primary"
            type="submit"
            label={t('buttonText')}
            onClick={onClick}
            imgSide={undefined}
          />
        </ButtonsBlockStyled>
      </AccountActivatedPageStyled>
    </PublicLayout>
  );
};

export default AccountActivated;
