import { ENDPOINTS } from 'appConstants';
import { IdentityType } from 'models';
import axiosAuthorized from './axiosAuthorized';

// GET OPTIONS FOR DISAMBIGUATION SSO LOGIN ATTEMPT (private) -------------

type DisambiguatePayloadType = {
  service_provider_id: number | null;
  ambiguous_continue: string | null;
};

export type DisambiguateResponseType = {
  identities?: IdentityType[];
};

export const disambiguate = (
  payload: DisambiguatePayloadType,
): Promise<DisambiguateResponseType> =>
  axiosAuthorized
    .post<DisambiguateResponseType>(ENDPOINTS.disambiguate, payload)
    .then((response) => response.data);
