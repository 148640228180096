import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { matchPath } from 'react-router-dom';
import { stripeSpaceFromUrl } from 'utils';
import { useMutation } from 'react-query';

import { getProfileDetails } from 'api';
import { ProfileState } from 'recoilTools';
import { INITIAL_LANGUAGE } from 'appConstants';
import { privateRoutes, privateRoutesKeys } from 'router/routes';
import { useLocation } from 'react-router-dom';

interface WithUserProfileProps {
  children: ReactJSXElement;
}

/** WithUserProfile COMPONENT *********************************************************/

const WithUserProfile = ({ children }: WithUserProfileProps) => {
  /* -- Const ------------------------------------------------------------- */
  const { i18n } = useTranslation();
  const location = useLocation();

  /* -- States ------------------------------------------------------------- */

  const [profile, setProfile] = useRecoilState(ProfileState);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const getProfileDetailsQuery = useMutation(
    'getProfileDetails',
    getProfileDetails,
    {
      retry: false,
      onSuccess: (data) => {
        setProfile(data);
      },
    },
  );

  /* -- Effects ------------------------------------------------------------- */

  // load profile if page is private
  useEffect(() => {
    const isPrivatePage = !!privateRoutesKeys.find((key) => {
      return matchPath(
        { path: privateRoutes[key].path },
        stripeSpaceFromUrl(location.pathname),
      );
    });
    if (
      isPrivatePage &&
      !profile?.id &&
      // !getProfileDetailsQuery.data &&
      !getProfileDetailsQuery.isLoading
    ) {
      getProfileDetailsQuery.mutate();
    }
  }, [profile?.id, getProfileDetailsQuery, location.pathname]);

  // if profile culture_name changes
  useEffect(() => {
    if (profile?.culture_name) {
      i18n.changeLanguage(profile?.culture_name || INITIAL_LANGUAGE);
    }
  }, [profile?.culture_name, i18n]);

  /* -- Return ------------------------------------------------------------- */

  return <>{children}</>;
};

export default WithUserProfile;
