import { useEffect } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';

import { getStructures } from 'api';
import { ProfileState, StructuresState, StructureState } from 'recoilTools';
import { StructureType } from 'models';

interface WithStructuresProps {
  children: ReactJSXElement;
}

/** WithStructures COMPONENT *********************************************************/

const WithStructures = ({ children }: WithStructuresProps) => {
  /* -- Const ------------------------------------------------------------- */
  const [searchParams] = useSearchParams();

  /* -- States ------------------------------------------------------------- */

  const profile = useRecoilValue(ProfileState);
  const [structures, setStructures] = useRecoilState(StructuresState);
  const setCurrentStructure = useSetRecoilState(StructureState);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const getStructuresMutation = useMutation('getStructures', getStructures, {
    retry: false,
    onSuccess: (data) => {
      const dataStructures = data as StructureType[];
      if (dataStructures) {
        setStructures(dataStructures);
        if (dataStructures.length) {
          setCurrentStructure(dataStructures[0]);
        }
      }
    },
  });

  /* -- Effects ------------------------------------------------------------- */

  // reset structures if !profile
  useEffect(() => {
    if (!profile?.id) {
      setStructures(undefined);
      setCurrentStructure(undefined);
    }
  }, [searchParams, profile, setStructures, setCurrentStructure]);

  // after profile loaded, load structures
  useEffect(() => {
    if (profile?.id && !structures && !getStructuresMutation.isLoading) {
      setStructures(undefined);
      setCurrentStructure(undefined);
      getStructuresMutation.mutate();
    }
  }, [
    profile?.id,
    structures,
    setStructures,
    getStructuresMutation,
    setCurrentStructure,
  ]);

  /* -- Return ------------------------------------------------------------- */

  return <>{children}</>;
};

export default WithStructures;
