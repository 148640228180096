import { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';
import { ModalState } from 'recoilTools';
import { ContainerStyled } from './GlobalModal.styles';
import { Modal } from '@maskott/tactileo.designsystem';
import { ModalType } from 'models';

const GlobalModal = () => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'modalAsync',
  });
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  /* -- States ------------------------------------------------------------- */

  const [modalState, setModalState] = useRecoilState(ModalState);
  const [iframeReady, setIframeReady] = useState<boolean>(false);

  /* -- Callbacks ------------------------------------------------------------- */

  const onModalCancel = useCallback(() => {
    setModalState(
      () =>
        ({
          ...modalState,
          status: 'cancelled',
          iframeRef: undefined,
        }) as ModalType,
    );
  }, [modalState, setModalState]);

  const onModalClose = useCallback(() => {
    setModalState(
      () =>
        ({
          ...modalState,
          status: 'cancelled',
          iframeRef: undefined,
        }) as ModalType,
    );
  }, [modalState, setModalState]);

  const onModalAction = useCallback(() => {
    setModalState(
      () =>
        ({
          ...modalState,
          status: 'accepted',
          iframeRef: iframeRef.current ? iframeRef.current : undefined,
        }) as ModalType,
    );
  }, [modalState, setModalState]);

  /* -- Effect ------------------------------------------------------------- */

  // listen to messages coming from iframe
  useEffect(() => {
    const handler = (ev: MessageEvent<{ type: string; message: string }>) => {
      if (typeof ev.data !== 'object') return;
      if (!ev.data.type) return;
      if (ev.data.type !== 'ready') return;

      if (modalState?.iframeMessage) {
        iframeRef.current?.contentWindow?.postMessage(modalState.iframeMessage);
      }
      setIframeReady(true);
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, [iframeRef, setIframeReady, modalState]);

  useEffect(() => {
    if (!modalState || !modalState?.iframeUrl) {
      setIframeReady(false);
    }
  }, [modalState]);

  /* -- Return ------------------------------------------------------------- */

  return modalState ? (
    <ContainerStyled>
      <Modal
        isAriaHidden={`${!modalState}`}
        size={modalState?.size || 'L'}
        isOpen={!!modalState}
        title={modalState?.title}
        showHeader={!!modalState?.title}
        ActionLabel={modalState?.actionTitle || '...'} // || t('pleaseWait')
        CancelLabel={t('cancel')}
        ActionFunc={modalState?.actionTitle ? onModalAction : undefined}
        CancelFunc={modalState?.actionTitle ? onModalCancel : undefined}
        actionButtonDisabled={
          !modalState?.actionTitle || (!!modalState.iframeUrl && !iframeReady)
        }
        closeModal={onModalClose}
      >
        <br />
        {/* <div style={{ wordWrap: 'normal' }}>{JSON.stringify(modalState)}</div> */}
        {modalState?.iframeUrl ? (
          <iframe
            ref={iframeRef}
            width="100%"
            height="300px"
            src={modalState.iframeUrl}
          />
        ) : (
          modalState?.message
        )}
      </Modal>
    </ContainerStyled>
  ) : (
    <></>
  );
};

export default GlobalModal;
