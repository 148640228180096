import { error, neutralLight, secondaryMain } from 'assets/colors';
import { RoundedCornerButton } from 'components/atoms';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { pxToRem, mb, ma, pa } from '@maskott/tactileo.designsystem';

interface MediaProps {
  isLargerThan767: boolean;
}

export const CreateDemoAccountPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 767px) {
    input {
      border: 1px solid rgb(${(props) => props.theme.colors.primary});
      box-shadow: 0px 1px 0px rgb(${(props) => props.theme.colors.primary});
    }
    input:focus {
      border: 2px solid rgb(${(props) => props.theme.colors.primary});
      background-color: ${neutralLight};
    }
    .errorState {
      border: 2px solid ${error}!important;
      box-shadow: 0px 1px 0px ${error};
      background-color: #fce8e8 !important;
    }
  }
`;

export const CreateDemoAccountForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
  ${mb(3)};
  width: 100%;
`;

export const FieldLabel = styled.label`
  font-weight: 400;
  font-size: ${pxToRem(17)};
  line-height: ${pxToRem(20)};
  color: black;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
  width: 100%;
`;

export const FieldError = styled.span`
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(15)};
  color: ${error};
`;

export const NameFieldsWrapper = styled.div<MediaProps>`
  display: flex;
  gap: ${pxToRem(16)};
  flex-direction: ${({ isLargerThan767 }) =>
    isLargerThan767 ? 'row' : 'column-reverse'};
  width: 100%;
`;

export const PasswordFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
`;

export const SmallLinkStyled = styled(Link)`
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(12)};
  color: ${secondaryMain};
`;

export const SmallText = styled.p`
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(15)};
  color: black;
  ${ma(0)};
`;

export const Text = styled.p`
  font-weight: 400;
  color: black;
  ${ma(0)};
`;

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: nowrap;
  margin-top: 10px;
  width: 100%;
`;

export const CheckboxWrapperStyledTop = styled.div`
  flex-wrap: nowrap;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: top;
`;

export const CheckBoxLabelStyled = styled.label`
  padding-top: 5px;
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: 1.4em;
  color: ${secondaryMain};
`;

export const ButtonsWrapper = styled.div<MediaProps>`
  display: flex;
  flex-direction: ${({ isLargerThan767 }) =>
    isLargerThan767 ? 'row' : 'column-reverse'};
  justify-content: stretch;
  /* justify-content: space-between;
  flex-wrap: wrap; */
  align-items: center;
  gap: ${pxToRem(24)};
  width: 100%;
`;

export const CancelButton = styled(RoundedCornerButton)`
  font-weight: 700;
`;

export const FieldSet = styled.fieldset`
  &.optional {
    border: 3px solid rgb(${(props) => props.theme.colors.primary});
    ${pa(2)}
  }
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Legend = styled.legend`
  font-weight: 400;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(12)};
  color: ${secondaryMain};
`;

export const CreateDemoAccountButton = styled(RoundedCornerButton)`
  flex-grow: 1;
`;

export const SmallAlert = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${error};
  margin: 0;
`;
