import { useTranslation } from 'react-i18next';

import { DominoSiteButtonLink } from './DominoSiteButton.styles';

const DOMINO_SITE_URL = 'https://domino.education/';

const DominoSiteButton = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'atoms.dominoSiteButton',
  });

  return (
    <div>
      <DominoSiteButtonLink
        role="button"
        aria-label={t('ariaLabel')}
        target="_blank"
        rel="noopener noreferrer"
        href={DOMINO_SITE_URL}
      >
        <img src="/images/compagnon-domino.png" width="25" alt="" />
        <span>{t('label')}</span>
      </DominoSiteButtonLink>
    </div>
  );
};

export default DominoSiteButton;
