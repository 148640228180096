import { useEffect, useState, useCallback } from 'react';
import { generatePath, useParams, useNavigate } from 'react-router-dom';
import { routes } from 'router/routes';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import {
  getStructureClassroom,
  getLearnerAccounts,
  getLearnerAccount,
} from 'api';
import { LearnerAccountType, StructureClassroomType } from 'models';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { getRootUrl } from 'utils';

const StudentsPrint = () => {
  /* -- Const ------------------------------------------------------------- */
  const params = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const structureId: number = parseInt(params['structureId'] || '');
  const classroomId: number = parseInt(params['classroomId'] || '');
  const accountId: number | undefined = params['accountId']
    ? parseInt(params['accountId'])
    : undefined;

  /* -- States ------------------------------------------------------------- */

  const [passwordsList, setPasswordsList] = useState<
    { structure_id: number; learner_account_id: number; password: string }[]
  >([]);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const structureClassroomQuery = useQuery(
    ['structureClassroomQuery', structureId, classroomId],
    () => {
      if (structureId) {
        return getStructureClassroom(structureId, classroomId);
      }
      return Promise.reject(null);
    },
    {
      refetchOnWindowFocus: false,
      enabled: true,
      retry: false,
      cacheTime: 0,
      onError: (
        error: AxiosError<{ error_message: string; reason: string[] | null }>,
      ) => {
        if (error.response?.status === 404) {
          navigate(generatePath(`/${routes.studentsClassrooms.path}`));
        }
      },
    },
  );

  const learnerAccountsQuery = useQuery(
    ['learnerAccountsQuery', structureId],
    () => {
      return getLearnerAccounts(structureId, classroomId);
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      cacheTime: 0,
    },
  );

  const learnerAccountQuery = useQuery(
    ['learnerAccountQuery', structureId],
    () => {
      return getLearnerAccount(structureId, accountId || -1);
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      cacheTime: 0,
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const getPassword = useCallback(
    (learner_account_id: number): string | undefined => {
      const match = passwordsList.find((item) => {
        return item.learner_account_id === learner_account_id;
      });
      return match ? match.password : undefined;
    },
    [passwordsList],
  );

  /* -- Effects ------------------------------------------------------------- */

  // initialize datas
  useEffect(() => {
    if (accountId && !learnerAccountQuery.data) {
      learnerAccountQuery.refetch();
    }
  }, [accountId, learnerAccountQuery]);

  useEffect(() => {
    if (!accountId && !learnerAccountsQuery.data) {
      learnerAccountsQuery.refetch();
    }
  }, [accountId, learnerAccountsQuery]);

  // send ready message to parent
  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'ready',
      },
      '*',
    );
  }, []);

  // wait for password coming parent
  useEffect(() => {
    const handler = (
      ev: MessageEvent<{
        type: string;
        payload: {
          structure_id: number;
          learner_account_id: number;
          password: string;
        }[];
      }>,
    ) => {
      if (typeof ev.data !== 'object') return;
      if (!ev.data.type) return;
      if (ev.data.type !== 'passwords') return;
      if (!ev.data.payload) return;

      setPasswordsList(ev.data.payload);
    };

    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  /* -- Return ------------------------------------------------------------- */

  return (
    <StudentsPrintStyle>
      <div className="title">
        <h1>
          {t('studentAccess', {
            level: structureClassroomQuery.data?.level,
            name: structureClassroomQuery.data?.name,
          })}
        </h1>
      </div>
      <div className="cards">
        {structureClassroomQuery.data &&
          !structureClassroomQuery.isLoading &&
          !learnerAccountsQuery.isLoading &&
          learnerAccountsQuery?.data?.map(
            (_account) =>
              _account?.learner_account_id && (
                <ConnectionCard
                  key={_account.learner_account_id}
                  classroom={structureClassroomQuery.data}
                  account={{
                    ..._account,
                    password: getPassword(_account.learner_account_id),
                  }}
                />
              ),
          )}
        {!structureClassroomQuery.isLoading &&
          structureClassroomQuery.data &&
          learnerAccountQuery.data && (
            <ConnectionCard
              classroom={structureClassroomQuery.data}
              account={{
                ...learnerAccountQuery.data,
                password: getPassword(
                  learnerAccountQuery.data.learner_account_id,
                ),
              }}
            />
          )}
      </div>
    </StudentsPrintStyle>
  );
};

/** ConnectionCard COMPONENT *********************************************************/

const ConnectionCard = ({
  classroom,
  account,
}: {
  classroom: StructureClassroomType;
  account: LearnerAccountType;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });

  const url = getRootUrl() + generatePath(routes.loginStudent.path);

  return (
    <div className="ConnectionCard">
      <div className="title">
        {t('personalAccess', {
          firstname: account.first_name,
          lastname: account.last_name,
        })}
      </div>
      <div className="link d-flex">
        <span className="label">{t('gotoPage')} :</span>
        <span className="value">{url}</span>
      </div>
      <div className="d-flex">
        <div className="classroomCode col-sm-6 d-flex">
          <span className="label">{t('classroomCode')} :</span>
          <span className="value">{classroom.classroom_key}</span>
          <span className="label">{t('username')} :</span>
          <span className="value">{account.login_name}</span>
        </div>
      </div>
      <div className="password d-flex">
        <span className="label">{t('password')} :</span>
        <span className="value">{account.password}</span>
      </div>
    </div>
  );
};

export default StudentsPrint;

/* -- Style ------------------------------------------------------------- */

const StudentsPrintStyle = styled.div`
  max-width: 850px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  text-align: center;
  min-height: 100%;
  gap: 30px;
  padding: 60px 30px 30px 30px;
  .ConnectionCard {
    border: dotted 1px black;
    justify-content: left;
    text-align: left;
    padding: 10px 15px;
    .label {
      min-width: 200px;
      padding-right: 10px;
      text-align: right;
    }
    .value {
      white-space: nowrap;
    }
  }
  .print {
    button {
      padding: 0 5px;
    }
  }
  @media print {
    max-width: none;
    .print {
      display: none;
    }
    .ConnectionCard {
      break-inside: avoid;
    }
  }
`;
