import { Link } from 'react-router-dom';
import {
  error,
  neutralDarker,
  neutralLight,
  secondaryMain,
} from 'assets/colors';
import styled from 'styled-components';

export const ForgotPasswordPageStyled = styled.div`
  width: 100%;
`;

export const FieldStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media only screen and (max-width: 767px) {
    input {
      border: 1px solid rgb(${(props) => props.theme.colors.primary});
      box-shadow: 0px 1px 0px rgb(${(props) => props.theme.colors.primary});
    }
    input:focus {
      border: 2px solid rgb(${(props) => props.theme.colors.primary});
      background-color: ${neutralLight};
    }
    .errorState {
      border: 2px solid ${error}!important;
      box-shadow: 0px 1px 0px ${error};
      background-color: #fce8e8 !important;
    }
  }
`;

export const FieldErrorStyled = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${error};
`;

export const LabelStyled = styled.label`
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: black;
`;

export const SmallTextStyled = styled.p`
  font-weight: 400;
  font-size: 16px;
  line-height: 1.2em;
  color: black;
  margin: 24px 0 20px 0;
`;

export const BoldTextStyled = styled.h3`
  color: ${neutralDarker};
  font-weight: 700;
  font-size: 16px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.35px;
  align-self: center;
`;

export const BigTextStyled = styled.h2`
  color: ${neutralDarker};
  font-weight: 400;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.35px;
  align-self: center;
`;

export const CheckBoxLabelStyled = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${secondaryMain};
`;

export const SuccessIconStyled = styled.img`
  align-self: center;
  margin-bottom: 24px;
`;
export const SmallLinkStyled = styled(Link)`
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  text-decoration-line: underline;
  color: ${neutralDarker};
  width: 100%;
  margin: 0 3px;
`;
