import { useState, useCallback, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { modifyStructureClassroom, deleteStructureClassroom } from 'api';
import { StructureClassroomType } from 'models';
import { StructureState } from 'recoilTools';
import { useRecoilValue } from 'recoil';
import { AxiosError } from 'axios';
import { useError } from 'hooks';
import { toast } from 'react-toastify';
import StudentsClassroomsLineForm from './StudentsClassroomsLineForm';
import StudentsClassroomsLine from './StudentsClassroomsLine';
import { useModal } from 'hooks/useModal';
import TableHolder from 'components/atoms/PlaceHolders/TableHolder';

/** StudentsClassrooms COMPONENT *********************************************************/

const StudentsClassroomsList = ({
  disabled,
  list,
  onChange,
}: {
  disabled: boolean;
  list: StructureClassroomType[] | undefined;
  onChange: (status: string) => void;
}) => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const { getMessage } = useError();
  const { showModal, hideModal } = useModal();

  /* -- States ------------------------------------------------------------- */

  const structure = useRecoilValue(StructureState);
  const [classroom, setClassroom] = useState<
    StructureClassroomType | undefined
  >(undefined);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const modifyStructureClassroomMutation = useMutation(
    'modifyStructureClassroom',
    modifyStructureClassroom,
    {
      retry: false,
      onSuccess: () => {
        setClassroom(undefined);
        toast(t('classroomModified'), {
          type: 'success',
        });
        onChange('modified');
      },
      onError: (
        error: AxiosError<{ error_message: string; reason: string[] | null }>,
      ) => {
        toast(getMessage(error.response?.data, 'user.auth.students'), {
          type: 'error',
          autoClose: false,
          closeButton: true,
        });
        onChange('error');
      },
    },
  );

  const deleteStructureClassroomMutation = useMutation(
    'deleteStructureClassroomMutation',
    deleteStructureClassroom,
    {
      retry: false,
      onSuccess: () => {
        toast(
          t('classroomDeleted', {
            structure_name: `${structure?.structure_name} (${structure?.structure_identifier})`,
          }),
          {
            type: 'success',
          },
        );
        onChange('deleted');
      },
      onError: (
        error: AxiosError<{ error_message: string; reason: string[] | null }>,
      ) => {
        toast(getMessage(error.response?.data, 'user.auth.students'), {
          type: 'error',
          autoClose: false,
          closeButton: true,
        });
        onChange('error');
      },
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onSubmitModification = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const target: HTMLFormElement = event.target as HTMLFormElement;
      if (
        structure?.structure_id &&
        classroom?.classroom_id &&
        target.level.value != '' &&
        target.nameInput.value != ''
      ) {
        onChange('modifying');
        modifyStructureClassroomMutation.mutate({
          structure_id: structure?.structure_id,
          classroom_id: classroom?.classroom_id,
          level: target.level.value,
          name: target.nameInput.value,
        });
      }
    },
    [
      structure?.structure_id,
      classroom?.classroom_id,
      modifyStructureClassroomMutation,
      onChange,
    ],
  );

  const onModify = useCallback((_classroom: StructureClassroomType) => {
    setClassroom(_classroom);
  }, []);

  const onCancel = useCallback(() => {
    setClassroom(undefined);
  }, []);

  const onDelete = useCallback(
    async (classroom_id: number) => {
      if (structure?.structure_id) {
        const modalState = await showModal({
          size: 'L',
          action: 'delete',
          title: '',
          actionTitle: t('modalConfirm'),
          message: t('deleteClassroomConfirmation'),
        });
        if (modalState.status === 'accepted') {
          onChange('deleting');
          deleteStructureClassroomMutation.mutate({
            structure_id: structure.structure_id,
            classroom_id,
          });
        }
        hideModal();
      }
    },
    [
      structure?.structure_id,
      t,
      showModal,
      hideModal,
      deleteStructureClassroomMutation,
      onChange,
    ],
  );

  /* -- Effects ------------------------------------------------------------- */

  /* -- Return ------------------------------------------------------------- */

  const mutating: boolean =
    modifyStructureClassroomMutation.isLoading ||
    deleteStructureClassroomMutation.isLoading;

  return (
    <>
      <h2>{t('classroomList')}</h2>
      <form onSubmit={onSubmitModification}>
        <div className="table-responsive-md">
          <table className="table w-100">
            {list && list?.length > 0 && (
              <thead>
                <tr>
                  <th scope="col">{t('classroomLevel')}</th>
                  <th scope="col">{t('classroomName')}</th>
                  <th scope="col">{t('studentsNumber')}</th>
                  <th scope="col" className="text-end">
                    &nbsp;
                  </th>
                </tr>
              </thead>
            )}
            <tbody>
              {list &&
                structure &&
                list.map((_classroom) =>
                  _classroom.classroom_id !== classroom?.classroom_id ? (
                    <StudentsClassroomsLine
                      structureId={structure.structure_id}
                      key={_classroom.classroom_id}
                      classroom={_classroom}
                      onModify={onModify}
                      onDelete={() => {
                        if (_classroom.classroom_id) {
                          onDelete(_classroom.classroom_id);
                        }
                      }}
                      disabled={disabled || !!classroom || mutating}
                      isDeleting={
                        deleteStructureClassroomMutation.isLoading &&
                        _classroom.classroom_id ===
                          deleteStructureClassroomMutation.variables
                            ?.classroom_id
                      }
                    />
                  ) : (
                    <StudentsClassroomsLineForm
                      key={_classroom.classroom_id}
                      initialValue={_classroom}
                      onCancel={onCancel}
                      disabled={disabled}
                      loading={modifyStructureClassroomMutation.isLoading}
                    />
                  ),
                )}
              {!list && <TableHolder lines={4} cols={2} />}
            </tbody>
          </table>
          {list && list.length === 0 && <p>{t('noStructureClassrooms')}</p>}
        </div>
      </form>
    </>
  );
};

export default StudentsClassroomsList;
