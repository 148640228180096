const baseUrl = 'api/';

export const ENDPOINTS = {
  // authentication
  login: baseUrl + 'authentication/login',
  loginStudent: baseUrl + 'authentication/learner/login',
  logout: baseUrl + 'authentication/logout',
  checkSession: baseUrl + 'authentication/check_session',

  // registration
  createWayfAccount: baseUrl + 'registration/wayf/signup',
  askActivationEmail: baseUrl + 'registration/request_activation_code',
  activateAccount: baseUrl + 'registration/activate',
  createDemoAccount: baseUrl + 'registration/demo/signup',

  // recovery
  passwordRecover: baseUrl + 'recovery/password/recover',
  passwordReset: baseUrl + 'recovery/password/reset',
  usernameRecover: baseUrl + 'recovery/username/recover',

  // sso
  disambiguate: baseUrl + 'sso/login/disambiguate',

  // profile
  profileDetails: baseUrl + 'profile/details',
  profileUpdate: baseUrl + 'profile/update',
  profileDelete: baseUrl + 'profile/delete',
  structures: baseUrl + 'profile/accounts',
  modules: baseUrl + 'profile/modules',
  modulesGrouped: baseUrl + 'profile/modules/grouped',
  changePassword: baseUrl + 'profile/change_password',
  becomeTeacher: baseUrl + 'profile/become/teacher',
  leaveStructure: baseUrl + 'profile/leave_structure',
  classrooms: baseUrl + 'profile/classrooms',

  // structures
  structureClassrooms: baseUrl + 'structures/{structureId}/classrooms',
  structureLearnerAccounts:
    baseUrl + 'structures/{structureId}/learner_accounts',

  // public
  publicSpaces: baseUrl + 'public/spaces',
  publicClassroom: baseUrl + 'public/classrooms',
  publicMailinglists: baseUrl + 'public/spaces/{spaceKey}/mailing_lists',
};

// iframe to logout from modules
export const LOGOUT_IFRAME = 'portal/sso/logout/iframe';
