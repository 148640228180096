import styled from 'styled-components';

interface StructureServiceProps {
  color: string;
}

export const StructureServiceStyle = styled.div<StructureServiceProps>`
  width: 100%;
  height: 100%;

  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: 0.2s;
  &:hover {
    transform: scale(1.03);
  }
  .serviceLink {
    display: block;
    width: 100%;
    height: 100%;
    border-radius: 30px;
    padding: 15px 15px 70px 15px;
    text-decoration: none;
    background-color: white;
    color: black;
    .serviceLogo {
      width: 100%;
      height: 77px;
      object-fit: contain;
    }
    .serviceInfos {
      height: 150px;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px; /* width of the entire scrollbar */
      }

      &::-webkit-scrollbar-track {
        background: #eeeeee; /* color of the tracking area */
        border-radius: 5px; /* roundness of the scroll thumb */
      }

      &::-webkit-scrollbar-thumb {
        background-color: rgba(
          ${(props: any) => props.color}
        ); /* color of the scroll thumb */
        border-radius: 5px;
      }
      @media only screen and (max-width: 767px) {
        & {
          height: auto;
          overflow-y: hidden;
        }
      }
    }
    .serviceTitle {
      font-size: 20px;
      font-weight: bold;
      line-height: 1.2em;
      margin-bottom: 10px;
    }
  }
  .serviceButton {
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 20px;
    background-color: transparent;
    border: none;
    color: rgb(${(props: any) => props.color});
    transition: 0.2s;
    &:hover {
      transform: scale(1.3);
    }
    .serviceIcon {
      width: 35px;
      height: 35px;
      margin: 5px;
      object-fit: contain;
    }
  }
  .serviceInfoButton {
    left: 0;
    right: auto;
    .serviceIcon {
    }
  }
`;
