import styled from 'styled-components';

const DOMINO_BUTTON_COLOR = '#001cb0';

export const DominoSiteButtonLink = styled.a`
  display: flex;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  color: #333;
  box-shadow:
    0 4px 24px rgb(0 0 0 / 10%),
    0 2px 6px rgb(0 0 0 / 5%),
    0 3px 1px -2px rgb(0 0 0 / 5%);
  background-color: white;
  font-size: 16px;
  border: none;
  border-radius: 100px;
  padding: 10px 20px;
  img {
    transform: rotate(0deg) scale(1);
    transition: all 0.5s;
  }
  &:hover {
    box-shadow:
      0 4px 24px rgb(0 0 0 / 10%),
      0 2px 6px rgb(0 0 0 / 10%),
      0 3px 1px -2px rgb(0 0 0 / 10%);
    color: ${DOMINO_BUTTON_COLOR};
    img {
      transform: rotate(-15deg) scale(1.1);
    }
  }
`;
