import { useEffect, useMemo, useState } from 'react';

import { FormInput } from '@maskott/tactileo.designsystem';
import { SecurityIndicatorVariants } from 'appConstants';

type PasswordInputProps = {
  id?: string;
  state: null | 'error' | 'success';
  placeholder?: string;
  value?: string;
  disabled?: boolean;
  qualityIndicator?: boolean;
  onQualityChanged?: (quality?: SecurityIndicatorVariants) => any;
  type?: null | 'normal' | 'password';
  onChange?: (value: string) => any;
  onEyeClicked?: () => any;
  suggestions?: string[];
  suggestionNotFoundMessage?: string;
  eyeIcon?: null | 'hidden' | 'visible';
  leftIcon?: null | 'user' | 'padlock' | 'email' | 'house' | 'check';
  securityIndicator?: SecurityIndicatorVariants;
  onBlur?: () => unknown;
};

const smallCaseLetterRegexp = /[a-z]/;
const capitalLetterRegexp = /[A-Z]/;
const numberRegexp = /[0-9]/;
const specialCharacterRegexp = /[^A-Za-z0-9 ]/;
const spaceRegexp = /\s/;

const checkPasswordQuality = (password: string) => {
  const qualityCases = [
    smallCaseLetterRegexp.test(password),
    capitalLetterRegexp.test(password),
    numberRegexp.test(password),
    specialCharacterRegexp.test(password),
    !spaceRegexp.test(password),
    password.length >= 8,
  ].filter(Boolean);

  if (qualityCases.length === 6) {
    return SecurityIndicatorVariants.HARD;
  }
  if (qualityCases.length > 2) {
    return SecurityIndicatorVariants.MEDIUM;
  }
  return SecurityIndicatorVariants.EASY;
};

// only used for DeleteAccount. FormPasswordInput is used everywhere else !??
const PasswordInput = ({
  value,
  qualityIndicator,
  onQualityChanged,
  state,
  ...rest
}: PasswordInputProps) => {
  const [isShowPassword, setShowPassword] = useState(false);
  const toggleShowHide = () => setShowPassword((prevState) => !prevState);
  const quality = useMemo(
    () => (qualityIndicator && value ? checkPasswordQuality(value) : undefined),
    [value, qualityIndicator],
  );

  useEffect(() => {
    if (onQualityChanged) onQualityChanged(quality);
  }, [quality, onQualityChanged]);

  return (
    <FormInput
      {...rest}
      securityIndicator={quality}
      type={isShowPassword ? 'normal' : 'password'}
      eyeIcon={isShowPassword ? 'visible' : 'hidden'}
      onEyeClicked={toggleShowHide}
      state={state || null}
      value={value}
    />
  );
};

export default PasswordInput;
