const Holder = ({ nb }: { nb: number }) => {
  return (
    <>
      {Array(nb)
        .fill('')
        .map((elt, i) => (
          <p key={i} className="placeholder-glow">
            <span className="placeholder bg-secondary col-12" />
          </p>
        ))}
    </>
  );
};

export default Holder;
