import { ReactNode } from 'react';
import { TopBar } from 'components/parts';
import { Footer } from 'components/parts';
import { StructuresBar } from 'components/parts';
import { useRecoilValue } from 'recoil';
import { ProfileState, StructuresState } from 'recoilTools';
import { PrivateLayoutStyle } from './PrivateLayout.styles';
import { stripeSpaceFromUrl } from 'utils';
import { privateRoutes } from 'router/routes';
import { matchPath } from 'react-router-dom';
import GlobalModal from 'components/parts/GlobalModal/GlobalModal';

type PrivateLayoutProps = { children?: ReactNode };

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  const profile = useRecoilValue(ProfileState);
  const structures = useRecoilValue(StructuresState);
  const pagesWithoutStructuresMenu: string[] = [
    privateRoutes.personalData.path,
    privateRoutes.deleteAccount.path,
  ];
  const showStructuresBar =
    profile &&
    (!profile.current_login_provider ||
      (structures?.length && structures?.length > 1)) &&
    !pagesWithoutStructuresMenu.find((path) => {
      return matchPath({ path }, stripeSpaceFromUrl(location.pathname));
    });

  return (
    <PrivateLayoutStyle>
      <TopBar />
      <StructuresBar show={!!showStructuresBar} />
      {children}
      <Footer />
      <GlobalModal />
    </PrivateLayoutStyle>
  );
};

export default PrivateLayout;
