import { GroupedSpaceType } from 'models';

import {
  SpacesModulesGridList,
  SpacesModulesGridListItem,
  StyledMudulesGrid,
  StructureServiceStyled,
  StructureServicesList,
  StructureServicesListWrapper,
  StructureServicesListItem,
  SpacesModulesGridSpaceLogo,
  SpacesModulesGridLabel,
  SpacesModulesGridSpaceName,
  SpacesModulesGridSpaceDescription,
  SpacesModulesGridTextWrapper,
} from './SpacesModulesGrid.styles';

import assetsThemes from 'assets/themes.json';
import { DefaultTheme } from 'styled-components';
import { DEFAULT_THEME } from 'appConstants';

interface SpacesModulesGridProps {
  structureIdentifier: string | undefined;
  structureSpaces: GroupedSpaceType[];
  onModuleAccess: (props: { title: string; accessUri: string }) => void;
}

const SpacesModulesGrid = ({
  structureIdentifier,
  structureSpaces,
  onModuleAccess,
}: SpacesModulesGridProps) => {
  const themes: DefaultTheme[] = assetsThemes.themes;

  return (
    <SpacesModulesGridList>
      {structureSpaces.map((structureSpace) => (
        <SpacesModulesGridListItem key={structureSpace.space_key}>
          <StyledMudulesGrid>
            <SpacesModulesGridLabel>
              {structureSpace.space_logo_uri && (
                <SpacesModulesGridSpaceLogo
                  alt={`${structureSpace.space_key} logo`}
                  src={structureSpace.space_logo_uri}
                />
              )}
              <SpacesModulesGridTextWrapper>
                <SpacesModulesGridSpaceName>
                  {structureSpace.space_name} -&nbsp;
                </SpacesModulesGridSpaceName>
                <SpacesModulesGridSpaceDescription>
                  {structureSpace.space_description}
                </SpacesModulesGridSpaceDescription>
              </SpacesModulesGridTextWrapper>
            </SpacesModulesGridLabel>
            <StructureServicesListWrapper
              style={{
                backgroundColor:
                  'rgba(' +
                  (
                    themes.find(
                      (th) => th.key === structureSpace.space_theme_key,
                    ) || DEFAULT_THEME
                  )?.colors.primary +
                  ')',
              }}
            >
              <StructureServicesList>
                {structureSpace.modules.map(
                  (
                    {
                      module_title,
                      module_description,
                      module_access_uri,
                      module_image_uri,
                      module_information_file_uri,
                    },
                    index,
                  ) => (
                    <StructureServicesListItem key={module_title}>
                      <StructureServiceStyled
                        color={
                          (
                            themes.find(
                              (th) => th.key === structureSpace.space_theme_key,
                            ) || DEFAULT_THEME
                          )?.colors.primary
                        }
                        structureIdentifier={structureIdentifier}
                        title={module_title}
                        description={module_description}
                        imageUri={module_image_uri}
                        accessUri={module_access_uri}
                        informationFileUri={module_information_file_uri}
                        hideInfoButton={!module_information_file_uri}
                        isLastItem={structureSpace.modules.length - 1 === index}
                        onModuleAccess={onModuleAccess}
                      />
                    </StructureServicesListItem>
                  ),
                )}
              </StructureServicesList>
            </StructureServicesListWrapper>
          </StyledMudulesGrid>
        </SpacesModulesGridListItem>
      ))}
    </SpacesModulesGridList>
  );
};

export default SpacesModulesGrid;
