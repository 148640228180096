import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';

import { PDFPageViewWrapper } from 'components/layout/PdfLayout/PdfLayout.styles';

interface PDFPageViewProps {
  pdfFileUrl: string;
  height?: string;
}

const PDFPageView = ({ pdfFileUrl, height }: PDFPageViewProps) => {
  const viewerPlugins = [defaultLayoutPlugin()];

  return (
    <PDFPageViewWrapper height={height}>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
        <Viewer
          fileUrl={pdfFileUrl}
          plugins={viewerPlugins}
          defaultScale={SpecialZoomLevel.PageWidth}
          theme={{
            theme: 'auto',
          }}
        />
      </Worker>
    </PDFPageViewWrapper>
  );
};

export default PDFPageView;
