import { Modal } from '@maskott/tactileo.designsystem';
import {
  error,
  errorLight,
  neutralDarker,
  neutralLight,
  secondaryText,
} from 'assets/colors';
import { RoundedCornerButton } from 'components/atoms';
import styled from 'styled-components';

export const ConfirmDeleteAccountPageStyled = styled.div`
  width: 450px;
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const TitleStyled = styled.h3`
  color: ${neutralDarker};
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.35px;
  margin-bottom: 8px;
`;

export const BackButtonStyled = styled.button`
  margin-top: 32px;
  margin-bottom: 16px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  background: none;
  color: ${secondaryText};
  font-weight: 900;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
`;

export const BackImageStyled = styled.img`
  width: 12px;
  height: 12px;
`;

export const FieldStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 36px;
`;

export const FieldErrorStyled = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: ${error};
`;

export const LabelStyled = styled.label`
  color: black;
`;

export const SmallTextStyled = styled.p`
  font-size: 14px;
  line-height: 16px;
  color: black;
  margin: 0;
`;

export const ButtonsBlockStyled = styled.div`
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 24px;
`;

export const CancelButtonStyled = styled(RoundedCornerButton)`
  font-weight: 700;
`;

export const ConfirmButtonStyled = styled(RoundedCornerButton)`
  font-weight: 700;
  background-color: ${errorLight};
  border-color: ${error};
  box-shadow: ${error} 0px 3px 0px;
  &&:hover {
    background-color: ${errorLight};
  }
`;

export const ModalTextStyled = styled.p`
  margin-top: 0;
  width: 100%;
  max-width: 627px;
  color: black;
  margin: auto;
`;

export const ModalStyled = styled(Modal)`
  button {
    font-weight: 700;
  }
  button + button {
    background-color: ${errorLight};
    border-color: ${error};
    box-shadow: ${error} 0px 3px 0px;
    border-radius: 2.5rem;
  }
  button + button:hover:enabled {
    background-color: ${errorLight};
  }
`;

export const DeleteAccountPageStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media only screen and (max-width: 767px) {
    input {
      border: 1px solid rgb(${(props) => props.theme.colors.primary});
      box-shadow: 0px 1px 0px rgb(${(props) => props.theme.colors.primary});
    }
    input:focus {
      border: 2px solid rgb(${(props) => props.theme.colors.primary});
      background-color: ${neutralLight};
    }
    .errorState {
      border: 2px solid ${error}!important;
      box-shadow: 0px 1px 0px ${error};
      background-color: #fce8e8 !important;
    }
  }
`;
