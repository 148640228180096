import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { PrivateLayout } from 'components/layout';
import { StudentsHomeStyled } from './StudentsHome.styles';
import { Breadcrumb } from 'components/atoms';
import { routes } from 'router/routes';
import { Button } from '@maskott/tactileo.designsystem';
import { useNavigate } from 'react-router-dom';
import { useSpacePath } from 'hooks';
import { useRecoilValue } from 'recoil';
import { StructureState } from 'recoilTools';

/** StudentsHome COMPONENT *********************************************************/

const StudentsHome = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const navigate = useNavigate();
  const spacePath: string = useSpacePath();
  const structure = useRecoilValue(StructureState);

  /* -- Callbacks ------------------------------------------------------------- */

  const goto = useCallback(
    (url: string) => {
      navigate(`/${spacePath}${url}`);
    },
    [navigate, spacePath],
  );

  /* -- Effects ------------------------------------------------------------- */

  useEffect(() => {
    if (!structure) {
      navigate(`/${routes.home.path}`);
    }
  }, [structure, navigate]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <PrivateLayout>
      <div id="studentsHome-page">
        <StudentsHomeStyled>
          <div className="container p-4">
            <Breadcrumb
              homePath={routes.home.path}
              items={[{ name: t('studentsManagment') }]}
            />
            <h1>{t('studentsManagment')}</h1>
            <div className="d-flex gap-4">
              <div className="w-50 d-flex flex-column gap-4">
                <div className="visual p-4 rounded-5 text-center d-flex flex-column justify-content-center">
                  <div className="fs-5 bold">{t('classroomAccountsAge')}</div>
                </div>
                <Button
                  state="primary"
                  label={t('classroomAccounts')}
                  aria-label={t('classroomAccounts')}
                  onClick={() => {
                    goto(routes.classrooms.path);
                  }}
                />
                <div className="text">
                  <ul>
                    <li>{t('classroomAccountsText')}</li>
                  </ul>
                </div>
              </div>
              <div className="w-50 d-flex flex-column gap-4">
                <div className="visual p-4 rounded-5 text-center d-flex flex-column justify-content-center">
                  <div className="fs-5 bold">{t('individualAccountsAge')}</div>
                </div>
                <Button
                  state="primary"
                  label={t('individualAccounts')}
                  aria-label={t('individualAccounts')}
                  onClick={() => {
                    goto(routes.studentsClassrooms.path);
                  }}
                />
                <div className="text">
                  <ul>
                    <li>{t('individualAccountsText')}</li>
                    <li>{t('individualAccountsText2')}</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </StudentsHomeStyled>
      </div>
    </PrivateLayout>
  );
};

export default StudentsHome;
