import { useState, useCallback, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { modifyLearnerAccount, deleteLearnerAccount } from 'api';
import { routes } from 'router/routes';
import { useParams, generatePath } from 'react-router-dom';
import { LearnerAccountType, DefaultResponseErrorType } from 'models';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { StructureState } from 'recoilTools';
import { useRecoilValue } from 'recoil';
import { useError } from 'hooks';
import StudentsAccountsLine from './StudentsAccountsLine';
import StudentsAccountsLineForm from './StudentsAccountsLineForm';
import { useModal } from 'hooks/useModal';
import TableHolder from 'components/atoms/PlaceHolders/TableHolder';

/** StudentsAccountsList COMPONENT *********************************************************/

const StudentsAccountsList = ({
  disabled,
  list,
  passwordsList,
  onChange,
  onClickResetPassword,
}: {
  disabled: boolean;
  list: LearnerAccountType[] | undefined;
  passwordsList: {
    structure_id: number;
    learner_account_id: number;
    password: string;
  }[];
  onChange: (status: string) => void;
  onClickResetPassword: (learner_account_id: number) => void;
}) => {
  /* -- Const ------------------------------------------------------------- */
  const params = useParams();
  const classroomId: number = params['classroomId']
    ? parseInt(params['classroomId'])
    : -1;
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const { getMessage } = useError();
  const { showModal, hideModal } = useModal();

  /* -- States ------------------------------------------------------------- */

  const structure = useRecoilValue(StructureState);
  const [account, setAccount] = useState<LearnerAccountType | undefined>(
    undefined,
  );

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const modifyLearnerAccountMutation = useMutation(
    'modifyLearnerAccount',
    modifyLearnerAccount,
    {
      retry: false,
      onSuccess: () => {
        setAccount(undefined);
        toast(t('learnerModified'), {
          type: 'success',
        });
        onChange('modified');
      },
      onError: (
        error: AxiosError<{ error_message: string; reason: string[] | null }>,
      ) => {
        toast(getMessage(error.response?.data, 'user.auth.students'), {
          type: 'error',
          autoClose: false,
          closeButton: true,
        });
        onChange('error');
      },
    },
  );

  const deleteLearnerAccountMutation = useMutation(
    'deleteLearnerAccount',
    deleteLearnerAccount,
    {
      retry: false,
      onSuccess: () => {
        toast(t('learnerDeleted'), {
          type: 'success',
        });
        onChange('deleted');
      },
      onError: (error: DefaultResponseErrorType) => {
        toast(getMessage(error.response?.data, 'user.auth.students'), {
          type: 'error',
          autoClose: false,
          closeButton: true,
        });
        onChange('error');
      },
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onSubmitModification = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const target: HTMLFormElement = event.target as HTMLFormElement;
      if (
        structure?.structure_id &&
        account?.learner_account_id &&
        target.last_name.value != '' &&
        target.first_name.value != ''
      ) {
        onChange('modifying');
        modifyLearnerAccountMutation.mutate({
          structure_id: structure?.structure_id,
          learner_account_id: account?.learner_account_id,
          last_name: target.last_name.value,
          first_name: target.first_name.value,
        });
      }
    },
    [
      structure?.structure_id,
      account?.learner_account_id,
      modifyLearnerAccountMutation,
      onChange,
    ],
  );

  const onModify = useCallback((_account: LearnerAccountType) => {
    setAccount(_account);
  }, []);

  const onGeneratePassword = useCallback(
    async (learner_account_id: number) => {
      const modalState = await showModal({
        action: 'resetConfirmation',
        size: 'L',
        title: '',
        actionTitle: t('modalReset'),
        message: t('resetConfirmation'),
      });
      if (modalState.status === 'accepted') {
        onClickResetPassword(learner_account_id);
      }
      hideModal();
    },
    [showModal, hideModal, onClickResetPassword, t],
  );

  const onPrint = useCallback(
    async (_account: LearnerAccountType) => {
      if (
        structure?.structure_id &&
        _account.learner_account_id &&
        classroomId &&
        list
      ) {
        const iframeUrl: string = generatePath(
          `/${routes.studentAccountPrint.path}`,
          {
            structureId: structure?.structure_id,
            classroomId,
            accountId: _account.learner_account_id,
          },
        );
        const modalState = await showModal({
          size: 'L',
          action: 'print',
          title: '',
          actionTitle: t('print'),
          iframeUrl,
          iframeMessage: {
            type: 'passwords',
            payload: passwordsList,
          },
        });
        if (modalState.status === 'accepted') {
          if (modalState.iframeRef) {
            modalState.iframeRef?.contentWindow?.print();
            hideModal();
            onChange('printed');
          }
        }
      }
    },
    [
      structure?.structure_id,
      classroomId,
      t,
      list,
      showModal,
      hideModal,
      onChange,
      passwordsList,
    ],
  );

  const onCancel = useCallback(() => {
    setAccount(undefined);
  }, []);

  const onDelete = useCallback(
    async (learner_account_id: number) => {
      if (structure?.structure_id) {
        const modalState = await showModal({
          size: 'L',
          action: 'delete',
          title: '',
          actionTitle: t('modalConfirm'),
          message: t('deleteAccountConfirmation'),
        });
        if (modalState.status === 'accepted') {
          onChange('deleting');
          deleteLearnerAccountMutation.mutate({
            structure_id: structure.structure_id,
            learner_account_id,
          });
        }
        hideModal();
      }
    },
    [
      structure?.structure_id,
      t,
      deleteLearnerAccountMutation,
      showModal,
      hideModal,
      onChange,
    ],
  );

  /* -- Effects ------------------------------------------------------------- */

  /* -- Return ------------------------------------------------------------- */

  const mutating: boolean =
    modifyLearnerAccountMutation.isLoading ||
    deleteLearnerAccountMutation.isLoading ||
    !!passwordsList.find((item) => !item.password);

  const getPassword = (learner_account_id: number): string | undefined => {
    const match = passwordsList.find(
      (item) => item.learner_account_id === learner_account_id,
    );
    return match ? match.password : undefined;
  };

  return (
    <>
      {' '}
      <h2>{t('studentsList')}</h2>
      <form onSubmit={onSubmitModification}>
        <div className="table-responsive-md">
          <table className="table w-100">
            {!!list?.length && (
              <thead>
                <tr>
                  <th scope="col">{t('lastname')}</th>
                  <th scope="col">{t('firstname')}</th>
                  <th scope="col">{t('username')}</th>
                  <th scope="col">{t('password')}</th>
                  <th scope="col" className="text-end">
                    &nbsp;
                  </th>
                </tr>
              </thead>
            )}
            <tbody>
              {list?.map((_account) =>
                _account.learner_account_id !== account?.learner_account_id ? (
                  <StudentsAccountsLine
                    key={_account.learner_account_id}
                    account={{
                      ..._account,
                      password: getPassword(_account.learner_account_id || -1),
                    }}
                    onModify={onModify}
                    onDelete={() => {
                      if (_account.learner_account_id) {
                        onDelete(_account.learner_account_id);
                      }
                    }}
                    onPassword={() => {
                      if (_account.learner_account_id) {
                        onGeneratePassword(_account.learner_account_id);
                      }
                    }}
                    onPrint={() => {
                      onPrint(_account);
                    }}
                    disabled={disabled || !!account || mutating}
                    isDeleting={
                      deleteLearnerAccountMutation.isLoading &&
                      _account.learner_account_id ===
                        deleteLearnerAccountMutation.variables
                          ?.learner_account_id
                    }
                    isGeneratingPassword={
                      false
                      // generatePasswordForLearnerMutation.isLoading &&
                      // _account.learner_account_id ===
                      //   generatePasswordForLearnerMutation.variables
                      //     ?.learner_account_id
                    }
                  />
                ) : (
                  <StudentsAccountsLineForm
                    key={_account.learner_account_id}
                    initialValue={_account}
                    onCancel={onCancel}
                    disabled={disabled}
                    loading={modifyLearnerAccountMutation.isLoading}
                  />
                ),
              )}
              {!list && <TableHolder lines={4} cols={2} />}
            </tbody>
          </table>
          {list?.length === 0 && <p>{t('noLearnerAccounts')}</p>}
        </div>
      </form>
    </>
  );
};

export default StudentsAccountsList;
