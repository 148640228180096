import { secondaryMain } from 'assets/colors';
import styled from 'styled-components';

export const CguPageStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  ul {
    padding-left: 0;
  }
  a {
    line-height: 1.4em;
    color: ${secondaryMain};
  }
`;
