import axiosAuthorized from './axiosAuthorized';
import { ENDPOINTS } from 'appConstants';
import { StructureClassroomType, LearnerAccountType } from 'models';

/********************************************/
/* STRUCTURE CLASSROOMS *********************/

// GET STRUCTURE CLASSROOMS FROM STRUCTURE (private) -------------

export const getStructureClassrooms = (
  structure_id: number,
): Promise<StructureClassroomType[]> => {
  const endpoint: string = ENDPOINTS.structureClassrooms.replace(
    '{structureId}',
    `${structure_id}`,
  );
  return axiosAuthorized
    .get<{ results: StructureClassroomType[] }>(endpoint, {
      params: { page: 1, limit: 999999999 },
    })
    .then((response) => response?.data?.results);
};

// GET STRUCTURE CLASSROOM (private) -------------

export const getStructureClassroom = (
  structure_id: number,
  classroom_id: number,
): Promise<StructureClassroomType> => {
  const endpoint: string =
    ENDPOINTS.structureClassrooms.replace('{structureId}', `${structure_id}`) +
    `/${classroom_id}`;
  return axiosAuthorized
    .get<StructureClassroomType>(endpoint)
    .then((response) => response?.data);
};

// ADD STRUCTURE CLASSROOM (private) -------------

type AddStructureClassroomParamsType = {
  level: string;
  name: string;
  structure_id: number;
};
export const addStructureClassroom = (
  params: AddStructureClassroomParamsType,
) => {
  const endpoint: string = ENDPOINTS.structureClassrooms.replace(
    '{structureId}',
    `${params.structure_id}`,
  );
  const payload: any = { ...params };
  delete payload.structure_id;
  return axiosAuthorized.post(endpoint, payload);
};

// UPDATE STRUCTURE CLASSROOM (private) -------------

type ModifyStructureClassroomPayloadType = {
  structure_id: number;
  classroom_id: number;
  level: string;
  name: string;
};
export const modifyStructureClassroom = (
  params: ModifyStructureClassroomPayloadType,
) => {
  const endpoint: string =
    ENDPOINTS.structureClassrooms.replace(
      '{structureId}',
      `${params.structure_id}`,
    ) + `/${params.classroom_id}`;
  const payload: any = { ...params };
  delete payload.structure_id;
  delete payload.classroom_id;
  return axiosAuthorized.put(endpoint, payload);
};

// DELETE STRUCTURE CLASSROOM FROM STRUCTURE (private) -------------

type DeleteStructureClassroomParamsType = {
  structure_id: number;
  classroom_id: number;
};
export const deleteStructureClassroom = (
  params: DeleteStructureClassroomParamsType,
) => {
  const endpoint: string = ENDPOINTS.structureClassrooms.replace(
    '{structureId}',
    `${params.structure_id}`,
  );
  return axiosAuthorized.delete(`${endpoint}/${params.classroom_id}`);
};

// IMPORT LEARNER ACCOUNTS TO CLASSROOM (private) -------------

type ImportLearnerToClassroomParamsType = {
  structure_id: number;
  classroom_id: number;
  formData: FormData;
};
export const importLearnerToClassroom = (
  params: ImportLearnerToClassroomParamsType,
) => {
  const endpoint: string =
    ENDPOINTS.structureClassrooms.replace(
      '{structureId}',
      `${params.structure_id}`,
    ) + `/${params.classroom_id}/import`;
  return axiosAuthorized.post(endpoint, params.formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
// GET LEARNER ACCOUNTS NB OF A CLASSROOM (private) -------------

type GetLearnerClassroomNbParamsType = {
  structure_id: number;
  classroom_id: number;
};
export const getLearnerClassroomNb = (
  params: GetLearnerClassroomNbParamsType,
) => {
  const endpoint: string =
    ENDPOINTS.structureClassrooms.replace(
      '{structureId}',
      `${params.structure_id}`,
    ) + `/${params.classroom_id}/learner_count`;
  return axiosAuthorized.get(endpoint);
};

/********************************************/
/* LEARNER ACCOUNTS *************************/

// GET LEARNER ACCOUNTS FROM STRUCTURE (private) -------------

export const getLearnerAccounts = (
  structure_id: number,
  classroom_id: number,
): Promise<LearnerAccountType[]> => {
  const endpoint: string =
    ENDPOINTS.structureLearnerAccounts.replace(
      '{structureId}',
      `${structure_id}`,
    ) + `?classroomId=${classroom_id}`;
  return axiosAuthorized
    .get<{ results: LearnerAccountType[] }>(endpoint, {
      params: { page: 1, limit: 999999999 },
    })
    .then((response) => response?.data?.results);
};

// GET LEARNER ACCOUNT (private) -------------

export const getLearnerAccount = (
  structure_id: number,
  learner_account_id: number,
) => {
  const endpoint: string =
    ENDPOINTS.structureLearnerAccounts.replace(
      '{structureId}',
      `${structure_id}`,
    ) + `/${learner_account_id}`;
  return axiosAuthorized.get(endpoint).then((response) => response?.data);
};

// ADD LEARNER ACCOUNT (private) -------------

type AddLearnerAccountParamsType = {
  structure_id: number;
  classroom_id: number;
  login_name: string;
  last_name: string;
  first_name: string;
};
export const addLearnerAccount = (params: AddLearnerAccountParamsType) => {
  const endpoint: string = ENDPOINTS.structureLearnerAccounts.replace(
    '{structureId}',
    `${params.structure_id}`,
  );
  const payload: any = { ...params };
  delete payload.structure_id;
  return axiosAuthorized.post(endpoint, payload);
};

// UPDATE LEARNER ACCOUNT (private) -------------

type ModifyLearnerAccountParamsType = {
  structure_id: number;
  learner_account_id: number;
  last_name: string;
  first_name: string;
};
export const modifyLearnerAccount = (
  params: ModifyLearnerAccountParamsType,
) => {
  const endpoint: string =
    ENDPOINTS.structureLearnerAccounts.replace(
      '{structureId}',
      `${params.structure_id}`,
    ) + `/${params.learner_account_id}`;
  const payload: any = { ...params };
  delete payload.structure_id;
  delete payload.learner_account_id;
  return axiosAuthorized.put(endpoint, payload);
};

// GENERATE PASSWORD FOR LEARNER ACCOUNT (private) -------------

type GeneratePasswordForLearnerAccountParamsType = {
  structure_id: number;
  learner_account_id: number;
};
export const generatePasswordForLearner = (
  params: GeneratePasswordForLearnerAccountParamsType,
) => {
  const endpoint: string =
    ENDPOINTS.structureLearnerAccounts.replace(
      '{structureId}',
      `${params.structure_id}`,
    ) + `/${params.learner_account_id}/generate_password`;
  return axiosAuthorized.post(endpoint);
};

// DELETE LEARNER ACCOUNT FROM STRUCTURE (private) -------------

type DeleteLearnerAccountParamsType = {
  structure_id: number;
  learner_account_id: number;
};
export const deleteLearnerAccount = (
  params: DeleteLearnerAccountParamsType,
) => {
  const endpoint: string =
    ENDPOINTS.structureLearnerAccounts.replace(
      '{structureId}',
      `${params.structure_id}`,
    ) + `/${params.learner_account_id}`;
  return axiosAuthorized.delete(endpoint);
};
