import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { ReactJSXElement } from '@emotion/react/types/jsx-namespace';
import { useMutation } from 'react-query';

import { getSpace } from 'api';
import { SessionSpaceState } from 'recoilTools';
import { DEFAULT_SESSION_SPACE, DEFAULT_THEME } from 'appConstants';
import assetsThemes from 'assets/themes.json';
import { DefaultTheme } from 'styled-components';
import { routes } from 'router/routes';
import { useSpaceDetection } from 'hooks';

interface WithSpaceProps {
  children: ReactJSXElement;
  onThemeChange: (theme: DefaultTheme) => void;
}

/** WithSpace COMPONENT *********************************************************/

const WithSpace = ({ children, onThemeChange }: WithSpaceProps) => {
  /* -- Const ------------------------------------------------------------- */
  const { spaceDetection } = useSpaceDetection();

  /* -- States ------------------------------------------------------------- */

  const [sessionSpaceState, setSessionSpaceState] =
    useRecoilState(SessionSpaceState);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const getSpaceMutation = useMutation('getSpace', getSpace, {
    retry: false,
    onError: () => {
      window.location.href = routes.login.path;
    },
  });

  /* -- Effects ------------------------------------------------------------- */

  // load Space when profile is loaded or use default one
  useEffect(() => {
    const spaceDetected = spaceDetection();
    if (
      spaceDetection() &&
      !getSpaceMutation.isLoading &&
      spaceDetected !== getSpaceMutation.data?.space_key &&
      spaceDetected !== sessionSpaceState?.space_key
    ) {
      if (spaceDetected) {
        if (spaceDetected === 'default') {
          setSessionSpaceState(DEFAULT_SESSION_SPACE);
        } else {
          getSpaceMutation.mutate({ space_key: spaceDetected });
        }
      }
    }
  }, [
    spaceDetection,
    getSpaceMutation,
    sessionSpaceState?.space_key,
    setSessionSpaceState,
  ]);

  // update sessionSpaceState when space loaded
  useEffect(() => {
    if (
      getSpaceMutation.data &&
      getSpaceMutation.data.space_key !== sessionSpaceState?.space_key
    ) {
      setSessionSpaceState(getSpaceMutation.data);
      getSpaceMutation.reset();
    }
  }, [getSpaceMutation, sessionSpaceState?.space_key, setSessionSpaceState]);

  // change theme on space_key update, use themes if available and default one instead
  useEffect(() => {
    if (sessionSpaceState?.space_key) {
      const themes: DefaultTheme[] = assetsThemes.themes;
      const theme: DefaultTheme =
        themes.find((th) => th.key === sessionSpaceState?.theme_key) ||
        DEFAULT_THEME;
      onThemeChange(theme);
    }
  }, [
    sessionSpaceState?.space_key,
    sessionSpaceState?.theme_key,
    onThemeChange,
  ]);

  /* -- Return ------------------------------------------------------------- */

  return <>{children}</>;
};

export default WithSpace;
