import { useState, useCallback, FormEvent, useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { PrivateLayout } from 'components/layout';
import { ClassroomsStyled } from './Classrooms.styles';
import { Breadcrumb, AnimalsPassword } from 'components/atoms';
import { routes } from 'router/routes';
import { FormInput } from '@maskott/tactileo.designsystem';
import { generatePath, useNavigate } from 'react-router-dom';
import { ClassroomType, DefaultResponseErrorType } from 'models';
import { StructureState } from 'recoilTools';
import { useRecoilValue } from 'recoil';
import { useQuery, useMutation } from 'react-query';
import { getClassrooms, addClassroom, deleteClassroom } from 'api';
import { toast } from 'react-toastify';
import {
  ANIMALS_NUMBER,
  SPECIES,
} from 'components/atoms/AnimalsPassword/AnimalsPassword';
import { AxiosError } from 'axios';
import { getRootUrl } from 'utils';
import { useError, useSpacePath } from 'hooks';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';
import TableHolder from 'components/atoms/PlaceHolders/TableHolder';

/***********************************************************/
/***********************************************************/
/** Classrooms COMPONENT **/

const CLASSROOM_MAX = 4;

const Classrooms = () => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.classrooms',
  });
  const structure = useRecoilValue(StructureState);
  const { getMessage } = useError();
  const navigate = useNavigate();

  /* -- States ------------------------------------------------------------- */

  const [classroom, setClassroom] = useState<ClassroomType | undefined>(
    undefined,
  );
  const [inputError, setInputError] = useState<string>('');

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const classroomsQuery = useQuery('getClassrooms', getClassrooms, {
    refetchOnWindowFocus: false,
    enabled: false,
    retry: false,
    cacheTime: 0,
  });

  const addClassroomMutation = useMutation('addClassroom', addClassroom, {
    retry: false,
    onSuccess: () => {
      classroomsQuery.refetch();
      toast(t('classroomAdded'), {
        type: 'success',
      });
    },
    onError: (
      error: AxiosError<{ error_message: string; reason: string[] | null }>,
    ) => {
      toast(getMessage(error.response?.data, 'user.auth.classrooms'), {
        type: 'error',
        autoClose: false,
        closeButton: true,
      });
    },
  });

  const deleteClassroomsMutation = useMutation(
    'deleteClassroom',
    deleteClassroom,
    {
      retry: false,
      onSuccess: () => {
        classroomsQuery.refetch();
        toast(t('classroomDeleted'), {
          type: 'success',
        });
      },
      onError: (error: DefaultResponseErrorType) => {
        toast(getMessage(error.response?.data, 'user.auth.classrooms'), {
          type: 'error',
        });
      },
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onSubmitModification = useCallback((event: FormEvent) => {
    event.preventDefault();
    return false;
  }, []);

  const onSubmitAdd = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const target: HTMLFormElement = event.target as HTMLFormElement;

      if (structure?.structure_id) {
        if (target.display_name.value === '') {
          setInputError('noName');
        } else {
          setInputError('');
          const password: string =
            ANIMALS_NUMBER +
            '-' +
            Array(ANIMALS_NUMBER)
              .fill(0)
              .map(() => SPECIES[Math.floor(SPECIES.length * Math.random())])
              .join('-');
          addClassroomMutation.mutate({
            structure_id: structure.structure_id,
            display_name: target.display_name.value,
            password: password,
          });
        }
      }
    },
    [addClassroomMutation, structure],
  );

  const onModify = useCallback((_classroom: ClassroomType) => {
    setClassroom(_classroom);
  }, []);

  const onCancel = useCallback(() => {
    setClassroom(undefined);
  }, []);

  const onDelete = useCallback(
    (classroom_key: string) => {
      deleteClassroomsMutation.mutate({
        classroom_key,
      });
    },
    [deleteClassroomsMutation],
  );

  /* -- Effects ------------------------------------------------------------- */

  useEffect(() => {
    if (
      structure?.structure_id &&
      !classroomsQuery.isLoading &&
      !classroomsQuery.data
    ) {
      classroomsQuery.refetch();
    }
  }, [classroomsQuery, structure?.structure_id]);

  useEffect(() => {
    if (!structure) {
      navigate(`/${routes.home.path}`);
    }
  }, [structure, navigate]);

  /* -- Return ------------------------------------------------------------- */

  const classrooms = classroomsQuery.data?.filter(
    (_classroom) => _classroom.structure_id === structure?.structure_id,
  );

  const mutating: boolean =
    addClassroomMutation.isLoading || deleteClassroomsMutation.isLoading;

  return (
    <PrivateLayout>
      <div id="studentsHome-page">
        <ClassroomsStyled>
          <div className="container p-4">
            <Breadcrumb
              homePath={routes.home.path}
              items={[
                {
                  name: t('studentsManagment'),
                  url: `/${routes.studentsHome.path}`,
                },
                {
                  name: t('classroomAccounts'),
                },
              ]}
            />
            <h1>{t('classroomAccounts')}</h1>
            <div>{t('classroomAccountsText')}</div>
            <h2>{t('classroomConnexionTitle')}</h2>
            <div>
              <Trans
                t={t}
                i18nKey="classroomConnexionText"
                values={{
                  connectionUrl: getRootUrl() + routes.loginClassroom.path,
                }}
              />
            </div>
            <h2>{t('classroomList')}</h2>
            <form onSubmit={onSubmitModification}>
              <div className="table-responsive-md">
                <table className="table w-100">
                  {!!classroomsQuery?.data?.length && (
                    <thead>
                      <tr>
                        <th scope="col">{t('classroomName')}</th>
                        <th scope="col">{t('classroomUrl')}</th>
                        <th scope="col">{t('classroomPassword')}</th>
                        <th scope="col" className="text-end">
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {!classroomsQuery.isLoading &&
                      classrooms?.map((_classroom) =>
                        _classroom.classroom_key !==
                        classroom?.classroom_key ? (
                          <Line
                            key={_classroom.classroom_key}
                            classroom={_classroom}
                            onModify={onModify}
                            onDelete={() => onDelete(_classroom.classroom_key)}
                            disabled={!!classroom || mutating}
                            isDeleting={
                              deleteClassroomsMutation.isLoading &&
                              _classroom.classroom_key ===
                                deleteClassroomsMutation.variables
                                  ?.classroom_key
                            }
                          />
                        ) : (
                          <LineForm
                            key={_classroom.classroom_key}
                            initialValue={_classroom}
                            onCancel={onCancel}
                            disabled={false}
                            loading={false}
                            error={inputError}
                          />
                        ),
                      )}
                    {(!classroomsQuery || classroomsQuery.isLoading) && (
                      <TableHolder lines={4} cols={2} />
                    )}
                  </tbody>
                </table>
                {classroomsQuery?.data?.length === 0 && (
                  <p>{t('noLearnerAccounts')}</p>
                )}
              </div>
            </form>
            <h2>{t('addClassroom', { max: CLASSROOM_MAX })}</h2>
            <form onSubmit={onSubmitAdd}>
              <div className="table-responsive-md">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">{t('classroomName')}</th>
                      <th scope="col">&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {structure && !addClassroomMutation.isLoading ? (
                      <LineForm
                        initialValue={{
                          classroom_key: '',
                          structure_id: structure.structure_id,
                          display_name: '',
                          password: '',
                        }}
                        disabled={!!classroom || mutating}
                        loading={addClassroomMutation.isLoading}
                        error={inputError}
                      />
                    ) : (
                      <></>
                    )}
                  </tbody>
                </table>
                {inputError === 'noName' && (
                  <div className="text-danger">{t('noName')}</div>
                )}
              </div>
            </form>
          </div>
        </ClassroomsStyled>
      </div>
    </PrivateLayout>
  );
};

/***********************************************************/
/***********************************************************/
/** Line COMPONENT **/

const Line = ({
  classroom,
  onModify,
  onDelete,
  disabled,
  isDeleting,
}: {
  classroom: ClassroomType;
  onModify: (classroom: ClassroomType) => void;
  onDelete: (classroom: ClassroomType) => void;
  disabled: boolean;
  isDeleting: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.classrooms',
  });
  const spacePath = useSpacePath();

  const loginUrl =
    getRootUrl() +
    generatePath(`${spacePath}${routes.loginClassroom.path}`, {
      classroomKey: classroom.classroom_key,
    });

  /* -- Callbacks ------------------------------------------------------------- */

  // const onModifyClick = useCallback(() => {
  //   onModify(classroom);
  // }, [onModify, classroom]);

  const onPrintClick = useCallback(() => {
    window.open(
      generatePath(`/${spacePath}${routes.classroomPrint.path}`, {
        classroomKey: classroom.classroom_key,
      }),
    );
  }, [classroom, spacePath]);

  const onClickUrl = useCallback((e: FormEvent<HTMLInputElement>) => {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    target.setSelectionRange(0, target.value.length);
  }, []);

  const onCopyClick = useCallback(() => {
    navigator.clipboard.writeText(loginUrl);
    toast(t('linkCopied'), {
      type: 'success',
    });
  }, [loginUrl, t]);

  const onDeleteClick = useCallback(() => {
    onDelete(classroom);
  }, [onDelete, classroom]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <tr>
      <td>{classroom.display_name}</td>
      <td>
        <div className="input-group">
          <div className="input-group-prepend">
            <button
              className="btn btn-outline-secondary btn-print"
              type="button"
              onClick={onCopyClick}
            >
              <CopyIcon className="inputIcon" />
            </button>
          </div>
          <input
            type="text"
            className="form-control"
            value={loginUrl}
            onClick={onClickUrl}
            readOnly
          />
        </div>
      </td>
      <td>
        <AnimalsPassword password={classroom.password} />
      </td>
      <td className="text-end">
        {/* <button
          type="button"
          className="btn btn-primary"
          onClick={onModifyClick}
          disabled={disabled}
        >
          {t('modify')}
        </button> */}
        <button
          type="button"
          className="btn btn-primary"
          onClick={onPrintClick}
          disabled={disabled}
        >
          {t('print')}
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-outline-dark"
          onClick={onDeleteClick}
          disabled={disabled}
        >
          {' '}
          {isDeleting && (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />{' '}
            </>
          )}
          {t('delete')}
        </button>
      </td>
    </tr>
  );
};

/***********************************************************/
/***********************************************************/
/** LineForm COMPONENT **/

const LineForm = ({
  initialValue,
  onCancel,
  disabled,
  loading,
  error,
}: {
  initialValue: ClassroomType;
  onCancel?: () => void;
  disabled: boolean;
  loading: boolean;
  error: string;
}) => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.classrooms',
  });

  /* -- States ------------------------------------------------------------- */

  const [classroom, setClassroom] = useState({
    ...initialValue,
  });

  /* -- Callbacks ------------------------------------------------------------- */

  const onChangeName = useCallback(
    (value: string) => {
      setClassroom({ ...classroom, display_name: value });
    },
    [classroom],
  );

  /* -- Return ------------------------------------------------------------- */

  return (
    <>
      <tr>
        <td>
          <FormInput
            leftIcon={null}
            placeholder={''}
            id="display_name"
            value={classroom?.display_name}
            onChange={onChangeName}
            state={error === 'noName' ? 'error' : null}
          />
        </td>
        {classroom.classroom_key ? (
          <>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </>
        ) : (
          <></>
        )}
        <td className="text-end">
          <button
            type="submit"
            className="btn btn-outline-dark"
            disabled={disabled}
          >
            {' '}
            {loading && (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
              </>
            )}
            {classroom.classroom_key
              ? t('ok')
              : t('addClassroomMax', { max: CLASSROOM_MAX })}
          </button>
          {classroom.classroom_key ? (
            <>
              &nbsp;
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={onCancel}
              >
                {t('cancel')}
              </button>
            </>
          ) : (
            <></>
          )}
        </td>
      </tr>
    </>
  );
};

/***********************************************************/
/***********************************************************/
export default Classrooms;
