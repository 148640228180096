import styled from 'styled-components';
import { neutralDarker } from 'assets/colors';
import image from 'assets/images/image-1409722748.jpg';

export const PageWrapperStyled = styled.div`
  h1 {
    color: ${neutralDarker};
    font-weight: bold;
    font-size: 24px;
    line-height: 24px;
    letter-spacing: 0.35px;
  }

  .illustration {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 60vh;
    @media only screen and (max-width: 992px) {
      display: none;
    }
    .image {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: url(${image});
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;
      opacity: 0.6;
    }
  }
`;
