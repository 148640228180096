import { useState, useEffect, useCallback } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { PrivateLayout } from 'components/layout';
import { StudentsAccountsStyled } from './StudentsAccounts.styles';
import { Breadcrumb } from 'components/atoms';
import { useQuery, useMutation } from 'react-query';
import {
  getStructureClassroom,
  getLearnerAccounts,
  generatePasswordForLearner,
} from 'api';
import { routes } from 'router/routes';
import { useParams, generatePath, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { StructureState } from 'recoilTools';
import { useRecoilValue } from 'recoil';
import { getRootUrl } from 'utils';
import { useError } from 'hooks';
import StudentsAccountsList from './StudentsAccountsList';
import StudentsAccountsAdd from './StudentsAccountsAdd';
import StudentsAccountsImport from './StudentsAccountsImport';
import { useModal } from 'hooks/useModal';
import { PasswordLearnearAccountType } from 'models';

/** StudentsAccounts COMPONENT *********************************************************/

const StudentsAccounts = () => {
  /* -- Const ------------------------------------------------------------- */
  const params = useParams();
  const navigate = useNavigate();
  const classroomId: number = params['classroomId']
    ? parseInt(params['classroomId'])
    : -1;
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const { getMessage } = useError();

  /* -- States ------------------------------------------------------------- */
  const [isMutating, setIsMutating] = useState<boolean>(false);
  const structure = useRecoilValue(StructureState);
  const [passwordsList, setPasswordsList] = useState<
    PasswordLearnearAccountType[]
  >([]);
  const { showModal, hideModal } = useModal();

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const structureClassroomQuery = useQuery(
    ['structureClassroomQuery', structure?.structure_id],
    () => {
      if (structure?.structure_id) {
        return getStructureClassroom(structure?.structure_id, classroomId);
      }
      return Promise.reject(null);
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      cacheTime: 0,
      onError: (
        error: AxiosError<{ error_message: string; reason: string[] | null }>,
      ) => {
        if (error.response?.status === 404) {
          navigate(generatePath(`/${routes.studentsClassrooms.path}`));
        }
      },
    },
  );

  const learnerAccountsQuery = useQuery(
    ['learnerAccountsQuery', structure?.structure_id],
    () => {
      if (structure?.structure_id) {
        return getLearnerAccounts(structure?.structure_id, classroomId);
      }
      return [];
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      cacheTime: 0,
    },
  );

  const generatePasswordForLearnerMutation = useMutation(
    'generatePasswordForLearner',
    generatePasswordForLearner,
    {
      retry: false,
      onError: (
        error: AxiosError<{ error_message: string; reason: string[] | null }>,
      ) => {
        toast(getMessage(error.response?.data, 'user.auth.students'), {
          type: 'error',
          autoClose: false,
          closeButton: true,
        });
      },
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const resetPassword = useCallback(
    async ({
      structure_id,
      learner_account_id,
    }: {
      structure_id: number;
      learner_account_id: number;
    }) => {
      return new Promise<PasswordLearnearAccountType>((resolve) => {
        generatePasswordForLearnerMutation
          .mutateAsync({
            structure_id,
            learner_account_id,
          })
          .then((result) => {
            resolve({
              structure_id: structure_id,
              learner_account_id: learner_account_id,
              password: result.data,
            });
          });
      });
    },
    [generatePasswordForLearnerMutation],
  );

  const onClickResetAllPasswords = useCallback(async () => {
    if (structure?.structure_id) {
      const modalState = await showModal({
        action: 'resetAllConfirmation',
        size: 'L',
        title: '',
        actionTitle: t('modalResetAll'),
        message: t('resetAllConfirmation'),
      });

      if (modalState.status === 'accepted') {
        showModal({
          action: 'resetingAll',
          size: 'L',
          title: '',
          actionTitle: '',
          message: <>{t('resetingAll')}</>,
        });

        setPasswordsList([]);
        const newPasswords: PasswordLearnearAccountType[] = [];
        if (learnerAccountsQuery.data) {
          await Promise.all(
            learnerAccountsQuery.data?.map((_account) => {
              return new Promise((resolve) => {
                if (_account.learner_account_id) {
                  resetPassword({
                    structure_id: structure.structure_id,
                    learner_account_id: _account.learner_account_id,
                  }).then((result) => {
                    newPasswords.push(result);
                    resolve(result);
                  });
                }
              });
            }),
          );
          setPasswordsList(newPasswords);
        }

        const modalPrintState = await showModal({
          action: 'printAll',
          size: 'XL',
          title: '',
          actionTitle: t('print'),
          iframeUrl: generatePath(`/${routes.studentsClassroomPrint.path}`, {
            structureId: structure?.structure_id,
            classroomId,
          }),
          iframeMessage: {
            type: 'passwords',
            payload: newPasswords,
          },
        });

        if (modalPrintState.status === 'accepted') {
          if (modalPrintState.iframeRef) {
            modalPrintState.iframeRef?.contentWindow?.print();
            hideModal();
          }
        }
      }
      hideModal();
    }
  }, [
    structure?.structure_id,
    classroomId,
    showModal,
    hideModal,
    resetPassword,
    learnerAccountsQuery.data,
    t,
  ]);

  const onClickResetPassword = useCallback(
    async (learner_account_id: number) => {
      if (structure?.structure_id) {
        const result = await resetPassword({
          structure_id: structure.structure_id,
          learner_account_id,
        });
        setPasswordsList((_passwordsList) => [
          ..._passwordsList.filter(
            (access) => access.learner_account_id != result.learner_account_id,
          ),
          result,
        ]);
      }
    },
    [structure?.structure_id, resetPassword],
  );

  const onChange = useCallback(
    (status: string) => {
      setIsMutating(
        ['adding', 'deleting', 'modifying', 'importing'].includes(status),
      );
      if (['added', 'deleted', 'modified', 'imported'].includes(status)) {
        learnerAccountsQuery.refetch();
      }
    },
    [learnerAccountsQuery],
  );

  /* -- Effects ------------------------------------------------------------- */

  // initialize classroom and learner accounts
  useEffect(() => {
    if (structure?.structure_id && classroomId) {
      if (!structureClassroomQuery.data && !structureClassroomQuery.isLoading) {
        structureClassroomQuery.refetch();
      }
      if (!learnerAccountsQuery.data && !learnerAccountsQuery.isLoading) {
        learnerAccountsQuery.refetch();
      }
    }
  }, [
    structureClassroomQuery,
    learnerAccountsQuery,
    structure?.structure_id,
    classroomId,
  ]);

  useEffect(() => {
    if (!structure) {
      navigate(`/${routes.home.path}`);
    }
  }, [structure, navigate]);

  /* -- Return ------------------------------------------------------------- */

  const mutating: boolean = !!passwordsList.find((item) => !item.password);

  return (
    <PrivateLayout>
      <div id="studentsHome-page">
        <StudentsAccountsStyled>
          <div className="container p-4">
            <Breadcrumb
              homePath={routes.home.path}
              items={[
                {
                  name: t('studentsManagment'),
                  url: `/${routes.studentsHome.path}`,
                },
                {
                  name: t('individualAccountsTitle'),
                  url: `/${routes.studentsClassrooms.path}`,
                },
                { name: t('studentsClassroomManagment') },
              ]}
            />
            <h1>
              {t('studentsClassroomManagment')}&nbsp;
              {structureClassroomQuery?.data?.level ? (
                <>
                  {structureClassroomQuery?.data?.level} /
                  {structureClassroomQuery?.data?.name}
                </>
              ) : (
                <>...</>
              )}
            </h1>
            <div>
              <Trans t={t} i18nKey="studentsClassroomManagmentText" />
            </div>
            <h2>{t('studentsClassroomConnexionTitle')}</h2>
            <div>
              <Trans
                t={t}
                i18nKey="studentsClassroomConnexionText"
                values={{
                  classroomCode: structureClassroomQuery?.data?.classroom_key,
                  connectionUrl: getRootUrl() + routes.loginStudent.path,
                }}
              />
            </div>
            <StudentsAccountsList
              list={learnerAccountsQuery.data}
              passwordsList={passwordsList}
              disabled={isMutating}
              onChange={onChange}
              onClickResetPassword={onClickResetPassword}
            />
            <StudentsAccountsAdd
              alreadyUsedUsername={
                learnerAccountsQuery.data?.map(
                  (_account) => _account.login_name,
                ) || []
              }
              disabled={isMutating}
              onChange={onChange}
            />

            <h2>{t('printAccounts')}</h2>
            <div className="d-flex justify-content-between">
              <div>{t('printAccountsText')}</div>
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={onClickResetAllPasswords}
                disabled={
                  mutating ||
                  !learnerAccountsQuery?.data ||
                  learnerAccountsQuery.data.length === 0
                }
              >
                {t('printAccountsBtn')}
              </button>
            </div>

            <StudentsAccountsImport disabled={isMutating} onChange={onChange} />
          </div>
        </StudentsAccountsStyled>
      </div>
    </PrivateLayout>
  );
};

export default StudentsAccounts;
