import { useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PublicLayout } from 'components/layout';
import {
  AfterDeletePageStyled,
  ButtonsBlockStyled,
  ButtonStyled,
} from './AfterDelete.styles';
import { useSpacePath } from 'hooks';
import { routes } from 'router/routes';
import { useSetRecoilState } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

const AfterDelete = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.afterDelete',
  });
  const setSessionSpaceState = useSetRecoilState(SessionSpaceState);

  useEffect(() => {
    setSessionSpaceState(undefined);
  }, [setSessionSpaceState]);

  const spacePath: string = useSpacePath();
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    navigate(`/${spacePath}${routes.login.path}`);
  }, [navigate, spacePath]);

  return (
    <PublicLayout
      title={t('logoutDone')}
      breadcrumb={[
        {
          name: t('logoutDone'),
        },
      ]}
    >
      <AfterDeletePageStyled>
        <br />
        <br />
        <br />
        <br />
        <div className="instruction">
          <div>
            <p>{t('instruction')}</p>
          </div>
          <ButtonsBlockStyled>
            <ButtonStyled
              state="primary"
              type="submit"
              label={t('login')}
              onClick={onClick}
              imgSide={undefined}
            />
          </ButtonsBlockStyled>
        </div>
      </AfterDeletePageStyled>
    </PublicLayout>
  );
};

export default AfterDelete;
