import { primaryText } from 'assets/colors';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const MenuWrapperStyled = styled.div`
  width: inherit;
  height: inherit;
  position: relative;
`;

export const MenuToggleStyled = styled.button`
  width: inherit;
  height: inherit;
  border: 0;
  background-color: transparent;
`;

export const MenuStyled = styled.ul`
  display: none;
  position: absolute;
  top: calc(100% + 8px);
  right: 0;
  filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.12));
  background-color: white;
  padding: 0;
  &.show {
    display: block;
  }
  a.on {
    font-weight: bold;
  }
`;

const menuItemStyle = css`
  width: 286px;
  height: 74px;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  padding: 0;
  padding-left: 25px;
  border: none;
  text-decoration: none;
  color: ${primaryText};
  background-color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  &:hover {
    color: ${primaryText};
  }
`;

export const MenuLinkItemStyled = styled(Link)`
  ${menuItemStyle}
`;

export const MenuItemIconStyled = styled.img`
  width: 24px;
  height: 24px;
`;

export const LogoutItemStyled = styled.button`
  ${menuItemStyle}
  padding-left: 29px;
  img {
    width: 16px;
    height: 16px;
  }
`;
