import { useTranslation } from 'react-i18next';
import { FormInput } from '@maskott/tactileo.designsystem';
import { Spinner } from 'components/atoms';
import { PublicLayout } from 'components/layout';
import {
  FieldStyled,
  LabelStyled,
  ForgotUsernamePageStyled,
  SendButtonStyled,
  SmallTextStyled,
  FieldErrorStyled,
} from './ForgotUsername.styles';
import { FORGOT_PASSWORD_NO_USER_FOUND } from 'appConstants';
import { routes } from 'router/routes';

type ForgotUsernamePropsType = {
  email: string;
  setEmail: (value: string) => any;
  onSubmit: () => any;
  errorMessage?: string;
  submitEnabled: boolean;
  isLoading: boolean;
};

const ForgotUsername = ({
  email,
  setEmail,
  onSubmit,
  errorMessage,
  submitEnabled,
  isLoading,
}: ForgotUsernamePropsType) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.forgotUsername',
  });

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  return (
    <PublicLayout
      backButtonText={t('goBack')}
      title={t('forgotUsername')}
      breadcrumb={[
        {
          name: t('forgotPassword'),
          url: `/${routes.forgotPassword.path}`,
        },
        {
          name: t('forgotUsername'),
        },
      ]}
    >
      <form onSubmit={handleSubmit}>
        <ForgotUsernamePageStyled>
          <SmallTextStyled>{t('pleaseEnterYourEmail')}</SmallTextStyled>
          <FieldStyled>
            <LabelStyled htmlFor="EmailInput">{t('email')}</LabelStyled>
            <FormInput
              leftIcon="email"
              placeholder={t('emailAddress')}
              id="EmailInput"
              state={null}
              value={email}
              onChange={setEmail}
            />
            {errorMessage === FORGOT_PASSWORD_NO_USER_FOUND && (
              <FieldErrorStyled>{t('noAccountWasFound')}</FieldErrorStyled>
            )}
            {errorMessage && errorMessage !== FORGOT_PASSWORD_NO_USER_FOUND && (
              <FieldErrorStyled>{errorMessage}</FieldErrorStyled>
            )}
          </FieldStyled>
          <br />
          <SendButtonStyled
            type="submit"
            disabled={!submitEnabled}
            state="primary"
            label={t('send')}
            Icon={isLoading ? Spinner : undefined}
            imgSide={isLoading ? 'right' : undefined}
          />
        </ForgotUsernamePageStyled>
      </form>
    </PublicLayout>
  );
};

export default ForgotUsername;
