import { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { ModalState } from 'recoilTools';
import { ModalType } from 'models';

type DeferredPromise = {
  resolve: (value: ModalType) => void;
  reject: (value: unknown) => void;
  promise: Promise<ModalType>;
};

export const useModal = () => {
  const [modalState, setModalState] = useRecoilState(ModalState);

  const [deferRef, setDeterRef] = useState<DeferredPromise>();

  const defer = () => {
    const deferred = {} as DeferredPromise;

    const promise = new Promise<ModalType>((resolve, reject) => {
      deferred.resolve = resolve;
      deferred.reject = reject;
    });

    deferred.promise = promise;
    setDeterRef(deferred);
    return deferred;
  };

  const showModal = async (options: ModalType) => {
    setModalState({ ...options, status: 'show' });
    return defer().promise;
  };

  const hideModal = async () => {
    setModalState(undefined);
  };

  useEffect(() => {
    if (modalState) {
      if (modalState?.status === 'accepted') {
        if (deferRef?.resolve) {
          deferRef.resolve(modalState);
        }
        // setModalState(undefined);
      } else if (modalState?.status === 'cancelled') {
        if (deferRef?.resolve) {
          deferRef.resolve(modalState);
        }
        setModalState(undefined);
      }
    }
  }, [modalState, setModalState, setDeterRef, deferRef]);

  return { showModal, hideModal };
};
