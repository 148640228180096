import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailSent from './EmailSent';
import ForgotUsernameView from './ForgotUsername';
import { sendForgotUsername } from 'api';
import { DefaultResponseErrorType } from 'models';
import { validateEmail } from 'utils';
import { useSpacePath } from 'hooks';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';
import { routes } from 'router/routes';

const ForgotUsername = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const spacePath: string = useSpacePath();
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.forgotUsername',
  });
  const { isLoading, isRefetching, refetch, error, isSuccess } = useQuery(
    'sendForgotUsername',
    () =>
      sendForgotUsername(
        email,
        sessionSpace?.space_key && sessionSpace.space_key !== 'default'
          ? sessionSpace.space_key
          : null,
      ).then(() => {
        toast(t('forgotMessage'));
        navigate(`/${spacePath}${routes.login.path}`);
      }),
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      cacheTime: 0,
    },
  );

  const submitEnabled = useMemo(
    () => validateEmail(email) && !isLoading && !isRefetching,
    [email, isLoading, isRefetching],
  );

  if (isSuccess) return <EmailSent email={email} />;

  return (
    <ForgotUsernameView
      isLoading={isLoading}
      email={email}
      setEmail={setEmail}
      onSubmit={refetch}
      submitEnabled={submitEnabled}
      errorMessage={
        (error as DefaultResponseErrorType)?.response?.data?.error_message
      }
    />
  );
};

export default ForgotUsername;
