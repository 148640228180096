import { error, neutralLight } from 'assets/colors';
import { RoundedCornerButton } from 'components/atoms';
import styled from 'styled-components';

export const WayfPageDemoStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .instruction {
    margin: 0;
  }
  .wayf_container {
    min-height: calc(100vh - 300px);
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .wayf_inner {
      height: auto;
      width: 100%;
    }
  }
  @media only screen and (max-width: 767px) {
    input {
      border: 1px solid rgb(${(props) => props.theme.colors.primary});
      box-shadow: 0px 1px 0px rgb(${(props) => props.theme.colors.primary});
    }
    input:focus {
      border: 2px solid rgb(${(props) => props.theme.colors.primary});
      background-color: ${neutralLight};
    }
    .errorState {
      border: 2px solid ${error}!important;
      box-shadow: 0px 1px 0px ${error};
      background-color: #fce8e8 !important;
    }
  }
`;

export const ApiErrorStyle = styled.div`
  padding: 30px 0;
  text-align: center;
  color: red;
`;

export const ButtonStyled = styled(RoundedCornerButton)`
  flex-grow: 1;
  margin: 15px auto;
`;
