import { RoundedCornerButton } from 'components/atoms';
import styled from 'styled-components';

export const Page404Styled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  .instruction {
    display: block;
    a {
      display: inline;
      color: rgb(${({ theme }) => `${theme.colors.primary}`});
      cursor: pointer;
    }
  }
`;

export const ButtonsBlockStyled = styled.div`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 24px;
`;

export const ButtonStyled = styled(RoundedCornerButton)`
  flex-grow: 1;
`;
