import { useRecoilValue } from 'recoil';

import { ProfileState, StructuresState } from 'recoilTools';
import { PrivateLayout } from 'components/layout';
import StructuresView from 'pages/private/Home/StructuresView';
import WelcomeView from 'pages/private/Home/WelcomeView';

import { HomeStyled } from 'pages/private/Home/Home.styles';

const Home = () => {
  const profileState = useRecoilValue(ProfileState);
  const structures = useRecoilValue(StructuresState);
  const first_name = profileState?.first_name;
  const last_name = profileState?.last_name;
  const username = profileState?.username;

  const displayedName =
    first_name && last_name ? `${first_name} ${last_name}` : username;

  return (
    <PrivateLayout>
      <HomeStyled>
        {structures && (
          <>
            {structures.length ? (
              <StructuresView />
            ) : (
              <WelcomeView displayedName={displayedName} />
            )}
          </>
        )}
      </HomeStyled>
    </PrivateLayout>
  );
};

export default Home;
