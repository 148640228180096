import { disambiguate } from 'api';
import { useQuery } from 'react-query';

import DisambiguateView from './Disambiguate';
import { useSearchParams } from 'react-router-dom';

const Disambiguate = () => {
  const [searchParams] = useSearchParams();
  const serviceProviderIdFromParams = searchParams.get('serviceProviderId');
  const serviceProviderId =
    typeof serviceProviderIdFromParams === 'string'
      ? Number(serviceProviderIdFromParams)
      : null;
  const continueParam = searchParams.get('continue');

  const { data } = useQuery(
    'disambiguate',
    () =>
      disambiguate({
        service_provider_id: serviceProviderId,
        ambiguous_continue: continueParam,
      }),
    {
      refetchOnWindowFocus: false,
      enabled: true,
      retry: false,
      cacheTime: 0,
    },
  );

  // learning connect open modules in new window and give it specific name to remember which structure was used to access module
  // <a ... target="structureIdentifier_XXXXXXX_RANDOM">
  const structureIdentifier = window.name.split('_').slice(1, -1).join('_');
  if (structureIdentifier) {
    const match = data?.identities?.find(
      (identity) => identity.structure_identifier === structureIdentifier,
    );
    if (match) {
      window.location.href = match.continue;
    }
  }

  return <DisambiguateView identities={data?.identities} />;
};

export default Disambiguate;
