export const MATOMO_CUSTOM_DIMENTIONS = [
  {
    trackMethod: 'pageView',
    dimensions: [
      // sessions values
      {
        id: 1,
        name: `domain`, // user email domain
      },
      {
        id: 2,
        name: `profile`, // Teacher or Student
      },
      {
        id: 3,
        name: `type`, // Standard, Demo or Classroom
      },
    ],
  },
  {
    trackMethod: 'event',
    dimensions: [
      // discret values
      {
        id: 4,
        name: `structure`,
      },
      {
        id: 5,
        name: `bouquet`,
      },
    ],
  },
];
