import styled from 'styled-components';
interface StyledComponentsProps {
  borderColor: string;
}
export const RadioButtonsWrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const RadioButtonItem = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 50%;
  position: relative;
`;

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 25%;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: white;
  border: 1px solid #bebebe;
`;
export const RadioButton = styled.input<StyledComponentsProps>`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 10px;
  ${(props) =>
    props.checked &&
    ` 
    &:checked + ${RadioButtonLabel} {
      border: 1px solid rgb(${props.borderColor});
      &::after {
        content: "";
        display: block;
        border-radius: 50%;
        width: 11px;
        height: 11px;
        margin: 5.5px;
        box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
        background: black;
      }
    }
  `}
`;
