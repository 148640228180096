import { useError } from 'hooks';
import { useEffect, useState } from 'react';

export const ErrorMsg = ({
  error,
  namespace,
}: {
  error: any; // depends of source...
  namespace: string;
}) => {
  /* -- Const ------------------------------------------------------------- */

  const { getMessage } = useError();

  /* -- States ------------------------------------------------------------- */

  const [message, setMessage] = useState<string>();

  /* -- Effects ------------------------------------------------------------- */

  useEffect(() => {
    setMessage(getMessage(error, namespace));
  }, [getMessage, error, namespace]);

  /* -- Return ------------------------------------------------------------- */

  return message ? <span className="ErrorMsg">{message}</span> : <></>;
};
