import { useEffect, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { AnimalsPassword } from 'components/atoms';
import { routes } from 'router/routes';
import styled from 'styled-components';
import { getClassrooms, getPublicClassroom, getWayfQrCode } from 'api';
import { ClassroomType, PublicClassroomType } from 'models';
import { getRootUrl } from 'utils';
import { useSpacePath } from 'hooks';
import { useTranslation } from 'react-i18next';

const ClassroomPrint = () => {
  const params = useParams();
  const classroomKey = params['classroomKey'];
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.classrooms',
  });

  const [classroom, setClassroom] = useState<ClassroomType | undefined>();
  const [classroomPublic, setClassroomPublic] = useState<
    PublicClassroomType | undefined
  >();
  const [url, setUrl] = useState<string>('');
  const [base64img, setBase64img] = useState();
  const spacePath: string = useSpacePath();

  const printPage = () => {
    window.print();
  };

  useEffect(() => {
    if (!classroomKey) return;
    getClassrooms().then((classrooms) => {
      setClassroom(classrooms.find((cl) => cl.classroom_key === classroomKey));
    });
    setUrl(
      () =>
        getRootUrl() +
        generatePath(spacePath + routes.loginClassroom.path, {
          classroomKey: classroomKey,
        }),
    );
  }, [classroomKey, spacePath]);

  useEffect(() => {
    if (!classroomKey) return;
    getPublicClassroom(classroomKey).then((datas) => {
      setClassroomPublic(datas as PublicClassroomType);
    });
  }, [classroomKey]);

  useEffect(() => {
    if (!url) return;
    getWayfQrCode(url).then((data) => {
      setBase64img(data.data);
    });
  }, [url]);

  return (
    <ClassroomPrintStyle>
      <div className="print">
        <button onClick={printPage}>{t('printPage')}</button>
      </div>
      <div className="title">
        <h1>{t('classroomAccount')}</h1>
        <p>
          {classroomPublic?.structure_name}
          <br />
          {classroomPublic?.display_name}
        </p>
      </div>
      <div className="link">
        {t('gotoPage')} : <br />
        <a href={`${url}`}>{url}</a>
        <br />
        <br />
        {base64img && (
          <div className="qr">
            <img src={base64img} />
          </div>
        )}
      </div>
      {classroom && (
        <div className="password">
          {t('password')} :<br />
          <AnimalsPassword password={classroom.password} />
        </div>
      )}
    </ClassroomPrintStyle>
  );
};

export default ClassroomPrint;

const ClassroomPrintStyle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  min-height: 100%;
  gap: 30px;
  h1 {
    font-size: 30px;
    border: none;
  }
  .qr img {
    width: 256px;
    height: auto;
  }
  .password * {
    -webkit-print-color-adjust: exact !important; /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important; /* Firefox 48 – 96 */
    print-color-adjust: exact !important; /* Firefox 97+, Safari 15.4+ */
  }
  .print {
    button {
      padding: 0 5px;
    }
  }
  @media print {
    .print {
      display: none;
    }
  }
`;
