import styled from 'styled-components';

export const StudentsHomeStyled = styled.div`
  height: 100%;

  .visual {
    background-color: lightgrey;
    background-color: rgba(${({ theme }) => theme.colors.primaryLight});
    min-height: 160px;
  }
`;
