import { useCallback, useEffect, useState } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import CreateAccountView from './CreateAccount';
import {
  createWayfAccount,
  getWayfStructure,
  getMailinglists,
  getSpaces,
} from 'api';
import { SecurityIndicatorVariants } from 'appConstants';
import { routes } from 'router/routes';
import { validateEmail } from 'utils/tools';

import { WayfStructure, Mailinglist, Subscription, SpaceType } from 'models';
import { useSpacePath, useCustomMatomo } from 'hooks';
import { useRecoilValue } from 'recoil';
import { AuthState, SessionSpaceState } from 'recoilTools';

const classroomRegex = new RegExp(`^classe-`);

const CreateAccount = () => {
  /* -- Const ------------------------------------------------------------- */

  const { trackMatomoEvent } = useCustomMatomo();
  const navigate = useNavigate();
  const spacePath: string = useSpacePath();
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const authState = useRecoilValue(AuthState);

  /* -- States ------------------------------------------------------------- */

  const [wayfStructure, setWayfStructure] = useState<WayfStructure | undefined>(
    undefined,
  );
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [userName, setUserName] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [userNameError, setUserNameError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [conditionsChecked, setConditionsChecked] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [passwordQuality, setPasswordQuality] =
    useState<SecurityIndicatorVariants>();
  const [allSpaces, setAllSpaces] = useState<SpaceType[]>([]);
  const [errorCode, setErrorCode] = useState<string>();

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const createWayfAccountMutation = useMutation(
    'createAccount',
    createWayfAccount,
    {
      retry: false,
    },
  );

  const getWayfStructureMutation = useMutation(
    'getWayfStructure',
    getWayfStructure,
    {
      retry: false,
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const toggleConditionsCheckbox = useCallback(
    () => setConditionsChecked((prevState) => !prevState),
    [],
  );

  const toggleSubscription = useCallback(
    (id: string) => {
      const subs = subscriptions.map((sub) => {
        if (sub.identifier === id) sub.accepted = !sub.accepted;
        return sub;
      });
      setSubscriptions(subs);
    },
    [subscriptions],
  );

  const submitButtonAvailable = useCallback(
    () =>
      !!(
        userName &&
        !userName.match(classroomRegex) &&
        userName &&
        firstName &&
        lastName &&
        validateEmail(email) &&
        password &&
        confirmPassword &&
        password === confirmPassword &&
        // cgu are only to be accepted for default space
        ((sessionSpace && sessionSpace.space_key !== 'default') ||
          conditionsChecked) &&
        passwordQuality === SecurityIndicatorVariants.HARD
      ),
    [
      userName,
      firstName,
      lastName,
      email,
      password,
      confirmPassword,
      sessionSpace,
      conditionsChecked,
      passwordQuality,
    ],
  );

  const onSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (submitButtonAvailable()) {
        createWayfAccountMutation.mutate({
          uai_code: wayfStructure?.uai || '',
          username: userName,
          last_name: lastName,
          first_name: firstName,
          space_key:
            sessionSpace?.space_key && sessionSpace.space_key !== 'default'
              ? sessionSpace.space_key
              : null,
          email,
          password,
          subscriptions,
        });
      }
    },
    [
      userName,
      firstName,
      lastName,
      password,
      email,
      subscriptions,
      wayfStructure,
      sessionSpace?.space_key,
      createWayfAccountMutation,
      submitButtonAvailable,
    ],
  );

  /* -- Effects ------------------------------------------------------------- */

  // // load structure
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const uai = urlParams.get('structure');
    if (uai) {
      if (
        !getWayfStructureMutation.data &&
        !getWayfStructureMutation.isLoading
      ) {
        getWayfStructureMutation.mutate({ uai, domain: '' });
      }
    } else {
      navigate(`/${spacePath}${routes.wayf.path}`);
    }
  }, [navigate, spacePath, getWayfStructureMutation]);

  useEffect(() => {
    if (getWayfStructureMutation.isSuccess) {
      setWayfStructure(getWayfStructureMutation.data);
    }
  }, [getWayfStructureMutation]);

  useEffect(() => {
    const spaceKey: string | null =
      sessionSpace?.space_key && sessionSpace.space_key !== 'default'
        ? sessionSpace.space_key
        : null;
    if (!spaceKey) return;
    getMailinglists(spaceKey).then((mls: Mailinglist[]) => {
      if (mls) {
        setSubscriptions(
          mls
            .filter((ml: Mailinglist) => ml.open_to_regular_user)
            .map((ml: Mailinglist) => ({
              identifier: `${ml.identifier}`,
              accepted: false,
            })),
        );
      }
    });
  }, [sessionSpace?.space_key]);

  useEffect(() => {
    getSpaces().then((spaces: SpaceType[]) => {
      setAllSpaces(spaces);
    });
  }, []);

  useEffect(() => {
    if (authState) {
      setIsLoginLoading(false);
      navigate(`/${routes.home.path}`);
    }
  }, [navigate, authState]);

  // login with new credentials after account created
  useEffect(() => {
    if (!createWayfAccountMutation.isSuccess) return;
    if (createWayfAccountMutation.data?.activation_required) {
      {
        navigate(
          generatePath(
            `/${spacePath}${routes.accountActivation.path}?username=${createWayfAccountMutation.data.username}`,
          ),
        );
      }
    } else {
      trackMatomoEvent({
        category: 'Setup',
        action: 'Create account',
        name: 'Standard',
      });
      navigate(`/${spacePath}${routes.login.path}`);
    }
  }, [createWayfAccountMutation, navigate, spacePath, trackMatomoEvent]);

  useEffect(() => {
    const axiosError: AxiosError =
      createWayfAccountMutation.error as AxiosError<{
        error_message?: string;
        reasons?: string[];
      }>;
    const error_message: string =
      axiosError?.response?.data?.error_message || '';
    const reasons: string[] = axiosError?.response?.data?.reasons || [];
    if (!error_message) return;

    const newEmailError = reasons?.find((err) => err.includes('email'));
    const newUserNameError = reasons?.find((err) => err.includes('username'));
    setEmailError(newEmailError);
    setUserNameError(newUserNameError);
    if (!newUserNameError && !newEmailError) {
      setErrorCode(
        error_message.indexOf('UAI') !== -1 ? 'uaiError' : 'genericError',
      );
    } else {
      setErrorCode('');
    }
  }, [createWayfAccountMutation.error]);

  useEffect(() => {
    const elements = document.querySelectorAll('.WithLeftIconIcon');
    for (let i = 0; i < elements.length; i++) {
      const icon = elements[i].nextSibling as HTMLElement;
      icon.setAttribute('aria-hidden', 'true');
    }
  }, []);

  /* -- Return ------------------------------------------------------------- */

  return (
    <CreateAccountView
      wayfStructure={wayfStructure}
      userName={userName}
      setUserName={setUserName}
      firstName={firstName}
      setFirstName={setFirstName}
      lastName={lastName}
      setLastName={setLastName}
      email={email}
      setEmail={setEmail}
      password={password}
      setPassword={setPassword}
      setPasswordQuality={setPasswordQuality}
      confirmPassword={confirmPassword}
      setConfirmPassword={setConfirmPassword}
      conditionsChecked={conditionsChecked}
      toggleConditionsCheckbox={toggleConditionsCheckbox}
      subscriptions={subscriptions}
      toggleSubscription={toggleSubscription}
      submitButtonAvailable={submitButtonAvailable()}
      onSubmit={onSubmit}
      isLoading={createWayfAccountMutation.isLoading || isLoginLoading}
      userNameError={userNameError}
      emailError={emailError}
      errorCode={errorCode}
      allSpaces={allSpaces}
    />
  );
};

export default CreateAccount;
