import { CheckIconBold } from '@maskott/tactileo.designsystem';
import styled from 'styled-components';

interface MediaProps {
  isChecked: boolean;
  borderColor: string;
}
export const CbContainerS = styled.div`
  display: inline-block;
  vertical-align: middle;
  margin-right: 16px;
`;

export const CbInputS = styled.input`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const CbLabelS = styled.label<MediaProps>`
  border: ${({ isChecked, borderColor }) =>
    isChecked ? `1px solid rgb(${borderColor})` : '1px solid gray'};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  background: white;
  border-radius: 4px;
  &:hover {
    outline: 2px solid grey;
  }
  input:focus + & {
    outline: 2px solid rgb(${(p) => p.theme.colors.primaryLight});
    border-color: rgb(${(p) => p.theme.colors.primary});
  }
`;
export const CbIconS = styled(CheckIconBold)<MediaProps>`
  color: rgb(${(props) => props.theme.colors.primary});
  visibility: ${({ isChecked }) => (isChecked ? 'visible' : 'hidden')};
  width: 18px;
  height: 18px;
`;
