import { PublicLayout } from 'components/layout';
import { useTranslation } from 'react-i18next';
import { routes } from 'router/routes';

import {
  LinkStyled,
  WelcomeTextStyled,
  BoldTextStyled,
} from 'pages/private/Home/Home.styles';

interface WelcomeViewProps {
  displayedName?: string;
}

const WelcomeView = ({ displayedName }: WelcomeViewProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.home',
  });

  return (
    <PublicLayout>
      <WelcomeTextStyled>
        {t('welcomeUser', { name: displayedName })}
      </WelcomeTextStyled>
      <BoldTextStyled>
        {t('toTakeAdvantage')}
        <LinkStyled
          to={{
            pathname: `/${routes.structures.path}`,
          }}
        >
          {t('addingStructure')}
        </LinkStyled>
      </BoldTextStyled>
    </PublicLayout>
  );
};

export default WelcomeView;
