import { atom } from 'recoil';
import { ProfileType } from '../models/auth';
import { ModalType, StructureType } from '../models';

export const ProfileState = atom<ProfileType>({
  key: 'userProfile',
  default: {},
});

export const StructureState = atom<StructureType | undefined>({
  key: 'currentStructure',
  default: undefined,
});

export const StructuresState = atom<StructureType[] | undefined>({
  key: 'structures',
  default: undefined,
});

export const ModalState = atom<ModalType | undefined>({
  key: 'modal',
  default: undefined,
});
