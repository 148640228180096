import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import placeholderLogoService from 'assets/images/placeholderLogoService.png';
import { ReactComponent as InfoIcon } from 'assets/images/info-icon.svg';
import { ReactComponent as RightArrowIcon } from 'assets/images/right-arrow-icon.svg';

import { PdfLayout } from 'components/layout';
import { Modal } from '@maskott/tactileo.designsystem';

import { StructureServiceStyle } from './StructureService.styles';

interface StructureServiceProps {
  color?: string;
  structureIdentifier: string | undefined;
  title: string | null;
  description: string | null;
  imageUri: string | null;
  accessUri: string;
  informationFileUri: string | null;
  className?: string;
  hideInfoButton?: boolean;
  onModuleAccess: (props: { title: string; accessUri: string }) => void;
}

const StructureService = ({
  color,
  structureIdentifier,
  title,
  description,
  imageUri,
  accessUri,
  informationFileUri,
  className,
  hideInfoButton,
  onModuleAccess,
}: StructureServiceProps) => {
  const [isInfoModalOpened, setIsInfoModalOpened] = useState(false);

  const { t } = useTranslation('translation', {
    keyPrefix: 'atoms.structureService',
  });

  const openInfoModal = useCallback(() => {
    setIsInfoModalOpened(true);
  }, [setIsInfoModalOpened]);

  const closeInfoModal = useCallback(() => {
    setIsInfoModalOpened(false);
  }, [setIsInfoModalOpened]);

  const randomNumber = () => Math.floor(Math.random() * 10000000000);

  const pdfFileUrl = informationFileUri ? informationFileUri : '';

  return (
    <>
      <StructureServiceStyle color={color || ''}>
        <a
          className={'serviceLink ' + className}
          target={`structureIdentifier_${structureIdentifier}_${randomNumber()}`}
          rel="noopener noreferrer"
          href={accessUri}
          onClick={() => {
            if (title && accessUri) {
              onModuleAccess({
                title,
                accessUri,
              });
            }
          }}
        >
          <div className="serviceInner">
            <img
              className="serviceLogo"
              alt={`${title} logo`}
              src={imageUri || placeholderLogoService}
            />

            <div className="serviceInfos">
              <div className="serviceTitle">{title}</div>
              <div className="serviceDescription">{description}</div>
            </div>
          </div>
          {
            <div className="serviceButton serviceOpenButton">
              <RightArrowIcon className="serviceIcon" />
            </div>
          }
        </a>
        {!hideInfoButton && pdfFileUrl && (
          <button
            className="serviceButton serviceInfoButton"
            aria-label={t('infoBtnAriaLabel')}
            onClick={openInfoModal}
          >
            <InfoIcon className="serviceIcon" />
          </button>
        )}
      </StructureServiceStyle>
      <Modal
        isAriaHidden={`${!isInfoModalOpened}`}
        size="XL"
        title={t('infoModalTitle', { serviceName: title })}
        isOpen={isInfoModalOpened}
        ActionLabel={t('modalCloseButton')}
        ActionFunc={closeInfoModal}
        closeModal={closeInfoModal}
        showHeader
      >
        <PdfLayout pdfFileUrl={pdfFileUrl} height="70vh" />
      </Modal>
    </>
  );
};

export default StructureService;
