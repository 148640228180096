import { useTranslation } from 'react-i18next';

import { PublicLayout } from 'components/layout';
import {
  DisambiguatePageStyled,
  IdentityLinkStyled,
  DescriptionStyled,
  SmallTextStyled,
  IdentitiesListStyled,
  ArrowRightIconStyled,
} from './Disambiguate.styles';
import { IdentityType } from 'models';

type DisambiguatePropsType = {
  identities?: IdentityType[];
};

const Disambiguate = ({ identities }: DisambiguatePropsType) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.login',
  });

  return (
    <PublicLayout title={t('chooseStructure')}>
      <DescriptionStyled>{t('theServiceYouWantToAccess')}</DescriptionStyled>
      <DescriptionStyled>{t('pleaseChooseTheOne')}</DescriptionStyled>
      <DisambiguatePageStyled>
        <IdentitiesListStyled>
          {identities?.map((identity) => (
            <IdentityLinkStyled
              key={identity.structure_identifier}
              href={identity.continue}
            >
              {identity.structure_name}
              <ArrowRightIconStyled color="black" />
            </IdentityLinkStyled>
          ))}
        </IdentitiesListStyled>

        <SmallTextStyled>{t('maskotFIDAccount')}</SmallTextStyled>
      </DisambiguatePageStyled>
    </PublicLayout>
  );
};

export default Disambiguate;
