import { useState, useEffect, useCallback, FormEvent } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { PrivateLayout } from 'components/layout';
import { StructuresStyled } from './PersonalData.styles';
import { Breadcrumb, FormPasswordInput } from 'components/atoms';
import { ProfileState } from 'recoilTools';
import { useRecoilState } from 'recoil';
import { useMutation } from 'react-query';
import {
  getProfileDetails,
  updateProfile,
  UpdateProfilePayloadType,
  changePassword,
} from 'api';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { routes } from 'router/routes';
import { SecurityIndicatorVariants } from 'appConstants';
import { useError } from 'hooks';
import Holder from 'components/atoms/PlaceHolders/Holder';

/***********************************************************/
/***********************************************************/
/** PersonalData COMPONENT **/

const PersonalData = () => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.personalData',
  });
  const navigate = useNavigate();
  interface passwordDataInterface {
    current_password: string;
    new_password: string;
    new_password_again: string;
  }

  /* -- States ------------------------------------------------------------- */

  const [profileState, setProfileState] = useRecoilState(ProfileState);
  const [personalData, setPersonalData] = useState<UpdateProfilePayloadType>({
    first_name: '',
    last_name: '',
    culture_name: '',
  });

  const [passwordData, setPasswordData] = useState<passwordDataInterface>({
    current_password: '',
    new_password: '',
    new_password_again: '',
  });
  const [passwordQuality, setPasswordQuality] =
    useState<SecurityIndicatorVariants>();
  const { getMessage } = useError();

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const updateProfileMutation = useMutation('updateProfile', updateProfile, {
    retry: false,
    onSuccess: () => {
      getProfileDetails().then((data) => {
        setProfileState(data);
      });
      toast(t('profileModified'), {
        type: 'success',
      });
    },
    onError: (
      error: AxiosError<{ error_message: string; reason: string[] | null }>,
    ) => {
      toast(getMessage(error.response?.data, 'user.personalData'), {
        type: 'error',
        autoClose: false,
        closeButton: true,
      });
    },
  });

  const changePasswordMutation = useMutation('changePassword', changePassword, {
    retry: false,
    onSuccess: () => {
      toast(t('passwordModified'), {
        type: 'success',
      });
    },
    onError: (
      error: AxiosError<{ error_message: string; reason: string[] | null }>,
    ) => {
      toast(getMessage(error.response?.data, 'user.personalData'), {
        type: 'error',
        autoClose: false,
        closeButton: true,
      });
    },
  });

  /* -- Callbacks ------------------------------------------------------------- */

  const onChange = useCallback(
    (event: FormEvent<HTMLInputElement | HTMLSelectElement>) => {
      const target: HTMLInputElement | HTMLSelectElement = event.target as
        | HTMLInputElement
        | HTMLSelectElement;
      if (Object.prototype.hasOwnProperty.call(personalData, target.id)) {
        setPersonalData((_personalData) => ({
          ..._personalData,
          ...{ [target.id]: target.value },
        }));
      }
    },
    [personalData],
  );

  const onSubmitModification = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      const target: HTMLFormElement = event.target as HTMLFormElement;
      event.preventDefault();
      if (
        target.first_name.value &&
        target.last_name.value &&
        target.culture_name.value
      ) {
        updateProfileMutation.mutate({
          first_name: target.first_name.value,
          last_name: target.last_name.value,
          culture_name: target.culture_name.value,
        });
      }
      return false;
    },
    [updateProfileMutation],
  );

  const onChangePassword = useCallback(
    (target: { id: string; value: string }) => {
      if (Object.prototype.hasOwnProperty.call(passwordData, target.id)) {
        setPasswordData((_passwordData) => ({
          ..._passwordData,
          ...{ [target.id]: target.value },
        }));
      }
      changePasswordMutation.reset();
    },
    [passwordData, changePasswordMutation],
  );

  const onSubmitPasswordModification = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      const target: HTMLFormElement = event.target as HTMLFormElement;
      event.preventDefault();
      if (
        target.current_password.value &&
        target.new_password.value &&
        target.new_password_again.value
      ) {
        changePasswordMutation.mutate({
          current_password: target.current_password.value,
          new_password: target.new_password.value,
        });
      } else {
        if (target.new_password.value !== target.new_password_again.value) {
          //
        }
      }
      return false;
    },
    [changePasswordMutation],
  );

  /* -- Effects ------------------------------------------------------------- */

  useEffect(() => {
    // redirect if classroom account
    if (profileState?.is_classroom_account) {
      navigate(`/${routes.home.path}`);
    }
  }, [profileState, navigate]);

  useEffect(() => {
    setPersonalData({
      first_name: profileState.first_name || '',
      last_name: profileState.last_name || '',
      culture_name: profileState.culture_name || '',
    });
  }, [profileState]);

  /* -- Return ------------------------------------------------------------- */

  const mutating: boolean =
    updateProfileMutation.isLoading || changePasswordMutation.isLoading;

  const hasLoginProvider = !!profileState?.current_login_provider;
  const isClassroomAccount = profileState?.is_classroom_account;
  const isLearnerAccount = profileState?.is_learner_account;

  return (
    <PrivateLayout>
      <div id="studentsHome-page">
        <StructuresStyled>
          <div className="container p-4">
            <Breadcrumb
              homePath={routes.home.path}
              items={[
                {
                  name: t('personalData'),
                },
              ]}
            />
            <h1>{t('personalData')}</h1>

            <div>
              <Trans t={t} i18nKey="personalDataText" />
            </div>

            {/* Account modification section ------------------------- */}
            {profileState?.id && !isClassroomAccount && (
              <>
                <h2>{t('modification')}</h2>
                <form onSubmit={onSubmitModification}>
                  {!updateProfileMutation.isLoading ? (
                    <>
                      {!isClassroomAccount && !isLearnerAccount && (
                        <>
                          <div className="form-group mb-3">
                            <label htmlFor="username">{t('username')}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="username"
                              value={profileState.username || ''}
                              onChange={onChange}
                              placeholder=""
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                          <div className="form-group mb-3">
                            <label htmlFor="email">{t('email')}</label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              value={profileState.email || ''}
                              onChange={onChange}
                              placeholder=""
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </>
                      )}
                      {/* disabled={mutating}
                    loading={updateProfileMutation.isLoading} */}
                      <div className="row">
                        <div className="form-group mb-3 col">
                          <label htmlFor="first_name">{t('firstName')}</label>
                          <input
                            type="text"
                            className="form-control"
                            id="first_name"
                            value={personalData?.first_name || ''}
                            onChange={onChange}
                            placeholder=""
                            autoComplete="off"
                            disabled={hasLoginProvider || isLearnerAccount}
                          />
                        </div>
                        <div className="form-group mb-3 col">
                          <label htmlFor="last_name">{t('lastName')}</label>
                          <input
                            type="text"
                            className="form-control"
                            id="last_name"
                            value={personalData?.last_name || ''}
                            onChange={onChange}
                            placeholder=""
                            autoComplete="off"
                            disabled={hasLoginProvider || isLearnerAccount}
                          />
                        </div>
                      </div>
                      <div className="form-group mb-3">
                        <label htmlFor="culture_name">{t('cultureName')}</label>
                        <select
                          className="form-select"
                          id="culture_name"
                          value={personalData?.culture_name}
                          onChange={onChange}
                        >
                          <option value="fr">Français</option>
                          <option value="en">English</option>
                        </select>
                      </div>
                      <button
                        type="submit"
                        className="btn btn-outline-dark"
                        disabled={
                          mutating ||
                          !personalData?.last_name ||
                          !personalData?.first_name
                        }
                      >
                        {(!updateProfileMutation ||
                          updateProfileMutation.isLoading) && (
                          <>
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            />{' '}
                          </>
                        )}
                        {t('modify')}
                      </button>
                    </>
                  ) : (
                    <></>
                  )}
                </form>

                {(!updateProfileMutation ||
                  updateProfileMutation.isLoading) && <Holder nb={4} />}

                {/* Change password section ------------------------- */}

                {!hasLoginProvider &&
                  !isClassroomAccount &&
                  !isLearnerAccount && (
                    <>
                      <h2>{t('passwordModification')}</h2>
                      <form onSubmit={onSubmitPasswordModification}>
                        {/* disabled={mutating}
                        loading={changePasswordMutation.isLoading} */}
                        <div className="form-group mb-3">
                          <label htmlFor="current_password">
                            {t('currentPassword')}
                          </label>
                          <FormPasswordInput
                            id="current_password"
                            value={passwordData?.current_password}
                            onChange={(value) => {
                              onChangePassword({
                                id: 'current_password',
                                value,
                              });
                            }}
                            placeholder=""
                            state={
                              changePasswordMutation.error &&
                              changePasswordMutation.error?.response?.data.error_message.indexOf(
                                'current',
                              ) !== -1
                                ? 'error'
                                : null
                            }
                          />
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="new_password">
                            {t('newPassword')}
                          </label>
                          <FormPasswordInput
                            id="new_password"
                            value={passwordData?.new_password}
                            onChange={(value) => {
                              onChangePassword({
                                id: 'new_password',
                                value,
                              });
                            }}
                            placeholder=""
                            qualityIndicator
                            onQualityChanged={setPasswordQuality}
                            state={null}
                          />
                          {!!passwordData?.new_password &&
                            passwordQuality !==
                              SecurityIndicatorVariants.HARD && (
                              <small className="form-text text-danger">
                                {t('passwordTooltip')}
                              </small>
                            )}
                        </div>
                        <div className="form-group mb-3">
                          <label htmlFor="new_password_again">
                            {t('newPasswordAgain')}
                          </label>
                          <FormPasswordInput
                            id="new_password_again"
                            value={passwordData?.new_password_again}
                            onChange={(value) => {
                              onChangePassword({
                                id: 'new_password_again',
                                value,
                              });
                            }}
                            placeholder=""
                            state={
                              passwordData.new_password_again &&
                              passwordData.new_password !==
                                passwordData.new_password_again
                                ? 'error'
                                : null
                            }
                          />
                          {passwordData.new_password &&
                            passwordData.new_password_again &&
                            passwordData.new_password !==
                              passwordData.new_password_again && (
                              <small className="form-text text-danger">
                                {t('newPasswordsNotMatching')}
                              </small>
                            )}
                        </div>
                        <button
                          type="submit"
                          className="btn btn-outline-dark"
                          disabled={
                            mutating ||
                            !passwordData.current_password ||
                            !passwordData.new_password ||
                            !passwordData.new_password_again ||
                            passwordData.new_password !==
                              passwordData.new_password_again ||
                            passwordQuality !== SecurityIndicatorVariants.HARD
                          }
                        >
                          {(!changePasswordMutation ||
                            changePasswordMutation.isLoading) && (
                            <>
                              <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                              />{' '}
                            </>
                          )}
                          {t('changePassword')}
                        </button>
                      </form>

                      {/* Delete account section ------------------------- */}

                      <h2>{t('deleteAccount')}</h2>
                      <div>
                        <Trans t={t} i18nKey="deleteAccountText" />
                      </div>
                      <div className="text-danger">
                        <Link
                          to={`/${routes.deleteAccount.path}`}
                          className="text-danger"
                        >
                          {t('deleteAccount')}
                        </Link>
                      </div>
                    </>
                  )}
              </>
            )}
          </div>
        </StructuresStyled>
      </div>
    </PrivateLayout>
  );
};

/***********************************************************/
/***********************************************************/
export default PersonalData;
