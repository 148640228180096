import { useCallback, useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { ProfileState, StructureState, SessionSpaceState } from 'recoilTools';
import { MATOMO_CUSTOM_DIMENTIONS } from 'appConstants';
import {
  getAppEnv,
  initMatomo,
  trackPageView,
  trackEvent,
  TrackPageViewParams,
  TrackEventParams,
  trackCustomDimensions,
  CustomDimensions,
  CustomDimension,
} from 'utils';

/************************************/
/* HOOK : Matomo */

export const useCustomMatomo = () => {
  const profileState = useRecoilValue(ProfileState);
  const structureState = useRecoilValue(StructureState);
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const [customDimensions, setCustomDimensions] = useState<CustomDimensions>({
    domain: '',
    structure: '',
    bouquet: '',
    profile: '',
    type: '',
  });

  // build customDimensions object for 'trackMethod' with available dimensions in data
  const formatDimensions: (
    trackMethod: string,
    data: CustomDimensions,
  ) => Array<CustomDimension> = useCallback((trackMethod, data) => {
    const customDimensionsSelected = MATOMO_CUSTOM_DIMENTIONS.find(
      (elt) => elt.trackMethod === trackMethod,
    );
    if (!customDimensionsSelected) return [];
    return customDimensionsSelected.dimensions.reduce<Array<CustomDimension>>(
      (acc, dimension) => {
        const value = data[dimension.name as keyof CustomDimensions];
        // if (value) {
        acc.push({
          id: dimension.id,
          value: value,
        });
        // }
        return acc;
      },
      [],
    );
  }, []);

  // retrieve context values before building customDimensions object
  useEffect(() => {
    const emailTab: string[] = profileState?.email?.split('@') || [];
    const domain: string = emailTab?.length === 2 ? emailTab[1] : '';
    let accountType: string = '';
    if (profileState?.username) {
      accountType = 'Standard';
    } else if (profileState?.is_demo_account) {
      accountType = 'Demo';
    } else if (profileState?.is_classroom_account) {
      accountType = 'Classroom';
    }
    const structureLabel: string = structureState?.structure_name
      ? `${structureState?.structure_name} (${structureState?.structure_identifier})`
      : '';
    let profile: string = '';
    if (structureState?.is_teacher) {
      profile = 'Teacher';
    } else if (structureState?.is_learner) {
      profile = 'Student';
    }
    setCustomDimensions({
      domain,
      structure: structureLabel,
      bouquet: sessionSpace?.space_key || '',
      profile,
      type: accountType,
    });
  }, [
    profileState?.username,
    profileState?.email,
    profileState?.is_classroom_account,
    profileState?.is_demo_account,
    structureState?.structure_name,
    structureState?.structure_identifier,
    structureState?.is_teacher,
    structureState?.is_learner,
    sessionSpace?.space_key,
  ]);

  const trackMatomoPageView = useCallback(
    (trackPageViewData: TrackPageViewParams) => {
      const { isProduction } = getAppEnv();
      if (
        !(
          isProduction &&
          customDimensions.domain &&
          customDimensions.domain === 'maskott.com'
        )
      ) {
        initMatomo();
        trackCustomDimensions(formatDimensions('pageView', customDimensions));
        trackPageView(trackPageViewData);
      }
    },
    [customDimensions, formatDimensions],
  );

  const trackMatomoEvent = useCallback(
    (trackEventData: TrackEventParams) => {
      const { isProduction } = getAppEnv();
      if (
        !(
          isProduction &&
          customDimensions.domain &&
          customDimensions.domain === 'maskott.com'
        )
      ) {
        initMatomo();
        trackCustomDimensions(formatDimensions('event', customDimensions));
        trackEvent(trackEventData);
      }
    },
    [customDimensions, formatDimensions],
  );

  return {
    trackMatomoEvent,
    trackMatomoPageView,
  };
};
