import { error, neutralLight, secondaryMain } from 'assets/colors';
import { RoundedCornerButton } from 'components/atoms';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

interface MediaProps {
  isLargerThan767: boolean;
}
export const CreateAccountPageStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  .wayf_structure {
    background-color: transparent;
    border: none;
    padding: 0.5em 1em;
    margin: 0 0 1em 0;
    border-radius: 5px;
    font-size: 1em;
    border: solid 1px #eeeeee;
    font-weight: bold;
    width: auto;
    color: black;
    .wayf_id {
      font-size: 0.9em;
      font-weight: normal;
    }
  }
  @media only screen and (max-width: 767px) {
    input {
      border: 1px solid rgb(${(props) => props.theme.colors.primary});
      box-shadow: 0px 1px 0px rgb(${(props) => props.theme.colors.primary});
    }
    input:focus {
      border: 2px solid rgb(${(props) => props.theme.colors.primary});
      background-color: ${neutralLight};
    }
    .errorState {
      border: 2px solid ${error}!important;
      box-shadow: 0px 1px 0px ${error};
      background-color: #fce8e8 !important;
    }
  }
`;

export const FieldStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FieldErrorStyled = styled.span`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${error};
`;

export const FormErrorStyled = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: ${error};
  text-align: center;
  margin: 30px 0;
`;

export const FieldsWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 24px;
`;

export const LabelStyled = styled.label`
  font-weight: 400;
  font-size: 17px;
  line-height: 20px;
  color: black;
`;

export const SmallLinkStyled = styled(Link)`
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  color: ${secondaryMain};
`;

export const SmallTextStyled = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: black;
  margin: 0;
`;

export const TextStyled = styled.p`
  font-weight: 400;
  /* font-size: 12px;
  line-height: 15px; */
  color: black;
  margin: 0;
`;

export const SmallAlertStyled = styled.p`
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${error};
  margin: 0;
`;

export const CreateAccountButtonStyled = styled(RoundedCornerButton)`
  flex-grow: 1;
`;

export const NameFieldsWrapper = styled.div<MediaProps>`
  width: 100%;
  display: flex;
  gap: 16px;
  flex-direction: ${({ isLargerThan767 }) =>
    isLargerThan767 ? 'row' : 'column-reverse'};
`;

export const PasswordFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const CheckboxWrapperStyled = styled.div`
  flex-wrap: nowrap;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
export const CheckboxWrapperStyledTop = styled.div`
  flex-wrap: nowrap;
  margin-top: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: top;
`;

export const CheckBoxLabelStyled = styled.label`
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4em;
  color: ${secondaryMain};
`;

export const CancelButtonStyled = styled(RoundedCornerButton)`
  /* width: 170px; */
  font-weight: 700;
`;

export const ButtonsBlockStyled = styled.div<MediaProps>`
  margin-top: 32px;
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: center;
  gap: 24px;
  flex-direction: ${({ isLargerThan767 }) =>
    isLargerThan767 ? 'row' : 'column-reverse'};
`;
