import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';
import { SessionSpaceState } from 'recoilTools';
import { BarContainerStyled } from './TopBarPublic.styles';
import { Link } from 'react-router-dom';
import { routes } from 'router/routes';
import { useSpacePath } from 'hooks';

const TopBarPublic = () => {
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const spacePath = useSpacePath();

  const { t } = useTranslation('translation', {
    keyPrefix: 'user.core.topBar',
  });

  return (
    <BarContainerStyled>
      {sessionSpace?.logo_uri && (
        <Link
          title={t(`topBarLogoText`)}
          to={`/${spacePath}${routes.login.path}`}
        >
          <img className="img_logo" alt="logo" src={sessionSpace?.logo_uri} />
        </Link>
      )}
    </BarContainerStyled>
  );
};

export default TopBarPublic;
