import { useCallback, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { addLearnerAccount } from 'api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { StructureState } from 'recoilTools';
import { useRecoilValue } from 'recoil';
import { useError } from 'hooks';
import StudentsAccountsLineForm from './StudentsAccountsLineForm';

/** StudentsAccounts COMPONENT *********************************************************/

const StudentsAccountsAdd = ({
  disabled,
  onChange,
  alreadyUsedUsername,
}: {
  disabled: boolean;
  onChange: (status: string) => void;
  alreadyUsedUsername: string[];
}) => {
  /* -- Const ------------------------------------------------------------- */
  const params = useParams();
  const classroomId: number = params['classroomId']
    ? parseInt(params['classroomId'])
    : -1;
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const { getMessage } = useError();

  /* -- States ------------------------------------------------------------- */
  const structure = useRecoilValue(StructureState);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const addLearnerAccountMutation = useMutation(
    'addLearnerAccount',
    addLearnerAccount,
    {
      retry: false,
      onSuccess: () => {
        toast(t('learnerAdded'), {
          type: 'success',
        });
        onChange('added');
      },
      onError: (
        error: AxiosError<{ error_message: string; reasons: string[] | null }>,
      ) => {
        toast(getMessage(error.response?.data, 'user.auth.students'), {
          type: 'error',
          autoClose: false,
          closeButton: true,
        });
        onChange('error');
      },
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onSubmitAdd = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      const target: HTMLFormElement = event.target as HTMLFormElement;
      event.preventDefault();
      if (
        structure?.structure_id &&
        target.login_name.value != '' &&
        target.last_name.value != '' &&
        target.first_name.value != ''
      ) {
        onChange('adding');
        addLearnerAccountMutation.mutate({
          structure_id: structure?.structure_id,
          classroom_id: classroomId,
          login_name: target.login_name.value,
          last_name: target.last_name.value,
          first_name: target.first_name.value,
        });
      }
    },
    [addLearnerAccountMutation, structure?.structure_id, classroomId, onChange],
  );

  /* -- Effects ------------------------------------------------------------- */

  /* -- Return ------------------------------------------------------------- */

  const mutating: boolean = addLearnerAccountMutation.isLoading;

  return (
    <>
      {' '}
      <h2>{t('addStudent')}</h2>
      <form onSubmit={onSubmitAdd}>
        <div className="table-responsive-md">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">{t('lastname')}</th>
                <th scope="col">{t('firstname')}</th>
                <th scope="col">{t('username')}</th>
                <th scope="col">&nbsp;</th>
                <th scope="col">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              {
                <StudentsAccountsLineForm
                  key={`${addLearnerAccountMutation.status === 'success'}`}
                  initialValue={{
                    last_name: '',
                    first_name: '',
                    login_name: '',
                  }}
                  disabled={disabled || mutating}
                  loading={addLearnerAccountMutation.isLoading}
                  alreadyUsed={alreadyUsedUsername}
                />
              }
            </tbody>
          </table>
        </div>
      </form>
    </>
  );
};

export default StudentsAccountsAdd;
