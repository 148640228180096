import styled from 'styled-components';
import { px, pxToRem, py, TrashBinIcon } from '@maskott/tactileo.designsystem';

import {
  error,
  neutralDark,
  neutralDarker,
  neutralMedium,
  neutralWhite,
  primaryLight,
} from 'assets/colors';

interface StructureContainerProps {
  isSelected: boolean;
}

export const StructureContainer = styled.div<StructureContainerProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${pxToRem(4)};
  width: 100%;
  height: ${pxToRem(48)};
  border-radius: ${pxToRem(8)};
  ${px(2)};
  ${py(1.25)};
  border: 2px solid rgb(${(p) => p.theme.colors.primary});
  background: ${primaryLight};
  cursor: pointer;

  ${({ isSelected }) => {
    if (isSelected) {
      return `
        background: ${neutralWhite};
        border-color: ${neutralMedium};
      `;
    }
  }}
`;

export const StructureInfo = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: ${pxToRem(4)};
`;

export const StructureName = styled.span`
  font-weight: 900;
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(14)};
  color: ${neutralDarker};
`;

export const StructureServicesCount = styled.span`
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(10)};
  color: ${neutralDark};
`;

export const RemoveButton = styled.button`
  color: ${error};
  background: none;
  box-shadow: none;
  border: none;
  text-decoration: underline;
`;

export const RemoveIcon = styled(TrashBinIcon)`
  height: ${pxToRem(20)};
  width: ${pxToRem(20)};
  cursor: pointer;
`;
