import { useRecoilValue } from 'recoil';
import { useTranslation } from 'react-i18next';

import { ProfileState, SessionSpaceState } from 'recoilTools';
import {
  UserNameBlockStyled,
  BarContainerStyled,
  LogoImageStyled,
  SmallTextStyled,
  TextBlockStyled,
  UserBlockStyled,
  FirstNameStyled,
  LastNameStyled,
  UserNameStyled,
  UserLogoStyled,
  BoldTextStyled,
  TemplateStyled,
} from './TopBar.styles';
import { UserMenu } from 'components/parts';
import { Link } from 'react-router-dom';
import { routes } from 'router/routes';
import { useMedia } from 'hooks';

const TopBar = () => {
  const profileState = useRecoilValue(ProfileState);
  const first_name = profileState?.first_name;
  const last_name = profileState?.last_name;
  const username = profileState?.username;
  const display_name = profileState?.display_name;
  const sessionSpace = useRecoilValue(SessionSpaceState);

  const { t } = useTranslation('translation', {
    keyPrefix: 'user.core.topBar',
  });

  const { isLargerThan767 } = useMedia();

  const { displayedName, userLogoChars } = (() => {
    if (display_name) {
      return {
        displayedName: display_name,
        userLogoChars: display_name?.substring(0, 2).toUpperCase(),
      };
    }

    if (!first_name || !last_name) {
      return {
        displayedName: username,
        userLogoChars: username?.charAt(0).toUpperCase(),
      };
    }

    return {
      displayedName: `${first_name} ${last_name}`,
      userLogoChars: `${first_name?.charAt(0).toUpperCase()}${last_name
        ?.charAt(0)
        .toUpperCase()}`,
    };
  })();

  return (
    <BarContainerStyled>
      {sessionSpace?.logo_uri && (
        <Link title={t(`topBarLogoText`)} to={`/${routes.home.path}`}>
          <LogoImageStyled alt="logo" src={sessionSpace?.logo_uri} />
        </Link>
      )}
      {isLargerThan767 && (first_name || last_name) && (
        <TextBlockStyled>
          <SmallTextStyled>
            {t('welcome', { name: displayedName })}
          </SmallTextStyled>
        </TextBlockStyled>
      )}
      <UserBlockStyled>
        {isLargerThan767 && (
          <UserNameBlockStyled>
            {display_name ? (
              <UserNameStyled>{displayedName}</UserNameStyled>
            ) : first_name && last_name ? (
              <>
                <FirstNameStyled>{first_name}</FirstNameStyled>
                <LastNameStyled>{last_name}</LastNameStyled>
              </>
            ) : (
              <UserNameStyled>{displayedName}</UserNameStyled>
            )}
            <TemplateStyled>
              <BoldTextStyled>{sessionSpace?.name}</BoldTextStyled>
            </TemplateStyled>
          </UserNameBlockStyled>
        )}
        <UserMenu>
          <UserLogoStyled title={t('userLogoText')}>
            {userLogoChars}
          </UserLogoStyled>
        </UserMenu>
      </UserBlockStyled>
    </BarContainerStyled>
  );
};

export default TopBar;
