import styled from 'styled-components';
import { pxToRem, pl, pr } from '@maskott/tactileo.designsystem';

import { neutralWhite } from 'assets/colors';

export const BarContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  gap: ${pxToRem(48)};
  width: 100%;
  height: ${pxToRem(160)};
  ${pl(3)};
  ${pr(2.5)};
  background: ${neutralWhite};
  z-index: 2;
  text-align: center;
  /* padding: 30px; */

  a {
    display: block;
    height: 80px;
    /* object-fit: contain; */
  }
  .img_logo {
    display: block;
    height: 80px;
    /* object-fit: contain; */
  }
`;
