import { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Spinner } from 'components/atoms';
import { PublicLayout } from 'components/layout';
import { LogoutPropagatePageStyled } from './LogoutPropagate.styles';
import { doLogout } from 'api';
import { LOGOUT_IFRAME } from 'appConstants';
import { useSpacePath } from 'hooks';
import { useSetRecoilState, useRecoilState } from 'recoil';
import {
  ProfileState,
  StructureState,
  AuthState,
  StructuresState,
} from 'recoilTools';
import { useNavigate } from 'react-router-dom';
import { routes } from 'router/routes';

const LogoutPropagate = () => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.logoutPropagate',
  });
  const spacePath: string = useSpacePath();
  const navigate = useNavigate();
  const LOADING_DELAY = 5000;

  /* -- States ------------------------------------------------------------- */

  const [loading, setLoading] = useState(true);
  const [profile, setProfile] = useRecoilState(ProfileState);
  const setStructure = useSetRecoilState(StructureState);
  const setStructures = useSetRecoilState(StructuresState);
  const setAuthState = useSetRecoilState(AuthState);

  /* -- Callbacks ------------------------------------------------------------- */

  const logout = useCallback(() => {
    setLoading(false);
    setAuthState(null);
    setProfile({});
    setStructure(undefined);
    setStructures(undefined);
    let get: string = '';
    if (profile && !profile.current_login_provider) {
      get = '?provider=false';
    } else if (profile && !!profile.current_login_provider) {
      get = '?provider=true';
    }
    navigate(`/${spacePath}${routes.afterLogout.path}${get}`);
  }, [
    profile,
    setProfile,
    setStructure,
    setStructures,
    navigate,
    spacePath,
    setAuthState,
  ]);

  /* -- Effects ------------------------------------------------------------- */

  useEffect(() => {
    const timeout = setTimeout(() => {
      doLogout().then(() => {
        logout();
      });
    }, LOADING_DELAY);
    return () => clearTimeout(timeout);
  }, [logout]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <PublicLayout title={t('logout')}>
      <LogoutPropagatePageStyled>
        <br />
        <br />
        <br />
        <br />
        {loading ? (
          <Spinner />
        ) : (
          <>
            {t('logoutDone')}
            <br />
            {t('canClose')}
          </>
        )}
      </LogoutPropagatePageStyled>
      {loading && (
        <iframe
          src={LOGOUT_IFRAME}
          style={{ position: 'absolute', width: 0, height: 0, border: 0 }}
        />
      )}
    </PublicLayout>
  );
};

export default LogoutPropagate;
