import Holder from './Holder';

const TableHolder = ({ cols, lines }: { cols: number; lines: number }) => {
  return (
    <>
      {Array(lines)
        .fill('')
        .map((elt, i) => (
          <tr key={i}>
            {Array(cols)
              .fill('')
              .map((eltj, j) => (
                <td key={j}>
                  <Holder nb={1} />
                </td>
              ))}
          </tr>
        ))}
    </>
  );
};

export default TableHolder;
