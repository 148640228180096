import { useState, useCallback, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput } from '@maskott/tactileo.designsystem';
import { StructureClassroomType } from 'models';

/** StudentsClassroomsLineForm COMPONENT *********************************************************/

const StudentsClassroomsLineForm = ({
  initialValue,
  onCancel,
  disabled,
  loading,
  error,
}: {
  initialValue: StructureClassroomType;
  onCancel?: () => void;
  disabled: boolean;
  loading: boolean;
  error?: string;
}) => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const levels: string[] = [
    'CP',
    'CE1',
    'CE2',
    'CM1',
    'CM2',
    '6e',
    '5e',
    '4e',
    '3e',
    '2nde',
    '1ère',
    'Tle',
    t('other'),
  ];

  /* -- States ------------------------------------------------------------- */

  const [classroom, setClassroom] = useState({
    ...initialValue,
  });
  const [customLevel, setCustomLevel] = useState<boolean>(false);

  /* -- Callbacks ------------------------------------------------------------- */

  const onChangeName = useCallback(
    (value: string) => {
      setClassroom({ ...classroom, name: value });
    },
    [classroom],
  );

  const onChangeLevelSelect = useCallback(
    (e: FormEvent<HTMLSelectElement>) => {
      const value: string = (e.target as HTMLSelectElement).value;
      if (value !== t('other')) {
        setClassroom({ ...classroom, level: value });
      } else {
        setCustomLevel(true);
      }
    },
    [classroom, t],
  );

  const onChangeLevel = useCallback(
    (value: string) => {
      setClassroom({ ...classroom, level: value });
    },
    [classroom],
  );

  const onClickCancel = useCallback(() => {
    setCustomLevel(false);
    if (onCancel) {
      onCancel();
    }
  }, [onCancel]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <>
      <tr>
        <td>
          {!customLevel && (
            <select
              className={`form-select ${error === 'noLevel' ? 'error' : ''}`}
              aria-label={t('classroomLevel')}
              onChange={onChangeLevelSelect}
              defaultValue=""
            >
              <option value="">{t('select')}</option>
              {levels.map((level) => (
                <option key={level} value={level}>
                  {level}
                </option>
              ))}
            </select>
          )}
          <div className={customLevel ? '' : 'd-none'}>
            <FormInput
              leftIcon={null}
              placeholder={''}
              id="level"
              value={classroom?.level}
              onChange={onChangeLevel}
              state={error === 'noLevel' ? 'error' : null}
            />
          </div>
        </td>
        <td>
          <FormInput
            leftIcon={null}
            placeholder={''}
            id="nameInput"
            value={classroom?.name}
            onChange={onChangeName}
            state={error === 'noName' ? 'error' : null}
          />
        </td>
        <td>&nbsp;</td>
        <td className="text-end">
          <button
            type="submit"
            className="btn btn-outline-dark"
            disabled={disabled}
          >
            {loading && (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
              </>
            )}
            {classroom.classroom_key ? t('ok') : t('addClassroom')}
          </button>
          {classroom.classroom_key ? (
            <>
              &nbsp;
              <button
                type="button"
                className="btn btn-outline-dark"
                onClick={onClickCancel}
              >
                {t('cancel')}
              </button>
            </>
          ) : (
            <></>
          )}
        </td>
      </tr>
    </>
  );
};

export default StudentsClassroomsLineForm;
