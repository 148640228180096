import { useState, useEffect } from 'react';
import { PdfLayout } from 'components/layout';
import { getSpaces, getSpace } from 'api';
import CguList from './CguList';
import { SpaceType } from 'models';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

const CguPage = () => {
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const [termsOfUseUri, setTermsOfUseUri] = useState('');
  const [allSpaces, setAllSpaces] = useState<SpaceType[]>([]);

  useEffect(() => {
    if (sessionSpace?.space_key) {
      if (sessionSpace.space_key !== 'default') {
        getSpace({ space_key: sessionSpace.space_key }).then((data) => {
          setTermsOfUseUri(data.terms_of_use_uri);
        });
      } else {
        getSpaces().then((data: SpaceType[]) => {
          setAllSpaces(data.filter((sp) => sp.space_key !== 'maskott'));
        });
      }
    }
  }, [sessionSpace?.space_key]);

  return (
    <>
      {termsOfUseUri ? (
        <PdfLayout pdfFileUrl={termsOfUseUri} />
      ) : (
        allSpaces.length !== 0 && <CguList allSpaces={allSpaces} />
      )}
    </>
  );
};

export default CguPage;
