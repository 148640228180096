import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  body, html, #root {
    height: 100%;
    margin: 0;
  }
  body {
    font: 400 17px / 1.2 Geomanist;
  }
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
  }
  .Toastify__toast {
    white-space: pre-line;
  }
  h1 {
    font-size: 24px;
    font-weight: bold;
    margin: 0 0 1em 0;
    padding: 0 0 8px 0;
    border-bottom: solid lightgrey 2px;
  }
  h2 {
    font-size: 18px;
    font-weight: bold;
    margin: 3em 0 1em 0;
    border-bottom: solid lightgrey 2px;
  }
  .bold {
    font-weight: bold;
  }
  a {
    color: rgba(${(props) => props.theme.colors.primary});
    &:hover {
        color: rgba(${(props) => props.theme.colors.primary});
    }
  }
  .text-pre {
    white-space: pre;
  }

`;
