import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Wayf } from 'components/atoms';
import { PublicLayout } from 'components/layout';
import { WayfPageStyled } from './WayfPage.styles';

const WayfPage = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.wayfPage',
  });

  const onStructureSelect = useCallback(() => {
    // nothing
  }, []);

  return (
    <PublicLayout
      backButtonText={t('return')}
      title={t('yourStructure')}
      breadcrumb={[
        {
          name: t('yourStructure'),
        },
      ]}
    >
      <WayfPageStyled>
        <div className="wayf_container">
          <div className="wayf_inner">
            {/* <p className="instruction">{tWayfPage('instruction')}</p> */}
            <Wayf
              onStructureSelect={onStructureSelect}
              domain=""
              showBtn={true}
              demo={false}
            />
          </div>
        </div>
      </WayfPageStyled>
    </PublicLayout>
  );
};

export default WayfPage;
