import { ReactNode } from 'react';
import { PageWrapperStyled } from './PublicLayout.styles';
import { Footer } from 'components/parts';
import { TopBarPublic } from 'components/parts';
import { useTheme } from 'styled-components';
import { Breadcrumb, BreadcrumbItem } from 'components/atoms';
import { routes } from 'router/routes';

type AuthPagePropsType = {
  children?: ReactNode;
  title?: string;
  backButtonText?: string;
  breadcrumb?: BreadcrumbItem[];
};

const PublicLayout = ({ children, title, breadcrumb }: AuthPagePropsType) => {
  const theme = useTheme();

  return (
    <PageWrapperStyled>
      <TopBarPublic />
      <div className="container">
        {breadcrumb ? (
          <Breadcrumb items={breadcrumb} homePath={routes.login.path} />
        ) : (
          <></>
        )}
        {title && <h1>{title}</h1>}
        <div className="d-flex justify-content-between gap-5">
          {
            <>
              {theme.key === 'loading' ? (
                <></>
              ) : (
                <div className="col-5 illustration">
                  <div className="image">&nbsp;</div>
                </div>
              )}
              <div className="col">{children}</div>
            </>
          }
        </div>
      </div>
      <Footer />
    </PageWrapperStyled>
  );
};

export default PublicLayout;
