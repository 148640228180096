import { useEffect, useCallback, FormEvent } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useRecoilState, useSetRecoilState } from 'recoil';
import ConfirmDemoAccountView from 'pages/private/ConfirmDemoAccount/ConfirmDemoAccount';
import { doLogin } from 'api';
import { routes } from 'router/routes';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState, AuthState, ProfileState } from 'recoilTools';
import { useSpacePath } from 'hooks';

interface LocationState {
  userName: string;
  password: string;
}

const ConfirmDemoAccount = () => {
  /* -- Const ------------------------------------------------------------- */

  const navigate = useNavigate();
  const spacePath = useSpacePath();

  /* -- States ------------------------------------------------------------- */

  const sessionSpace = useRecoilValue(SessionSpaceState);
  const [authState, setAuthState] = useRecoilState(AuthState);
  const setProfile = useSetRecoilState(ProfileState);
  const state = useLocation().state as LocationState;

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const doLoginMutation = useMutation('doLogin', doLogin, {
    retry: false,
  });

  /* -- Callbacks ------------------------------------------------------------- */

  const loginRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const space_key: string | null =
        sessionSpace && sessionSpace.space_key ? sessionSpace.space_key : null;

      doLoginMutation.mutate({
        username: state.userName,
        password: state.password,
        continue: null,
        space_key,
      });
    },
    [doLoginMutation, sessionSpace, state.userName, state.password],
  );

  /* -- Effects ------------------------------------------------------------- */

  // set AuthState if login success
  useEffect(() => {
    if (doLoginMutation.isSuccess) {
      if (doLoginMutation.data.is_success) {
        setAuthState(true);
        setProfile({});
      } else {
        setAuthState(null);
        setProfile({});
      }
    }
  }, [doLoginMutation, navigate, setAuthState, setProfile]);

  // redirect if isAuthenticated
  useEffect(() => {
    if (authState) {
      navigate(`/${routes.home.path}`);
    }
  }, [navigate, authState]);

  useEffect(() => {
    if (!state || !state.userName || !state.password) {
      navigate(`/${spacePath}${routes.login.path}`);
    }
  }, [navigate, state, spacePath]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <ConfirmDemoAccountView
      userName={state?.userName}
      isLoading={doLoginMutation.isLoading}
      onSubmit={loginRequest}
    />
  );
};

export default ConfirmDemoAccount;
