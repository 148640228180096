import { SpaceType } from 'models';
import learningConnectLogo from '../assets/images/learning-connect.svg';

export const DEFAULT_SESSION_SPACE: SpaceType = {
  space_key: 'default',
  theme_key: 'maskott',
  name: 'Learning Connect',
  description: '',
  terms_of_use_uri: '',
  data_protection_uri:
    'https://s3.maskott.com/learning-connect/pdfs/politiques-confidentialite/Politique-de-protection-des-donnees-personnelles-LearningConnect.pdf',
  legal_notices_uri:
    'https://s3.maskott.com/learning-connect/pdfs/mentions-legales/Mentions-Legales-LearningConnect.pdf',
  open_to_user_demo: false,
  logo_uri: learningConnectLogo,
  login_text: '',
};
