import { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { routesKeys, routes } from './routes';
import { useCustomMatomo } from 'hooks';

const renderRoutes = (keyPrefix?: string) =>
  routesKeys.map((routeKey) => (
    <Route key={keyPrefix + routeKey} {...routes[routeKey]} />
  ));

const MainRouter = () => {
  const { trackMatomoPageView } = useCustomMatomo();
  const location = useLocation();

  const [_, setCurrentPath] = useState<string>();

  useEffect(() => {
    setCurrentPath((oldPath) => {
      if (location.pathname && location.pathname !== oldPath) {
        trackMatomoPageView({
          documentTitle: location.pathname,
        });
      }
      return location.pathname;
    });
  }, [trackMatomoPageView, location]);

  return (
    <Routes>
      <Route path="spaces/:spaceKey">{renderRoutes('spaces')}</Route>
      {renderRoutes('noSpaces')}
    </Routes>
  );
};

export default MainRouter;
