import axios from 'axios';
import { ENDPOINTS } from 'appConstants';
import { ProfileType, Subscription } from 'models';

// LOGIN (public) ------------

type LoginPayloadType = {
  username: string;
  password: string;
  continue: string | null;
  space_key?: string | null;
};

type LoginResponseType = {
  is_success: boolean;
  error_code?: string;
  continue: string | null;
};

export const doLogin = (
  requestContent: LoginPayloadType,
): Promise<LoginResponseType> => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
  };
  return axios
    .post<LoginResponseType>(ENDPOINTS.login, requestContent, requestOptions)
    .then((response) => response?.data);
};

// LOGIN STUDENT (public) ------------

type LoginStudentPayloadType = {
  classroom_key: string;
  login_name: string;
  password: string;
  continue: string | null;
  space_key?: string | null;
};

export const doLoginStudent = (
  requestContent: LoginStudentPayloadType,
): Promise<LoginResponseType> => {
  const requestOptions = {
    headers: { 'Content-Type': 'application/json' },
  };
  return axios
    .post<LoginResponseType>(
      ENDPOINTS.loginStudent,
      requestContent,
      requestOptions,
    )
    .then((response) => response?.data);
};

// CREATE WAYF ACCOUNT (public) -------------

type CreateWayfAccountPayloadType = {
  uai_code: string;
  username: string;
  email: string;
  last_name: string;
  first_name: string;
  password: string;
  space_key: string | null;
  subscriptions: Subscription[];
};

type CreateAccountResponseType = {
  username?: string;
  activation_required?: boolean;
  error_message?: string;
};

export const createWayfAccount = (
  payload: CreateWayfAccountPayloadType,
): Promise<CreateAccountResponseType> =>
  axios
    .post<CreateAccountResponseType>(ENDPOINTS.createWayfAccount, payload)
    .then((response) => {
      let ret: CreateAccountResponseType = response?.data;
      if (!ret) {
        ret = {
          username: payload.username,
          activation_required: true,
        };
      }
      return ret;
    });

// ACCOUNT VALIDATION (public) -------------

type SendActivationEmailPayloadType = {
  username: string;
};

type SendActivationEmailResponseType = {
  is_success?: boolean;
  error_code?: string;
};

export const askActivationEmail = (
  payload: SendActivationEmailPayloadType,
): Promise<SendActivationEmailResponseType> =>
  axios
    .post<SendActivationEmailResponseType>(
      ENDPOINTS.askActivationEmail,
      payload,
    )
    .then((response) => response?.data);

// -------------

type ActivateAccountPayloadType = {
  username: string;
  activation_code: string;
};

type ActivateAccountResponseType = {
  is_success?: boolean;
  error_code?: string;
};

export const activateAccount = (
  payload: ActivateAccountPayloadType,
): Promise<ActivateAccountResponseType> =>
  axios
    .post<ActivateAccountResponseType>(ENDPOINTS.activateAccount, payload)
    .then((response) => response?.data);

// CREATE DEMO ACCOUNT (public) -------------

type CreateDemoAccountPayloadType = {
  uai_code: string;
  username: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  password: string;
  space_key: string | null;
  accepts_terms_of_use: boolean;
  accepts_demo_expiry_email: boolean;
  subscriptions: Subscription[];
};

type CreateDemoAccountResponseType = {
  username?: string;
  activation_required?: boolean;
};

export const createDemoAccount = (
  payload: CreateDemoAccountPayloadType,
): Promise<CreateDemoAccountResponseType> => {
  return axios
    .post<CreateDemoAccountResponseType>(ENDPOINTS.createDemoAccount, payload)
    .then((response) => response.data);
};

// SEND RESET PASSWORD LINK (public) -------------

type SendForgotPasswordLinkPayloadType = {
  username: string;
  space_key?: string;
};
export const sendForgotPasswordLink = (
  username: string,
  space_key: string | null,
): Promise<SendForgotPasswordLinkPayloadType> =>
  axios.post(ENDPOINTS.passwordRecover, { username, space_key });

// RESET PASSWORD (public) -------------

type ResetPasswordPayloadType = {
  token: string | null;
  new_password: string;
};

export const resetPassword = (payload: ResetPasswordPayloadType) =>
  axios.post(ENDPOINTS.passwordReset, payload);

// SEND USERNAMES (public) -------------

export const sendForgotUsername = (email: string, space_key: string | null) =>
  axios.post(ENDPOINTS.usernameRecover, { email, space_key });

// CHECK IF LOGOUT IS PENDING (public) -------------

type CheckSessionType = {
  is_authenticated: boolean;
  has_pending_logout: boolean;
};

export const checkSession = (): Promise<CheckSessionType> =>
  axios
    .get<CheckSessionType>(ENDPOINTS.checkSession)
    .then((response) => response?.data);

// LOGOUT (public) -------------

export const doLogout = (): Promise<ProfileType> =>
  axios.post<ProfileType>(ENDPOINTS.logout).then((response) => response?.data);
