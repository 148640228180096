import styled from 'styled-components';
import { pxToRem, mb } from '@maskott/tactileo.designsystem';

export const ConfirmDemoAccountPage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${pxToRem(24)};
  width: 100%;
  height: 100%;
`;

export const ConfirmDemoAccountForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(24)};
  width: 100%;
  ${mb(3)};
`;

export const FieldLabel = styled.label`
  font-weight: 400;
  font-size: ${pxToRem(17)};
  line-height: ${pxToRem(20)};
  color: black;
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
  width: 100%;
`;

export const ExplanatoryText = styled.p``;
