import AuthorizedRoute from 'router/AuthorizedRoute';

import {
  CreateAccount,
  AccountActivation,
  AccountActivated,
  Login,
  LoginStudent,
  LoginClass,
  LogoutPropagate,
  AfterLogout,
  ForgotPassword,
  ForgotUsername,
  ChangeForgottenPassword,
  CreateDemoAccount,
  WayfPage,
  WayfPageDemo,
  AfterDelete,
  Page404,
} from 'pages/public';

import {
  Home,
  DeleteAccount,
  CguPage,
  LegalNotices,
  DataProtection,
  ConfirmDemoAccount,
  NewsletterDomino,
  NewsletterEdugo,
  ClassroomPrint,
  Disambiguate,
  StudentsHome,
  StudentsClassrooms,
  StudentsAccounts,
  StudentsPrint,
  Classrooms,
  Structures,
  PersonalData,
  Themes,
} from 'pages/private';

export const privateRoutes = {
  ssoDisambiguate: {
    path: 'sso/disambiguate',
    element: <AuthorizedRoute component={Disambiguate} />,
  },
  deleteAccount: {
    path: 'delete-account',
    element: <AuthorizedRoute component={DeleteAccount} />,
  },
  home: {
    path: 'home',
    element: <AuthorizedRoute component={Home} />,
  },
  classroomPrint: {
    path: 'print/:classroomKey',
    params: {
      classroomKey: ':classroomKey',
    },
    element: <AuthorizedRoute component={ClassroomPrint} />,
  },
  classrooms: {
    path: 'classrooms',
    element: <AuthorizedRoute component={Classrooms} />,
  },
  structures: {
    path: 'structures',
    element: <AuthorizedRoute component={Structures} />,
  },
  personalData: {
    path: 'personalData',
    element: <AuthorizedRoute component={PersonalData} />,
  },
  studentsHome: {
    path: 'students',
    element: <AuthorizedRoute component={StudentsHome} />,
  },
  studentsClassrooms: {
    path: 'students/classrooms',
    element: <AuthorizedRoute component={StudentsClassrooms} />,
  },
  studentsAccounts: {
    path: 'students/accounts/:classroomId',
    params: {
      classroomId: ':classroomId',
    },
    element: <AuthorizedRoute component={StudentsAccounts} />,
  },
  studentsClassroomPrint: {
    path: 'students/print/:structureId/:classroomId',
    params: {
      structureId: ':structureId',
      classroomId: ':classroomId',
    },
    element: <AuthorizedRoute component={StudentsPrint} />,
  },
  studentAccountPrint: {
    path: 'students/print/:structureId/:classroomId/:accountId',
    params: {
      structureId: ':structureId',
      classroomId: ':classroomId',
      accountId: ':accountId',
    },
    element: <AuthorizedRoute component={StudentsPrint} />,
  },
  themes: {
    path: 'themes',
    element: <AuthorizedRoute component={Themes} />,
  },
};

export const publicRoutes = {
  page404: {
    path: '404',
    element: <Page404 />,
  },
  loginIndex: {
    path: '',
    element: <Login />,
  },
  login: {
    path: 'login',
    element: <Login />,
  },
  loginStudent: {
    path: 'eleve',
    element: <LoginStudent />,
  },
  loginClassroom: {
    path: 'login/:classroomKey',
    params: {
      classroomKey: ':classroomKey',
    },
    element: <LoginClass />,
  },
  logoutPropagate: {
    path: 'logout',
    element: <LogoutPropagate />,
  },
  afterLogout: {
    path: 'after-logout',
    element: <AfterLogout />,
  },
  afterDelete: {
    path: 'after-delete',
    element: <AfterDelete />,
  },
  wayf: {
    path: 'wayf',
    element: <WayfPage />,
  },
  wayfDemo: {
    path: 'demo-account/wayf',
    element: <WayfPageDemo />,
  },
  createAccount: {
    path: 'create-account',
    element: <CreateAccount />,
  },
  accountActivation: {
    path: 'account-activation',
    element: <AccountActivation />,
  },
  accountActivated: {
    path: 'account-activated',
    element: <AccountActivated />,
  },
  createDemoAccount: {
    path: 'demo-account/create',
    element: <CreateDemoAccount />,
  },
  confirmDemoAccount: {
    path: 'demo-account/confirmation',
    element: <ConfirmDemoAccount />,
  },
  cguPage: {
    path: 'cgu',
    element: <CguPage />,
  },
  legalNotices: {
    path: 'mentions-legales',
    element: <LegalNotices />,
  },
  dataProtection: {
    path: 'politique-confidentialit',
    element: <DataProtection />,
  },
  forgotUsername: {
    path: 'forgot-username',
    element: <ForgotUsername />,
  },
  forgotPassword: {
    path: 'forgot-password',
    element: <ForgotPassword />,
  },
  changeForgottenPassword: {
    path: 'reset-password',
    element: <ChangeForgottenPassword />,
  },
  newsletterDomino: {
    path: 'newsletter/domino',
    element: <NewsletterDomino />,
  },
  newsletterEdugo: {
    path: 'newsletter/edugo',
    element: <NewsletterEdugo />,
  },
};

export const routes = { ...publicRoutes, ...privateRoutes };

export const publicRoutesKeys = Object.keys(
  publicRoutes,
) as (keyof typeof publicRoutes)[];

export const privateRoutesKeys = Object.keys(
  privateRoutes,
) as (keyof typeof privateRoutes)[];

export const routesKeys = Object.keys(routes) as (keyof typeof routes)[];
