import { SecurityIndicatorVariants } from 'appConstants';
import { useCallback, useEffect, useState } from 'react';
import { useMedia } from 'hooks';
import {
  ContainerStyle,
  EyeCloseIconStyled,
  EyeIconStyled,
  EyeLeftIconStyled,
  FormInputStyle,
  LockIconStyled,
  SecurityIndicatorItemStyle,
  SecurityIndicatorsContainerStyle,
} from './PasswordInput.styles';

type FormPasswordInputProps = {
  placeholder: string;
  value: string;
  onChange: (value: any) => void;
  id: string;
  qualityIndicator?: boolean;
  onQualityChanged?: (quality?: SecurityIndicatorVariants) => any;
  state: string | null;
  onBlur?: () => void;
};

const smallCaseLetterRegexp = /[a-z]/;
const capitalLetterRegexp = /[A-Z]/;
const numberRegexp = /[0-9]/;
const specialCharacterRegexp = /[^A-Za-z0-9 ]/;
const spaceRegexp = /\s/;

const checkPasswordQuality = (password: string) => {
  const qualityCases = [
    smallCaseLetterRegexp.test(password),
    capitalLetterRegexp.test(password),
    numberRegexp.test(password),
    specialCharacterRegexp.test(password),
    !spaceRegexp.test(password),
    password.length >= 8,
  ].filter(Boolean);

  if (qualityCases.length === 6) {
    return SecurityIndicatorVariants.HARD;
  }
  if (qualityCases.length > 2) {
    return SecurityIndicatorVariants.MEDIUM;
  }
  return SecurityIndicatorVariants.EASY;
};

const securityIndicators = ['easy', 'medium', 'hard'];

const FormPasswordInput = ({
  placeholder,
  value,
  onChange,
  qualityIndicator,
  onQualityChanged,
  id,
  state,
  onBlur,
}: FormPasswordInputProps) => {
  const { isLargerThan767 } = useMedia();
  const [isPasswordType, setIsPasswordType] = useState(true);
  const [qualityIndicators, setQualityIndicators] = useState<any>({
    hard: '',
    medium: '',
    easy: '',
  });
  const quality =
    qualityIndicator && value ? checkPasswordQuality(value) : undefined;
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value);
    },
    [onChange],
  );
  useEffect(() => {
    if (onQualityChanged) onQualityChanged(quality);
  }, [quality, onQualityChanged]);

  useEffect(() => {
    switch (quality) {
      case 'easy':
        setQualityIndicators({ hard: '', medium: '', easy: 'easy' });
        break;
      case 'medium':
        setQualityIndicators({ hard: '', medium: 'medium', easy: 'easy' });
        break;
      case 'hard':
        setQualityIndicators({ hard: 'hard', medium: 'medium', easy: 'easy' });
        break;
      default:
        setQualityIndicators({
          hard: '',
          medium: '',
          easy: '',
        });
    }
  }, [quality]);

  return (
    <ContainerStyle>
      {isLargerThan767 && <LockIconStyled aria-hidden="true" color="black" />}

      <FormInputStyle
        onBlur={onBlur}
        id={id}
        placeholder={placeholder}
        type={isPasswordType ? 'password' : 'text'}
        value={value}
        onChange={handleChange}
        className={`${state}State ${quality}`}
      />
      {qualityIndicator && (
        <SecurityIndicatorsContainerStyle>
          {securityIndicators.map((indicator, index) => (
            <SecurityIndicatorItemStyle
              key={indicator}
              className={`${qualityIndicators[securityIndicators[index]]}`}
            />
          ))}
        </SecurityIndicatorsContainerStyle>
      )}
      {isPasswordType ? (
        <>
          {isLargerThan767 ? (
            <EyeCloseIconStyled
              aria-hidden="true"
              color="black"
              onClick={() => setIsPasswordType(!isPasswordType)}
            />
          ) : (
            <LockIconStyled
              aria-hidden="true"
              color="black"
              onClick={() => setIsPasswordType(!isPasswordType)}
            />
          )}
        </>
      ) : (
        <>
          {isLargerThan767 ? (
            <EyeIconStyled
              aria-hidden="true"
              color="black"
              onClick={() => setIsPasswordType(!isPasswordType)}
            />
          ) : (
            <EyeLeftIconStyled
              aria-hidden="true"
              color="black"
              onClick={() => setIsPasswordType(!isPasswordType)}
            />
          )}
        </>
      )}
    </ContainerStyle>
  );
};

export default FormPasswordInput;
