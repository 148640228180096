import { error, neutralLight } from 'assets/colors';
import { RoundedCornerButton } from 'components/atoms';
import styled from 'styled-components';

export const LoginClassStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
`;

export const FieldStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  @media only screen and (max-width: 767px) {
    input {
      border: 1px solid rgb(${(props) => props.theme.colors.primary});
      box-shadow: 0px 1px 0px rgb(${(props) => props.theme.colors.primary});
    }
    input:focus {
      border: 2px solid rgb(${(props) => props.theme.colors.primary});
      background-color: ${neutralLight};
    }
    .errorState {
      border: 2px solid ${error}!important;
      box-shadow: 0px 1px 0px ${error};
      background-color: #fce8e8 !important;
    }
  }
`;

export const FieldErrorStyled = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${error};
`;

export const FieldsWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 8px;
`;

export const LoginButtonStyled = styled(RoundedCornerButton)`
  margin-top: 24px;
`;

export const LoginFormStyled = styled.form`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
