import { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { StructuresBarContainer } from './StructuresBar.styles';
import { BaseSelect } from 'components/atoms';
import { useRecoilValue, useRecoilState } from 'recoil';
import { StructuresState, StructureState } from 'recoilTools';
import { useCustomMatomo } from 'hooks';
import {
  DropDownSelectOption,
  ParamsIcon,
} from '@maskott/tactileo.designsystem';

const StructuresBar = ({ show }: { show: boolean }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.core.structureBar',
  });

  const structures = useRecoilValue(StructuresState);
  const { trackMatomoEvent } = useCustomMatomo();

  const [currentStructure, setCurrentStructure] =
    useRecoilState(StructureState);

  const onStructureChange = useCallback(
    (changes: DropDownSelectOption) => {
      const structure = structures?.find(
        (ets) => ets.structure_identifier === changes?.value,
      );
      if (
        structure &&
        structure?.structure_id !== currentStructure?.structure_id
      ) {
        setCurrentStructure(structure);
        trackMatomoEvent({
          category: 'Usage',
          action: 'Access structure',
          name: `${structure.structure_name} (${structure.structure_identifier})`, // optional
          value: structure.structure_id, // optional, numerical value
        });
      }
      return false;
    },
    [
      trackMatomoEvent,
      structures,
      setCurrentStructure,
      currentStructure?.structure_id,
    ],
  );

  useEffect(() => {
    if (!currentStructure && structures?.length && structures?.length > 0) {
      setCurrentStructure(structures[0]);
    }
  }, [setCurrentStructure, structures, currentStructure]);

  const options = structures?.map((structure) => ({
    id: structure.structure_identifier,
    label: `${structure.structure_name} (${structure.structure_identifier})`,
    value: structure.structure_identifier,
    disabled: false,
  }));

  return (
    <StructuresBarContainer>
      {show ? (
        <>
          {currentStructure?.structure_identifier && options?.length ? (
            <>
              <BaseSelect
                selectedOptionId={currentStructure?.structure_identifier}
                onChange={onStructureChange}
                options={options}
              />
              <div className="menuButton">
                <ParamsIcon color={'#000'} />
                <p>{t('menuText')}</p>
              </div>
            </>
          ) : (
            <div className="holder">&nbsp;</div>
          )}
        </>
      ) : (
        <></>
      )}
    </StructuresBarContainer>
  );
};

export default StructuresBar;
