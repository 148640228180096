import axios from 'axios';
import axiosAuthorized from './axiosAuthorized';
import { ENDPOINTS } from 'appConstants';
import { PublicClassroomType, ClassroomType } from 'models';

// GET CLASSROOM DETAILS (public)
export const getPublicClassroom = (
  classroom_key: string,
): Promise<PublicClassroomType> => {
  return axios
    .get<PublicClassroomType>(`${ENDPOINTS.publicClassroom}/${classroom_key}`)
    .then((response) => response?.data);
};

// ADD CLASSROOM (private) -------------

type AddClassroomPayloadType = {
  structure_id: number;
  display_name: string;
  password: string;
};
export const addClassroom = (payload: AddClassroomPayloadType) =>
  axiosAuthorized.post(ENDPOINTS.classrooms, payload);

// GET CLASSROOMS (private) -------------

export const getClassrooms = (): Promise<ClassroomType[]> => {
  return axiosAuthorized
    .get<ClassroomType[]>(ENDPOINTS.classrooms)
    .then((response) => response?.data);
};

// DELETE CLASSROOMS (private) -------------

type DeleteClassroomPayloadType = {
  classroom_key: string;
};
export const deleteClassroom = (payload: DeleteClassroomPayloadType) =>
  axiosAuthorized.delete(`${ENDPOINTS.classrooms}/${payload.classroom_key}`);
