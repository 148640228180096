import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const A = styled.a`
  color: inherit;
`;

const LinkText = (
  props: PropsWithChildren<{ to: string; title: string; target?: string }>,
) => {
  return (
    <A
      href={props.to}
      target={props.target || '_self'}
      title={props.title || ''}
      rel="noopener noreferrer"
    >
      {props.children}
    </A>
  );
};

export default LinkText;
