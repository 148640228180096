import { Button } from '@maskott/tactileo.designsystem';
import { neutralMedium } from 'assets/colors';
import styled from 'styled-components';

const RoundedCornerButton = styled(Button)`
  border-radius: 2.5rem;
  span {
    font-weight: 700;
  }
  @media only screen and (max-width: 767px) {
    height: 40px;
  }
`;

export default RoundedCornerButton;
