import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'router/routes';
import { useTranslation } from 'react-i18next';

import { PrivateLayout } from 'components/layout';
import { PasswordInput, Spinner } from 'components/atoms';
import {
  ConfirmDeleteAccountPageStyled,
  TitleStyled,
  BackButtonStyled,
  FieldStyled,
  FieldErrorStyled,
  LabelStyled,
  SmallTextStyled,
  BackImageStyled,
  ButtonsBlockStyled,
  CancelButtonStyled,
  ConfirmButtonStyled,
  ModalTextStyled,
  ModalStyled,
  DeleteAccountPageStyled,
} from './DeleteAccount.styles';
import backIcon from 'assets/images/backIcon.svg';

import { PublicLayout } from 'components/layout';

import { INCORRECT_PASSWORD_ERROR } from 'appConstants';
import { deleteAccount, doLogout } from 'api';
import { LOGOUT_IFRAME } from 'appConstants';
import { useError, useSpacePath } from 'hooks';
import { useSetRecoilState } from 'recoil';
import { AuthState, ProfileState } from 'recoilTools';

const DeleteAccount = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.deleteAccount',
  });
  const navigate = useNavigate();

  const setAuthState = useSetRecoilState(AuthState);
  const setProfile = useSetRecoilState(ProfileState);

  const spacePath = useSpacePath();
  const [password, setPassword] = useState('');
  const [isShowModal, setIsShowModal] = useState(false); // ask for confirmation

  const [isDeleting, setIsDeleting] = useState(false); // query account deletion
  const [error, setError] = useState('');
  const isPasswordError = error === INCORRECT_PASSWORD_ERROR;

  const [isLoggingOut, setIsLoggingOut] = useState(false); // logging out
  const LOADING_DELAY = 5000;
  const { getMessage } = useError();

  const showModal = () => setIsShowModal(true);
  const hideModal = () => setIsShowModal(false);

  const onSubmitDeletion = () => {
    deleteAccount({ password }).then(
      () => {
        setIsDeleting(true);
        setIsLoggingOut(true);
        const timeout = setTimeout(() => {
          doLogout().then(() => {
            navigate(`/${spacePath}${routes.afterDelete.path}`);
            setAuthState(null);
            setProfile({});
            clearTimeout(timeout);
          });
        }, LOADING_DELAY);
      },
      ({ response }) =>
        onDeleteError(getMessage(response?.data, 'user.deleteAccount')),
    );
  };
  const onDeleteError = (message?: string) => {
    setIsDeleting(false);
    if (message) {
      setError(message);
      setIsShowModal(false);
    }
  };

  return !isDeleting ? (
    <PrivateLayout>
      <ConfirmDeleteAccountPageStyled>
        <BackButtonStyled
          id="ButtonBack"
          onClick={() => navigate(`/${routes.personalData.path}`)}
        >
          <BackImageStyled src={backIcon} />
          {t('return')}
        </BackButtonStyled>
        <TitleStyled>{t('accountDeletion')}</TitleStyled>
        <SmallTextStyled>
          {t('youHaveRequestedToDeleteAccount')}
        </SmallTextStyled>
        <FieldStyled>
          <LabelStyled>{t('password')}</LabelStyled>
          <PasswordInput
            leftIcon="padlock"
            placeholder={t('password')}
            value={password}
            onChange={setPassword}
            state={null}
          />
          {error && (
            <FieldErrorStyled>
              {isPasswordError ? t('incorrectPassword') : error}
            </FieldErrorStyled>
          )}
        </FieldStyled>
        <ButtonsBlockStyled>
          <CancelButtonStyled
            disabled={isDeleting}
            onClick={() => navigate(`/${routes.personalData.path}`)}
            state="secondary"
            label={t('cancel')}
          />
          <ConfirmButtonStyled
            disabled={isDeleting || !password}
            onClick={showModal}
            label={t('confirmBtn')}
            Icon={isDeleting ? Spinner : undefined}
            imgSide={isDeleting ? 'right' : undefined}
          />
        </ButtonsBlockStyled>
      </ConfirmDeleteAccountPageStyled>
      <ModalStyled
        isAriaHidden={`${!isShowModal}`}
        showHeader
        size="L"
        isOpen={isShowModal}
        title={t('accountDeletion')}
        ActionLabel={t('confirm')}
        CancelLabel={t('cancel')}
        ActionFunc={onSubmitDeletion}
        CancelFunc={hideModal}
        closeModal={hideModal}
      >
        <ModalTextStyled>{t('confirmTheDeletion')}</ModalTextStyled>
      </ModalStyled>
    </PrivateLayout>
  ) : (
    <PublicLayout title={t('accountDeletion')}>
      <DeleteAccountPageStyled>
        <br />
        <br />
        <br />
        <br />
        <Spinner />
      </DeleteAccountPageStyled>
      {isLoggingOut && (
        <iframe
          src={LOGOUT_IFRAME}
          style={{ position: 'absolute', width: 0, height: 0, border: 0 }}
        />
      )}
    </PublicLayout>
  );
};

export default DeleteAccount;
