import { TFunction } from 'react-i18next';

import { PublicLayout } from 'components/layout';
import {
  FieldStyled,
  LoginClassStyled,
  LoginButtonStyled,
  FieldsWrapperStyled,
  LoginFormStyled,
  FieldErrorStyled,
} from './LoginClass.styles';
import { Spinner, AnimalsPassword } from 'components/atoms';

type LoginClassProps = {
  t: TFunction<'translation'>;
  classroomName: string;
  setPasswordValue: (value: string) => any;
  loginDisabled: boolean;
  loginRequest: (e: React.FormEvent<HTMLFormElement>) => any;
  credentialsError: string | undefined;
  isLoading: boolean;
};

const LoginClass = ({
  t,
  classroomName,
  setPasswordValue,
  loginDisabled,
  loginRequest,
  credentialsError,
  isLoading,
}: LoginClassProps) => {
  return (
    <PublicLayout title={t('login', { class_name: classroomName })}>
      <LoginClassStyled>
        <LoginFormStyled onSubmit={loginRequest}>
          <FieldsWrapperStyled>
            <FieldStyled>
              <AnimalsPassword
                changeCallback={(value) => {
                  setPasswordValue(value);
                }}
              />
              {credentialsError && (
                <FieldErrorStyled>{t(credentialsError)}</FieldErrorStyled>
              )}
            </FieldStyled>
          </FieldsWrapperStyled>
          <LoginButtonStyled
            type="submit"
            disabled={loginDisabled}
            label={t('logIn')}
            Icon={isLoading ? Spinner : undefined}
            imgSide={isLoading ? 'right' : loginDisabled ? 'left' : undefined}
          />
        </LoginFormStyled>
      </LoginClassStyled>
    </PublicLayout>
  );
};

export default LoginClass;
