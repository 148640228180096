import { useEffect, useState } from 'react';

/************************************/
/* HOOK : Screen width */

export const useMedia = () => {
  const [isLargerThan767, setIsLargerThan767] = useState<boolean>(false);

  const resizeHandler = () => {
    setIsLargerThan767(
      Math.min(window.screen.width, window.screen.height) > 767,
    );
  };

  useEffect(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();

    return () => {
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return {
    isLargerThan767,
  };
};
