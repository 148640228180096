import { useState, useEffect, useCallback, FormEvent } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { PrivateLayout } from 'components/layout';
import { StructuresStyled } from './Structures.styles';
import { Breadcrumb } from 'components/atoms';
import { DefaultResponseErrorType, StructureType, WayfStructure } from 'models';
import { ProfileState, StructureState, StructuresState } from 'recoilTools';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { useQuery, useMutation } from 'react-query';
import {
  leaveStructure,
  getStructures,
  becomeTeacher,
  getWayfList,
  getWayfStructure,
} from 'api';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { validateZipCode, getDomainFromEmail } from 'utils';
import { ErrorMsg } from 'components/atoms/ErrorMsg/ErrorMsg';
import { useError } from 'hooks';
import TableHolder from 'components/atoms/PlaceHolders/TableHolder';
import Holder from 'components/atoms/PlaceHolders/Holder';
import { routes } from 'router/routes';

/***********************************************************/
/***********************************************************/
/** Structures COMPONENT **/

const Structures = () => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.structures',
  });

  /* -- States ------------------------------------------------------------- */

  const [structuresSearch, setStructuresSearch] = useState<WayfStructure[]>([]);
  const [searchStatus, setSearchStatus] = useState<string>('');
  const [addStatus, setAddStatus] = useState<string>('');
  const structureState = useRecoilValue(StructureState);
  const setStructuresState = useSetRecoilState(StructuresState);
  const { getMessage } = useError();

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const structuresQuery = useQuery('getStructures', getStructures, {
    refetchOnWindowFocus: false,
    enabled: true,
    retry: false,
    cacheTime: 0,
    onSuccess: (data) => {
      setStructuresState(data);
    },
  });

  const deleteStructureMutation = useMutation(
    'leaveStructure',
    leaveStructure,
    {
      retry: false,
      onSuccess: () => {
        structuresQuery.refetch();
        toast(t('structureDeleted'), {
          type: 'success',
        });
      },
      onError: (error: DefaultResponseErrorType) => {
        toast(getMessage(error.response?.data, 'user.structures'), {
          type: 'error',
          autoClose: false,
          closeButton: true,
        });
      },
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onSubmitModification = useCallback((event: FormEvent) => {
    event.preventDefault();
    return false;
  }, []);

  const onSearchResults = useCallback((structureList: WayfStructure[]) => {
    setStructuresSearch(structureList);
  }, []);

  const onSearchStatus = useCallback((status: string) => {
    setSearchStatus(status);
  }, []);

  const onAddStatus = useCallback(
    (status: string) => {
      setAddStatus(status);
      if (status === 'success') {
        structuresQuery.refetch();
      }
    },
    [structuresQuery],
  );

  const onDelete = useCallback(
    (structure_id: number) => {
      deleteStructureMutation.mutate({
        structure_id,
      });
    },
    [deleteStructureMutation],
  );

  /* -- Effects ------------------------------------------------------------- */

  /* -- Return ------------------------------------------------------------- */

  const mutating: boolean = deleteStructureMutation.isLoading;

  return (
    <PrivateLayout>
      <div id="studentsHome-page">
        <StructuresStyled>
          <div className="container p-4">
            <Breadcrumb
              homePath={routes.home.path}
              items={[
                {
                  name: t('structuresTitle'),
                },
              ]}
            />
            <h1>{t('structuresTitle')}</h1>
            <div>
              <Trans t={t} i18nKey="structuresText" />
            </div>
            <h2>{t('structureList')}</h2>
            <form onSubmit={onSubmitModification}>
              <div className="table-responsive-md">
                <table className="table w-100">
                  {!!structuresQuery?.data?.length && (
                    <thead>
                      <tr>
                        <th scope="col">{t('structureName')}</th>
                        <th scope="col">{t('uai')}</th>
                        <th scope="col" className="text-end">
                          &nbsp;
                        </th>
                      </tr>
                    </thead>
                  )}
                  <tbody>
                    {!structuresQuery.isLoading &&
                      structuresQuery.data?.map((_structure) => (
                        <Line
                          key={_structure.structure_id}
                          structure={_structure}
                          onDelete={() => onDelete(_structure.structure_id)}
                          disabled={mutating}
                          isDeleting={
                            deleteStructureMutation.isLoading &&
                            _structure.structure_id ===
                              deleteStructureMutation.variables?.structure_id
                          }
                          isCurrent={
                            _structure.structure_id ===
                            structureState?.structure_id
                          }
                        />
                      ))}
                    {(!structuresQuery || structuresQuery.isLoading) && (
                      <TableHolder lines={3} cols={3} />
                    )}
                  </tbody>
                </table>
                {structuresQuery?.data?.length === 0 && (
                  <p>{t('noStructure')}</p>
                )}
              </div>
            </form>
            <h2>{t('addStructure')}</h2>
            {addStatus !== 'loading' ? (
              <>
                <SearchForm
                  disabled={mutating}
                  onChange={onSearchResults}
                  onStatus={onSearchStatus}
                />
                {searchStatus === 'success' && !!structuresSearch.length && (
                  <AddForm
                    disabled={mutating}
                    structuresList={structuresSearch}
                    onStatus={onAddStatus}
                  />
                )}
              </>
            ) : (
              <Holder nb={3} />
            )}
          </div>
        </StructuresStyled>
      </div>
    </PrivateLayout>
  );
};

/***********************************************************/
/***********************************************************/
/** Line COMPONENT **/

const Line = ({
  structure,
  onDelete,
  disabled,
  isDeleting,
  isCurrent,
}: {
  structure: StructureType;
  onDelete: (structure: StructureType) => void;
  disabled: boolean;
  isDeleting: boolean;
  isCurrent: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.structures',
  });

  /* -- Callbacks ------------------------------------------------------------- */

  const onDeleteClick = useCallback(() => {
    onDelete(structure);
  }, [onDelete, structure]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <tr>
      <td>{structure.structure_name}</td>
      <td>{structure.structure_identifier}</td>
      <td className="text-end">
        {isCurrent ? (
          <button
            type="button"
            className="btn btn-outline-dark"
            onClick={onDeleteClick}
            disabled={true}
          >
            {' '}
            {isDeleting && (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
              </>
            )}
            {t('current')}
          </button>
        ) : (
          <button
            type="button"
            className="btn btn-outline-dark"
            onClick={onDeleteClick}
            disabled={disabled}
          >
            {' '}
            {isDeleting && (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
              </>
            )}
            {t('delete')}
          </button>
        )}
      </td>
    </tr>
  );
};

/***********************************************************/
/***********************************************************/
/** SearchForm COMPONENT **/

const SearchForm = ({
  disabled,
  onChange,
  onStatus,
}: {
  disabled: boolean;
  onChange: (structuresList: WayfStructure[]) => void;
  onStatus: (status: string) => void;
}) => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.structures',
  });
  const limit: number = 1000;

  /* -- States ------------------------------------------------------------- */

  const [zipCodeOrUAI, setZipCodeOrUAI] = useState<string>('');
  const [structuresSearch, setStructuresSearch] = useState<WayfStructure[]>([]);
  const [page, setPage] = useState<number>(1);
  const profile = useRecoilValue(ProfileState);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const getWayfListMutation = useMutation('getWayfList', getWayfList, {
    retry: false,
  });

  const getWayfStructureMutation = useMutation(
    'getWayfStructure',
    getWayfStructure,
    {
      retry: false,
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onChangeSearch = useCallback((event: FormEvent<HTMLInputElement>) => {
    const _zipCodeOrUAI: string = (event.target as HTMLInputElement).value;
    setZipCodeOrUAI(_zipCodeOrUAI);
    // setStructuresSearch([]);
  }, []);

  const onSubmitSearch = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const target: HTMLFormElement = event.target as HTMLFormElement;
      const _zipCodeOrUAI: string = target.zipCodeOrUAI.value;

      getWayfStructureMutation.reset();
      getWayfListMutation.reset();
      if (_zipCodeOrUAI.length === 5 && validateZipCode(_zipCodeOrUAI)) {
        onStatus('loading');
        getWayfListMutation.mutate({
          zipCode: _zipCodeOrUAI,
          domain: getDomainFromEmail(profile?.email || ''),
          page,
          limit,
        });
      } else {
        onStatus('loading');
        getWayfStructureMutation.mutate({
          uai: _zipCodeOrUAI,
          domain: getDomainFromEmail(profile?.email || ''),
        });
      }
    },
    [
      getWayfListMutation,
      getWayfStructureMutation,
      onStatus,
      profile?.email,
      page,
    ],
  );

  /* -- Effects ------------------------------------------------------------- */

  useEffect(() => {
    if (getWayfListMutation && getWayfListMutation.data) {
      setStructuresSearch(getWayfListMutation.data.structures);
      getWayfListMutation.reset();
    }
  }, [getWayfListMutation, structuresSearch.length]);

  useEffect(() => {
    if (getWayfStructureMutation && getWayfStructureMutation.data) {
      setStructuresSearch([getWayfStructureMutation.data]);
      getWayfStructureMutation.reset();
    }
  }, [getWayfStructureMutation, structuresSearch.length]);

  useEffect(() => {
    onStatus('success');
    onChange(structuresSearch);
  }, [onChange, structuresSearch, onStatus]);

  /* -- Return ------------------------------------------------------------- */

  const isLoading: boolean =
    getWayfStructureMutation.isLoading || getWayfListMutation.isLoading;
  const error = getWayfStructureMutation.error || getWayfListMutation.error;

  return (
    <>
      <form onSubmit={onSubmitSearch}>
        <div className="row align-items-end">
          <div className="form-group mb-3 col">
            <label htmlFor="zipCodeOrUAI">{t('zipCodeOrUAI')}</label>
            <input
              type="text"
              className="form-control"
              id="zipCodeOrUAI"
              value={zipCodeOrUAI}
              placeholder=""
              onChange={onChangeSearch}
              autoComplete="off"
            />
          </div>
        </div>
        <button
          type="submit"
          className="btn btn-outline-dark mb-3 col"
          disabled={disabled || zipCodeOrUAI.length < 5}
        >
          {' '}
          {isLoading && (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />{' '}
            </>
          )}
          {t('search')}
        </button>
        {!!error && (
          <div className="text-danger">
            <ErrorMsg error={error} namespace="user.wayf.errors" />
          </div>
        )}
      </form>
    </>
  );
};

/***********************************************************/
/***********************************************************/
/** AddForm COMPONENT **/

const AddForm = ({
  disabled,
  structuresList,
  onStatus,
}: {
  structuresList: WayfStructure[];
  disabled: boolean;
  onStatus: (uai: string) => void;
}) => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.structures',
  });
  const { getMessage } = useError();

  /* -- States ------------------------------------------------------------- */

  const [selectedStructureUai, setSelectedStructureUai] = useState<
    string | undefined
  >();

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const becomeTeacherMutation = useMutation('becomeTeacher', becomeTeacher, {
    retry: false,
    onSuccess: () => {
      toast(t('structureAdded'), {
        type: 'success',
      });
      onStatus('success');
      becomeTeacherMutation.reset();
    },
    onError: (
      error: AxiosError<{ error_message: string; reason: string[] | null }>,
    ) => {
      toast(getMessage(error.response?.data, 'user.structures'), {
        type: 'error',
        autoClose: false,
        closeButton: true,
      });
      onStatus('error');
      becomeTeacherMutation.reset();
    },
  });

  /* -- Callbacks ------------------------------------------------------------- */

  const onChangeSelect = useCallback((event: FormEvent<HTMLSelectElement>) => {
    const target = event.target as HTMLSelectElement;
    setSelectedStructureUai(target.value);
  }, []);

  const onSubmitAdd = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      const selectedStructure: WayfStructure | undefined = structuresList.find(
        (_structure) => _structure.uai === selectedStructureUai,
      );
      event.preventDefault();
      if (selectedStructure) {
        if (!selectedStructure.isGar && !selectedStructure.tne) {
          onStatus('loading');
          becomeTeacherMutation.mutate({
            source: 'WAYF',
            structure_identifier: selectedStructureUai,
          });
        } else {
          toast(t('noGarTne'), {
            type: 'error',
            autoClose: false,
            closeButton: true,
          });
        }
      }
    },
    [becomeTeacherMutation, selectedStructureUai, onStatus, structuresList, t],
  );

  /* -- Effects ------------------------------------------------------------- */

  useEffect(() => {
    if (structuresList.length === 1) {
      setSelectedStructureUai(structuresList[0].uai);
    } else {
      setSelectedStructureUai(undefined);
    }
  }, [structuresList]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <>
      {!!structuresList.length && (
        <form onSubmit={onSubmitAdd}>
          <div className="row align-items-end">
            <div className="form-group mb-3 col">
              {structuresList.length > 1 && (
                <label htmlFor="structure">{t('chooseStructure')}</label>
              )}
              <select
                className="form-select mb-3"
                size={Math.min(structuresList.length, 10)}
                aria-label={t('structures')}
                id="structure"
                onChange={onChangeSelect}
                value={selectedStructureUai}
              >
                {!structuresList.length && (
                  <option disabled>Aucun établissement</option>
                )}
                {structuresList.map((_structure) => (
                  <option
                    key={_structure.uuid}
                    value={_structure.uai}
                  >{`${_structure.officialName} (${_structure.uai})`}</option>
                ))}
              </select>
            </div>
          </div>
          <button
            type="submit"
            className="btn btn-outline-dark mb-3"
            disabled={disabled || !structuresList.length}
          >
            {' '}
            {becomeTeacherMutation.isLoading && (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
              </>
            )}
            {t('addThisStructure')}
          </button>
        </form>
      )}
    </>
  );
};

/***********************************************************/
/***********************************************************/
export default Structures;
