import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import languageDetector from 'i18next-browser-languagedetector';
import backend from 'i18next-http-backend';
import francais from './assets/locales/fr/resources.json';
import anglais from './assets/locales/en/resources.json';

const resources = {
  en: { translation: anglais },
  fr: { translation: francais },
};

i18n
  .use(initReactI18next)
  .use(languageDetector)
  .use(backend)
  .init({
    resources: resources,
    detection: {
      order: ['cookie'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/resources.json',
    },
    react: { useSuspense: false },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
