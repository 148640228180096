import { useTheme } from 'styled-components';
import {
  CbContainerS,
  CbIconS,
  CbInputS,
  CbLabelS,
} from './FormCheckbox.styles';

type FormCheckboxProps = {
  checked: boolean;
  toggleCheckbox: () => void;
  id: string;
};
const FormCheckbox = ({ checked, toggleCheckbox, id }: FormCheckboxProps) => {
  const theme = useTheme();
  return (
    <CbContainerS>
      <CbInputS
        type="checkbox"
        id={id}
        checked={checked}
        onChange={toggleCheckbox}
      />
      <CbLabelS
        isChecked={checked}
        borderColor={theme.colors.primary}
        htmlFor={id}
      >
        <CbIconS isChecked={checked} aria-hidden="true" color="currentColor" />
      </CbLabelS>
    </CbContainerS>
  );
};

export default FormCheckbox;
