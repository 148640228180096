import { DropDownSelectOption } from '@maskott/tactileo.designsystem';

import {
  SelectWrapper,
  Label,
  DropDownSelectStyled,
  ErrorMessage,
  ListBoxStyles,
} from './BaseSelect.styles';

import { HomeIcon } from '@maskott/tactileo.designsystem';

interface BaseSelectProps {
  id?: number | string;
  label?: string;
  error?: string | null;
  showError?: boolean;
  selectedOptionId?: number | string;
  options: {
    id: number | string;
    label: string;
    value: any;
    disabled: boolean;
  }[];
  onChange?: (option: DropDownSelectOption) => unknown;
  placeholder?: string;
  removeChoiceFromList?: boolean;
  noIcon?: boolean;
  IconComponent?: () => JSX.Element;
}

const BaseSelect = ({
  label,
  error,
  showError,
  placeholder = '',
  ...props
}: BaseSelectProps) => {
  return (
    <SelectWrapper>
      {label && <Label>{label}</Label>}
      <DropDownSelectStyled
        // isError={!!(error && showError)}
        placeHolder={placeholder}
        IconComponent={HomeIcon}
        size={'small'}
        {...props}
      />
      {error && showError && <ErrorMessage>{error}</ErrorMessage>}
      <ListBoxStyles />
    </SelectWrapper>
  );
};

export default BaseSelect;
