import { TFunction } from 'react-i18next';

import { PublicLayout } from 'components/layout';
import {
  FieldStyled,
  LoginStudentPageStyled,
  LabelStyled,
  LoginStudentButtonStyled,
  FieldsWrapperStyled,
  LoginStudentFormStyled,
  FieldErrorStyled,
} from './LoginStudent.styles';
import { FormPasswordInput, Spinner } from 'components/atoms';
import { FormInput } from '@maskott/tactileo.designsystem';

type LoginStudentProps = {
  classroomValue: string;
  loginValue: string;
  setLoginStudentValue: (value: string) => any;
  setClassroomValue: (value: string) => any;
  passwordValue: string;
  setPasswordValue: (value: string) => any;
  t: TFunction<'translation'>;
  loginDisabled: boolean;
  loginRequest: (e: React.FormEvent<HTMLFormElement>) => any;
  credentialsError: string | undefined;
  isLoading: boolean;
};

const LoginStudent = ({
  t,
  classroomValue,
  setClassroomValue,
  loginValue,
  setLoginStudentValue,
  passwordValue,
  setPasswordValue,
  loginDisabled,
  loginRequest,
  credentialsError,
  isLoading,
}: LoginStudentProps) => {
  return (
    <PublicLayout title={t('title')}>
      <LoginStudentPageStyled>
        <LoginStudentFormStyled onSubmit={loginRequest}>
          <FieldsWrapperStyled>
            <FieldStyled>
              <LabelStyled htmlFor="classroomInput">
                {t('classroomCode')}
              </LabelStyled>
              <FormInput
                leftIcon="house"
                placeholder={t('classroomCodeHolder')}
                id="classroomInput"
                value={classroomValue}
                state={credentialsError ? 'error' : null}
                onChange={setClassroomValue}
              />
            </FieldStyled>
            <FieldStyled>
              <LabelStyled htmlFor="loginInput">{t('userName')}</LabelStyled>
              <FormInput
                leftIcon="user"
                placeholder={t('userNameHolder')}
                id="loginInput"
                value={loginValue}
                state={credentialsError ? 'error' : null}
                onChange={setLoginStudentValue}
              />
            </FieldStyled>
            <FieldStyled>
              <LabelStyled htmlFor="passwordInput">{t('password')}</LabelStyled>
              <FormPasswordInput
                id="passwordInput"
                value={passwordValue}
                onChange={setPasswordValue}
                placeholder={t('passwordHolder')}
                state={credentialsError ? 'error' : null}
              />
              {credentialsError && (
                <FieldErrorStyled>{t(credentialsError)}</FieldErrorStyled>
              )}
            </FieldStyled>
          </FieldsWrapperStyled>
          <LoginStudentButtonStyled
            type="submit"
            disabled={loginDisabled}
            label={t('logIn')}
            Icon={isLoading ? Spinner : undefined}
            imgSide={isLoading ? 'right' : loginDisabled ? 'left' : undefined}
          />
          <br />
          {/* <SmallLinkStyled to={`/${spacePath}${routes.forgotPassword.path}`}>
            {t('forgotPassword')}
          </SmallLinkStyled> */}
        </LoginStudentFormStyled>
      </LoginStudentPageStyled>
    </PublicLayout>
  );
};

export default LoginStudent;
