import axios from 'axios';
import { ENDPOINTS } from 'appConstants';
import { SpaceType } from 'models';

// GET SPACE DETAILS (public)

interface GetSpaceParamsType {
  space_key: string;
}

export const getSpace = (params: GetSpaceParamsType): Promise<SpaceType> => {
  return axios
    .get<SpaceType>(`${ENDPOINTS.publicSpaces}/${params.space_key}`)
    .then((response) => response.data);
};

// GET SPACES LIST (public)
export const getSpaces = (): Promise<SpaceType[]> => {
  return axios
    .get<{ results: SpaceType[] }>(`${ENDPOINTS.publicSpaces}`)
    .then((response: { data: { results: SpaceType[] } }) => {
      return response?.data?.results;
    });
};
