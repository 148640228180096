import styled from 'styled-components';

export const AnimalsPasswordStyle = styled.div`
  display: flex;
  .tiles {
    display: flex;
    justify-content: center;
    width: 100%;
    .tilesColumn {
      padding: 5px;
      max-width: 80px;
    }
    .tile {
      aspect-ratio: 3;
      height: auto;
      text-align: center;
      background-color: white;
      .frame {
        width: 100%;
        height: auto;
        aspect-ratio: 1;
        position: relative;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: hidden;
      }
      .slider {
        width: 100%;
        height: 300%;
        position: absolute;
        margin-top: -100%;
        div {
          width: 100%;
          height: 33.33333333%;
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center center;
        }
      }
      button {
        display: block;
        width: 100%;
        height: auto;
        aspect-ratio: 1;
        line-height: 100%;
        transform: rotate(90deg);
        border: none;
        background-color: white;
        font-size: 2em;
      }
      &.movingUp .slider {
        margin-top: -200%;
        transition: all 0.5s;
      }
      &.movingDown .slider {
        margin-top: 0;
        transition: all 0.5s;
      }
      .animalName {
        font-size: 0.8em;
        font-weight: bold;
      }
    }
  }
`;
