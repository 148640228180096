import { atom, AtomEffect } from 'recoil';
import { LocalStorageKeys } from 'appConstants';
import { SpaceType } from '../models';

const localStorageEffect: <T>(key: string) => AtomEffect<T> =
  (key: string) =>
  ({ setSelf, onSet }) => {
    let savedValue = localStorage.getItem(key);

    if (key === LocalStorageKeys.CURRENT_SPACE) {
      if (savedValue && savedValue.length < 100) {
        // old versions where storing only space_key, a short string
        // no need to keep this code when in 2024
        localStorage.removeItem(LocalStorageKeys.CURRENT_SPACE);
        savedValue = null;
      }
    }

    if (savedValue !== null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue) => {
      if (newValue === null) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

export const AuthState = atom<boolean | null>({
  key: 'authenticationInfoState',
  default: null,
  effects_UNSTABLE: [
    localStorageEffect<boolean | null>(LocalStorageKeys.IS_AUTHENTICATED),
  ],
});

// The space key associated to user at login step is registered for sessions
// Profile endpoint is giving back this information with 'current_space_filter' label
// SessionSpaceState manage datas about this session space
// What's the difference with useTheme ? Theme is static (and about colors).
// Recoil's SessionSpaceState is about dynamic data managed by admin (logo, texts, documents...).
export const SessionSpaceState = atom<SpaceType | undefined>({
  key: 'userSpace',
  default: undefined,
  effects_UNSTABLE: [
    localStorageEffect<SpaceType | undefined>(LocalStorageKeys.CURRENT_SPACE),
  ],
});
