import styled from 'styled-components';
import { pxToRem, pl, pr } from '@maskott/tactileo.designsystem';

import {
  neutralBlack,
  neutralDarker,
  primaryLight,
  neutralWhite,
} from 'assets/colors';

export const BarContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  gap: ${pxToRem(48)};
  width: 100%;
  height: ${pxToRem(80)};
  ${pl(3)};
  ${pr(2.5)};
  background: ${neutralWhite};
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
  z-index: 2;
`;

export const SmallTextStyled = styled.span`
  font-size: ${pxToRem(17)};
  color: ${neutralBlack};
`;

export const BoldTextStyled = styled.span`
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(16)};
  font-weight: 900;
  text-transform: capitalize;
`;

export const TextBlockStyled = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  gap: ${pxToRem(2)};
  color: ${neutralDarker};
`;

export const UserNameBlockStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: ${neutralBlack};
  font-size: ${pxToRem(14)};
  line-height: ${pxToRem(16)};
`;
export const UserBlockStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${pxToRem(12)};
`;

export const LogoImageStyled = styled.img`
  width: 100%;
  height: 40px;
  object-fit: contain;
`;

export const UserLogoStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${pxToRem(40)};
  height: ${pxToRem(40)};
  border-radius: 50%;
  background-color: rgb(${(p) => p.theme.colors.primary});
  color: ${primaryLight};
`;

export const FirstNameStyled = styled.div``;
export const LastNameStyled = styled.div``;
export const UserNameStyled = styled.div``;
export const TemplateStyled = styled.div``;
