import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, useNavigate } from 'react-router-dom';
import { useSpacePath, useClickOutside } from 'hooks';
import { routes } from 'router/routes';
import { ProfileState } from 'recoilTools';
import { useRecoilValue } from 'recoil';
import {
  LogoutItemStyled,
  MenuItemIconStyled,
  MenuLinkItemStyled,
  MenuStyled,
  MenuToggleStyled,
  MenuWrapperStyled,
} from 'components/parts/UserMenu/UserMenu.styles';
import { stripeSpaceFromUrl } from 'utils';

import classroomIcon from 'assets/images/classroom.svg';
import homeIcon from 'assets/images/home.svg';
import logoutIcon from 'assets/images/logout.svg';
import propertiesIcon from 'assets/images/properties.svg';

interface UserMenuProps {
  children?: React.ReactNode;
}

const UserMenu = ({ children }: UserMenuProps) => {
  /* -- Const ------------------------------------------------------------- */
  const navigate = useNavigate();
  const spacePath: string = useSpacePath();
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.core.topBar',
  });
  const menuRef = useRef<HTMLDivElement>(null);

  /* -- States ------------------------------------------------------------- */

  const profileState = useRecoilValue(ProfileState);
  const [isShow, setIsShow] = useState(false);

  /* -- Callbacks ------------------------------------------------------------- */

  const toggleMenu = useCallback(
    () => setIsShow((prevState) => !prevState),
    [],
  );

  const onClickLogout = useCallback(async () => {
    navigate(`/${spacePath}${routes.logoutPropagate.path}`);
  }, [navigate, spacePath]);

  const hideMenu = useCallback(() => setIsShow(false), []);

  const isActive = useCallback((path) => {
    return matchPath({ path }, stripeSpaceFromUrl(location.pathname));
  }, []);

  /* -- Effects ------------------------------------------------------------- */

  useClickOutside({ ref: menuRef, callback: hideMenu });

  /* -- Return ------------------------------------------------------------- */

  const isClassroomAccount = profileState?.is_classroom_account;
  const isDemoAccount = profileState?.is_demo_account;
  const isLearnerAccount = profileState?.is_learner_account;
  const hasLoginProvider = !!profileState?.current_login_provider;

  return (
    <MenuWrapperStyled ref={menuRef}>
      <MenuToggleStyled onClick={toggleMenu}>{children}</MenuToggleStyled>
      {profileState?.id && (
        <>
          <MenuStyled className={isShow ? 'show' : undefined}>
            {!isClassroomAccount && (
              <>
                {/* personalData */}
                <MenuLinkItemStyled
                  to={`/${routes.personalData.path}`}
                  className={isActive(routes.personalData.path) ? 'on' : ''}
                >
                  <MenuItemIconStyled alt={'students'} src={propertiesIcon} />
                  {t('personalData')}
                </MenuLinkItemStyled>
              </>
            )}
            {!isClassroomAccount &&
              !isDemoAccount &&
              !isLearnerAccount &&
              !hasLoginProvider && (
                <>
                  {/* structures */}
                  <MenuLinkItemStyled
                    to={`/${routes.structures.path}`}
                    className={isActive(routes.structures.path) ? 'on' : ''}
                  >
                    <MenuItemIconStyled alt={'structures'} src={homeIcon} />
                    {t('structures')}
                  </MenuLinkItemStyled>
                  {/* students access managment */}
                  <MenuLinkItemStyled
                    to={`/${routes.studentsHome.path}`}
                    className={isActive(routes.studentsHome.path) ? 'on' : ''}
                  >
                    <MenuItemIconStyled alt={'students'} src={classroomIcon} />
                    {t('students')}
                  </MenuLinkItemStyled>
                </>
              )}
            {/* Logout item */}
            <LogoutItemStyled onClick={onClickLogout} key="logout">
              <MenuItemIconStyled src={logoutIcon} alt="logout" />
              {t('logOut')}
            </LogoutItemStyled>
          </MenuStyled>
        </>
      )}
    </MenuWrapperStyled>
  );
};

export default UserMenu;
