import { useCallback, useEffect, useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import LoginView from './LoginClass';
import { doLogin, getPublicClassroom } from 'api';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'router/routes';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useParams } from 'react-router-dom';
import { AuthState, SessionSpaceState } from 'recoilTools';
import { useMutation } from 'react-query';

type LoginLocationStateType = { from?: { pathname: string; search: string } };

/** LoginClass COMPONENT *********************************************************/

const LoginClass = () => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.loginClass',
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state: locationState } = useLocation();
  const params = useParams();

  /* -- States ------------------------------------------------------------- */

  const [classroomKey, setClassroomKey] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const [authState, setAuthState] = useRecoilState(AuthState);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const doLoginMutation = useMutation('doLogin', doLogin, {
    retry: false,
  });

  const getPublicClassroomQuery = useMutation(
    'getPublicClassroom',
    getPublicClassroom,
    {
      retry: false,
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const loginRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const space_key: string | null =
        sessionSpace?.space_key && sessionSpace?.space_key !== 'default'
          ? sessionSpace?.space_key
          : null;

      doLoginMutation.mutate({
        username: classroomKey,
        password: passwordValue,
        continue: searchParams.get('continue'),
        space_key,
      });
    },
    [
      doLoginMutation,
      classroomKey,
      passwordValue,
      searchParams,
      sessionSpace?.space_key,
    ],
  );

  /* -- Effects ------------------------------------------------------------- */

  // get classroomKey from route
  useEffect(() => {
    if (params['classroomKey'] && params['classroomKey'] !== classroomKey) {
      setClassroomKey(params['classroomKey']);
    }
  }, [params, classroomKey, getPublicClassroomQuery]);

  // get classroom details
  useEffect(() => {
    if (
      classroomKey &&
      !getPublicClassroomQuery.data &&
      !getPublicClassroomQuery.isLoading &&
      !getPublicClassroomQuery.error
    ) {
      getPublicClassroomQuery.mutate(classroomKey);
    }
  }, [classroomKey, getPublicClassroomQuery]);

  // set AuthState if login success
  useEffect(() => {
    if (doLoginMutation.isSuccess) {
      if (doLoginMutation.data.is_success) {
        setAuthState(true);
      } else {
        setAuthState(null);
      }
    }
  }, [doLoginMutation, navigate, setAuthState]);

  // redirect if isAuthenticated
  useEffect(() => {
    if (authState) {
      if ((locationState as LoginLocationStateType)?.from) {
        const { from } = locationState as LoginLocationStateType;
        navigate(`${from?.pathname}${from?.search}`);
      } else {
        navigate(`/${routes.home.path}`);
      }
    }
  }, [locationState, navigate, authState]);

  // fix icon a11y
  useEffect(() => {
    const elements = document.querySelectorAll('.WithLeftIconIcon');
    for (let i = 0; i < elements.length; i++) {
      const icon = elements[i].nextSibling as HTMLElement;
      icon.setAttribute('aria-hidden', 'true');
    }
  }, []);

  /* -- Return ------------------------------------------------------------- */

  const error: string | undefined = doLoginMutation.data?.error_code
    ? `${doLoginMutation.data?.error_code}`
    : doLoginMutation.error
    ? `${doLoginMutation.error}`
    : undefined;

  return (
    <LoginView
      t={t}
      classroomName={getPublicClassroomQuery.data?.display_name || ''}
      setPasswordValue={setPasswordValue}
      loginDisabled={!classroomKey.length || !passwordValue.length}
      loginRequest={loginRequest}
      credentialsError={error}
      isLoading={getPublicClassroomQuery.isLoading || doLoginMutation.isLoading}
    />
  );
};

export default LoginClass;
