import { WayfStructure } from 'models';
import axios from 'axios';
import { getWayfApi } from 'utils';

const replaceApos = (str: string) => str.replaceAll(/&apos;/g, "'");

// GET LIST OF STRUCTURES (public)

interface GetWayfListPayload {
  zipCode: string;
  domain: string;
  page: number;
  limit: number;
}

export const getWayfList = async (payload: GetWayfListPayload) => {
  const wayfApi: string = getWayfApi();
  let url: string = '';
  if (!payload.domain) {
    url = `${wayfApi}/structures?zipCode=${payload.zipCode}&page=${payload.page}&limit=${payload.limit}`;
  } else {
    url = `${wayfApi}/structures/allowed?zipCode=${payload.zipCode}&page=${payload.page}&limit=${payload.limit}&domain=${payload.domain}`;
  }
  const response = await axios.get(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });

  const structures = response.data.structures.filter(
    (str: WayfStructure) => str.zipCodeUai !== 0,
  ) as Array<WayfStructure>;

  if (structures.length === 0) {
    const error = Error();
    error.name = 'noStructures';
    throw error;
  }
  structures.map((structure: WayfStructure): WayfStructure => {
    structure.officialName = structure.officialName
      ? replaceApos(structure.officialName)
      : '';
    structure.category = structure.category
      ? replaceApos(structure.category)
      : '';
    return structure;
  });

  return {
    structures,
    pages: response.data.pages,
    currentPage: response.data.currentPage,
    total: response.data.total,
  };
};

// GET ESTABLISHMENT DETAILS (public)

interface GetWayfStructurePayload {
  uai: string;
  domain: string;
}

export const getWayfStructure = async (payload: GetWayfStructurePayload) => {
  const wayfApi: string = getWayfApi();
  let url: string = '';
  if (!payload.domain) {
    url = `${wayfApi}/structures?uai=${payload.uai}`;
  } else {
    url = `${wayfApi}/structures/allowed?uai=${payload.uai}&domain=${payload.domain}`; // add project = space !!!
  }

  let response;

  try {
    response = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    throw { ...new Error(''), name: 'noStructure' } as Error;
  }

  if (!response) throw new Error('');

  const structure: WayfStructure = response.data as WayfStructure;
  structure.officialName = structure.officialName
    ? replaceApos(structure.officialName)
    : '';
  structure.category = structure.category
    ? replaceApos(structure.category)
    : '';

  return structure;
};

// GET QR CODE (public)
export const getWayfQrCode = async (url: string) => {
  const wayfApi: string = getWayfApi();
  return axios.get(`${wayfApi}/qr-codes?url=${encodeURI(url)}`);
};
