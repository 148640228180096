import axios from 'axios';
import { ENDPOINTS } from 'appConstants';
import { Mailinglist } from 'models';

// GET MAILING LISTS OF A SPACE (public)
export const getMailinglists = (spaceKey: string): Promise<Mailinglist[]> => {
  const endpoint: string = ENDPOINTS.publicMailinglists.replace(
    '{spaceKey}',
    spaceKey,
  );
  return axios.get<Mailinglist[]>(endpoint).then((response) => response?.data);
};
