import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

/************************************/
/* HOOK : useSpacePath */

export const useSpacePath = (): string => {
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const [spacePath, setSpacePath] = useState('');

  useEffect(() => {
    const path =
      sessionSpace?.space_key !== 'default'
        ? 'spaces/' + sessionSpace?.space_key + '/'
        : '';
    setSpacePath(path);
  }, [sessionSpace?.space_key]);

  return spacePath;
};
