import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput } from '@maskott/tactileo.designsystem';

import { Spinner, RoundedCornerButton } from 'components/atoms';
import { PublicLayout } from 'components/layout';

import {
  ConfirmDemoAccountPage,
  ConfirmDemoAccountForm,
  ExplanatoryText,
  FieldLabel,
  Field,
} from 'pages/private/ConfirmDemoAccount/ConfirmDemoAccount.styles';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

interface ConfirmDemoAccountProps {
  userName: string;
  isLoading: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => unknown;
}

const ConfirmDemoAccount = ({
  userName,
  isLoading,
  onSubmit,
}: ConfirmDemoAccountProps) => {
  const sessionSpace = useRecoilValue(SessionSpaceState);

  const { t } = useTranslation('translation', {
    keyPrefix: 'user.confirmDemoAccount',
  });

  const AccessButtonIcon = useMemo(
    () => (isLoading ? Spinner : undefined),
    [isLoading],
  );

  return (
    <PublicLayout backButtonText={t('return')} title={t('title')}>
      <ConfirmDemoAccountPage>
        <ExplanatoryText>{sessionSpace?.description}</ExplanatoryText>
        <ConfirmDemoAccountForm onSubmit={onSubmit}>
          <Field>
            <FieldLabel htmlFor="UserNameInput">{t('userName')}</FieldLabel>
            <FormInput value={userName} state={null} disabled />
          </Field>
          {/* <FormInput
            value={password}
            leftIcon="padlock"
            state={null}
            disabled
          /> */}
          <RoundedCornerButton
            type="submit"
            state="primary"
            label={t('accessButton')}
            imgSide={isLoading ? 'right' : undefined}
            Icon={AccessButtonIcon}
          />
        </ConfirmDemoAccountForm>
      </ConfirmDemoAccountPage>
    </PublicLayout>
  );
};

export default ConfirmDemoAccount;
