import styled, { createGlobalStyle } from 'styled-components';

import {
  DropDownSelect,
  px,
  py,
  pxToRem,
} from '@maskott/tactileo.designsystem';

import {
  error,
  neutralBlack,
  neutralDark,
  neutralLight,
  neutralLighter,
  primaryText,
} from 'assets/colors';

export const SelectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(8)};
  width: 100%;
  cursor: text;
`;

export const Label = styled.span`
  color: ${neutralBlack};
  font-size: ${pxToRem(17)};
`;

export const DropDownSelectStyled = styled(DropDownSelect)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto !important;
  background: ${neutralLighter};
  border: 1px solid ${neutralLight};
  box-shadow: none;
  border-radius: 0;
  border: none !important;
  padding: 5px 0;
  ${px(2)};
  ${py(0.5)};

  &[aria-expanded] {
    box-shadow: none;
    border: 1px solid ${neutralLight};
  }

  &[aria-expanded='true'] {
    border-color: ${neutralDark};
  }
  p {
    text-overflow: clip !important;
    white-space: wrap !important;
    padding: 5px 5px 5px 35px;
    font-weight: 400;
    font-size: ${pxToRem(14)};
    font-weight: 400;
  }
`;

export const ErrorMessage = styled.div`
  color: ${error};
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(15)};
`;

export const ListBoxStyles = createGlobalStyle`
  body [role='listbox'] {
    border: none;
    border-radius: 0;
    box-shadow: rgba(0,0,0,0.2) 10px 10px 10px;
    
    [role='option'] {
      border-radius: 0;
      display: flex;
      justify-content: center;
      color: ${primaryText};
      font-size: ${pxToRem(14)};
      font-weight: 400;
      padding: 10px 5px;
      line-height: 1.3em;
    }
  }
`;
