import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormInput } from '@maskott/tactileo.designsystem';
import { LearnerAccountType } from 'models';
import { getUniqueIdFromInitials } from 'utils';

/** StudentsAccountsLineForm COMPONENT *********************************************************/

const StudentsAccountsLineForm = ({
  initialValue,
  onCancel,
  disabled,
  loading,
  alreadyUsed,
}: {
  initialValue: LearnerAccountType | undefined;
  onCancel?: () => void;
  disabled: boolean;
  loading: boolean;
  alreadyUsed?: string[];
}) => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });

  /* -- States ------------------------------------------------------------- */

  const [customUsername, setCustomUsername] = useState(false);
  const [student, setStudent] = useState({
    ...{
      last_name: '',
      first_name: '',
      login_name: '',
    },
    ...initialValue,
  });

  /* -- Callbacks ------------------------------------------------------------- */

  const onChangeLastName = useCallback(
    (value: string) => {
      const last_name: string = value;
      let login_name: string = student.login_name;
      if (!customUsername) {
        login_name = getUniqueIdFromInitials(
          student.first_name,
          last_name,
          alreadyUsed,
        );
      }
      setStudent({ ...student, last_name, login_name });
    },
    [student, customUsername, alreadyUsed],
  );
  const onChangeFirstName = useCallback(
    (value: string) => {
      const first_name: string = value;
      let login_name: string = student.login_name;
      if (!customUsername) {
        login_name = getUniqueIdFromInitials(
          value,
          student.last_name,
          alreadyUsed,
        );
      }
      setStudent({ ...student, first_name, login_name });
    },
    [student, customUsername, alreadyUsed],
  );
  const onChangeUsername = useCallback(
    (value: string) => {
      setStudent({ ...student, login_name: value });
      setCustomUsername(true);
    },
    [student],
  );

  /* -- Return ------------------------------------------------------------- */

  return (
    <>
      <tr>
        <td>
          <FormInput
            leftIcon={null}
            placeholder={''}
            id="last_name"
            value={student?.last_name}
            state={null}
            onChange={onChangeLastName}
          />
        </td>
        <td>
          <FormInput
            leftIcon={null}
            placeholder={''}
            id="first_name"
            value={student?.first_name}
            state={null}
            onChange={onChangeFirstName}
          />
        </td>
        <td>
          {!student?.learner_account_id && (
            <FormInput
              leftIcon={null}
              placeholder={''}
              id="login_name"
              value={student?.login_name}
              state={null}
              onChange={onChangeUsername}
            />
          )}
        </td>
        <td>&nbsp;</td>
        <td className="text-end">
          <button
            type="submit"
            className="btn btn-outline-dark"
            disabled={disabled}
          >
            {loading && (
              <>
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
              </>
            )}
            {student?.learner_account_id ? t('ok') : t('addStudent')}
          </button>
          {student?.learner_account_id && (
            <>
              &nbsp;
              <button
                type="button"
                onClick={onCancel}
                className="btn btn-outline-dark"
              >
                {t('cancel')}
              </button>
            </>
          )}
        </td>
      </tr>
    </>
  );
};

export default StudentsAccountsLineForm;
