import { FooterStyled, SmallLinkStyled } from './Footer.styles';
import { useTranslation } from 'react-i18next';
import { routes } from 'router/routes';
import { useSpacePath } from 'hooks';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';
import Favicon from 'assets/favicon.svg';

const Footer = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.footer',
  });
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const spacePath: string = useSpacePath();

  return (
    <FooterStyled>
      {sessionSpace?.space_key === 'default' ? (
        <></>
      ) : (
        <>{sessionSpace?.name} via</>
      )}{' '}
      <img
        src={Favicon}
        height="14"
        style={{ opacity: 0.8, verticalAlign: 'middle' }}
      />{' '}
      Learning Connect{<> </>}|&nbsp;
      <SmallLinkStyled
        target="_blank"
        to={`/${spacePath}${routes.legalNotices.path}`}
      >
        {t('termsOfUse')}
      </SmallLinkStyled>
      {<> </>}|&nbsp;
      <SmallLinkStyled
        target="_blank"
        to={`/${spacePath}${routes.dataProtection.path}`}
      >
        {t('dataPolicy')}
      </SmallLinkStyled>
      {<> </>}|&nbsp;
      {sessionSpace?.space_key === 'default' ? (
        <SmallLinkStyled to={`/${routes.cguPage.path}`}>
          {t('serviceAgreement')}
        </SmallLinkStyled>
      ) : (
        <SmallLinkStyled
          target="_blank"
          to={`/${spacePath}${routes.cguPage.path}`}
        >
          {t('serviceAgreement')}
        </SmallLinkStyled>
      )}
    </FooterStyled>
  );
};

export default Footer;
