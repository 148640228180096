import { WayfStructure } from 'models';
import styled from 'styled-components';

export const StructureDisplay = ({
  structure,
}: {
  structure: WayfStructure | undefined;
}) => {
  return (
    <StructureDisplayWrapper className="wayf_StructureDisplay {!structure && 'empty'}">
      {structure && (
        <>
          <span className="wayf_name">{structure.officialName}</span>
          <span className="wayf_complement">
            <span className="wayf_category">{structure.category}</span>
            <> </>(<span className="wayf_id">{structure.uai}</span>)
          </span>
        </>
      )}
    </StructureDisplayWrapper>
  );
};
export default StructureDisplay;

export const StructureDisplayWrapper = styled.span`
  display: block;
  background-color: transparent;
  border: none;
  padding: 0.5em 1em;
  margin: 0 0;
  border-radius: 5px;
  font-size: 1em;
  border: solid 1px #cccccc;
  font-weight: bold;
  width: auto;
  color: black;
  .wayf_name {
    display: block;
  }
  .wayf_complement {
    display: block;
    font-size: 0.9em;
    font-weight: normal;
  }
`;
