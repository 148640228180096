import { ENDPOINTS } from 'appConstants';
import { GroupedStructureType, StructureType } from 'models';
import { ProfileType } from 'models';
import axiosAuthorized from './axiosAuthorized';
import { replaceApos } from 'utils';

// GET PROFILE DETAILS (private) -------------

export const getProfileDetails = (): Promise<ProfileType> =>
  axiosAuthorized
    .get<ProfileType>(ENDPOINTS.profileDetails)
    .then((response) => response?.data);

// UPDATE PROFILE (private) -------------

export type UpdateProfilePayloadType = {
  email?: string;
  last_name: string;
  first_name: string;
  culture_name: string;
};

export const updateProfile = (
  payload: UpdateProfilePayloadType,
): Promise<null> => {
  return axiosAuthorized
    .post<null>(ENDPOINTS.profileUpdate, payload)
    .then((response) => response?.data);
};

// CHANGE PASSWORD (private) -------------

type ChangePasswordPayloadType = {
  current_password: string;
  new_password: string;
};

export const changePassword = (payload: ChangePasswordPayloadType) =>
  axiosAuthorized.post(ENDPOINTS.changePassword, payload);

// DELETE ACCOUNT (private) -------------

type DeleteAccountPayloadType = {
  password?: string;
};

export const deleteAccount = (payload: DeleteAccountPayloadType) =>
  axiosAuthorized.post(ENDPOINTS.profileDelete, payload);

// GET USER STRUCTURES (private) -------------

export const getStructures = (): Promise<StructureType[]> => {
  return axiosAuthorized
    .get<StructureType[]>(ENDPOINTS.structures)
    .then((response) => {
      return response?.data.map((ets) => ({
        ...ets,
        structure_name: replaceApos(ets?.structure_name || ''),
      }));
    });
};

// GET USER STRUCTURES, SPACES AND MODULES (private) -------------

export const getGroupedStructures = (): Promise<GroupedStructureType[]> => {
  return axiosAuthorized
    .get<GroupedStructureType[]>(ENDPOINTS.modulesGrouped)
    .then((response) => {
      return response?.data.map((ets) => ({
        ...ets,
        structure_name: replaceApos(ets?.structure_name || ''),
      }));
    });
};

// GET CURRENT USER ESTABLISHMENT, SPACES AND MODULES (private) -------------

export const getGroupedStructure = (): Promise<GroupedStructureType> => {
  return axiosAuthorized
    .get<GroupedStructureType>(ENDPOINTS.modules)
    .then((response) => {
      const ets = response.data;
      return {
        ...ets,
        structure_name: replaceApos(ets?.structure_name || ''),
      };
    });
};

// LINK USER TO ESTABLISHMENT (private) -------------

type BecomeTeacherPayloadType = {
  source: string;
  structure_identifier?: string;
};
export const becomeTeacher = (payload: BecomeTeacherPayloadType) =>
  axiosAuthorized.post(ENDPOINTS.becomeTeacher, payload);

// UNLINK USER TO ESTABLISHMENT (private) -------------

type RemoveStructurePayloadType = {
  structure_id?: number;
};
export const leaveStructure = (payload: RemoveStructurePayloadType) =>
  axiosAuthorized.post(ENDPOINTS.leaveStructure, payload);
