import { DefaultTheme } from 'styled-components';

export const DEFAULT_THEME: DefaultTheme = {
  key: 'default',
  name: 'Theme par défaut',
  colors: {
    primary: '71, 71, 71',
    primaryLight: '189, 189, 189',
  },
};

export const LOADING_THEME: DefaultTheme = {
  key: 'loading',
  name: 'Thème appliqué pendant le chargement',
  colors: {
    primary: '142, 142, 142',
    primaryLight: '237, 237, 237',
  },
};
