import styled from 'styled-components';
import { neutralLight, neutralLighter } from 'assets/colors';

export const StructuresBarContainer = styled.aside`
  width: 100%;
  height: auto;
  min-height: 48px;
  background: ${neutralLighter};
  border-bottom: solid 1px ${neutralLight};
  padding: 0;
  position: relative;
  ul {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 15px;
    max-width: 600px;
    margin: 0 auto;
  }
  .menuButton {
    pointer-events: none;
    width: 50px;
    height: 50px;
    position: absolute;
    top: calc(50% - 24px);
    right: 14px;
    padding: 10px;
    background-color: #f9fafb;
    p {
      text-indent: -999em;
      overflow: hidden;
      width: 0;
      height: 0;
    }
  }
  .holder {
    height: 47px;
  }
`;
