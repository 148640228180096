import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import EmailSent from './EmailSent';
import ForgotPasswordView from './ForgotPassword';
import { sendForgotPasswordLink } from 'api';
import { DefaultResponseErrorType } from 'models';
import { useSpacePath } from 'hooks';
import { routes } from 'router/routes';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const navigate = useNavigate();
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.forgotPassword',
  });
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const spacePath: string = useSpacePath();
  const { isLoading, isRefetching, refetch, error, isSuccess } = useQuery(
    'sendForgotPasswordLink',
    () => {
      return sendForgotPasswordLink(
        email,
        sessionSpace?.space_key && sessionSpace.space_key !== 'default'
          ? sessionSpace.space_key
          : null,
      ).then(() => {
        toast(t('forgotMessage'));
        navigate(`/${spacePath}${routes.login.path}`);
      });
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      cacheTime: 0,
    },
  );

  const submitEnabled = useMemo(
    () => !isLoading && !isRefetching,
    [isLoading, isRefetching],
  );

  if (isSuccess) return <EmailSent email={email} />;

  return (
    <ForgotPasswordView
      isLoading={isLoading}
      email={email}
      setEmail={setEmail}
      onSubmit={refetch}
      submitEnabled={submitEnabled}
      errorMessage={
        (error as DefaultResponseErrorType)?.response?.data?.error_message
      }
    />
  );
};

export default ForgotPassword;
