import { Link } from 'react-router-dom';
import { routes } from 'router/routes';
import styled from 'styled-components';
import { HomeIcon } from '@maskott/tactileo.designsystem';
import { useTranslation } from 'react-i18next';

/* Breadcrumb Component ***************************/

export interface BreadcrumbItem {
  name: string;
  url?: string;
}

export interface BreadcrumbProps {
  homePath: string;
  items: BreadcrumbItem[];
}

export const Breadcrumb = (props: BreadcrumbProps) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'atoms.breadcrumb',
  });

  return (
    <BreadcrumbStyle>
      <ul>
        <li className="homeItem">
          <Link to={`/${routes.home.path}`}>
            <div className="icon">
              <HomeIcon color={'#333'} />
              <span>{t('home')}</span>
            </div>
          </Link>
          &nbsp;&nbsp;&gt;&nbsp;&nbsp;
        </li>
        {props.items.map((item) => (
          <li key={item.name + item.url}>
            {item.url ? (
              <Link to={item.url}>{item.name}</Link>
            ) : (
              <span>{item.name}</span>
            )}
            &nbsp;&nbsp;&gt;&nbsp;&nbsp;
          </li>
        ))}
      </ul>
    </BreadcrumbStyle>
  );
};

const BreadcrumbStyle = styled.div`
  padding: 0;
  margin: 0 0 16px 0;
  ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 0;
    margin: 0;
    list-style-type: none;
    color: #999;
    font-size: 12px;
    line-height: 20px;
    li {
      &.homeItem {
        line-height: 0;
      }
      display: block;
      white-space: nowrap;
      a {
        display: inline-block;
        white-space: nowrap;
        vertical-align: middle;
        text-decoration: none;
        color: #333;
        &:hover {
          text-decoration: underline;
        }
      }
      &.homeItem {
        .icon {
          width: 20px;
          height: 20px;
          span {
            display: block;
            width: 0;
            height: 0;
            text-indent: -999em;
            overflow: hidden;
          }
        }
      }
    }
  }
`;
