import styled from 'styled-components';

export const AccountActivationPageStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  .instruction {
    display: block;
    a {
      display: inline;
      color: rgb(${({ theme }) => `${theme.colors.primary}`});
      cursor: pointer;
    }
  }
  button {
    display: inline-block;
    background-color: transparent;
    color: inherit;
    padding: 0;
    text-decoration: underline;
    border: none;
  }
  button[type='submit'] {
    display: inline-block;
    background-color: rgb(${({ theme }) => `${theme.colors.primary}`});
    color: white;
    padding: 0 10px;
    text-decoration: none;
    border: none;
    height: 46px;
    border-radius: 10px;
    font-weight: bold;
  }
  .activation_form {
    display: flex;
    gap: 20px;
    align-items: flex-start;
  }
  .error {
    color: red;
    margin: 0.5em 0;
  }
  .message {
    color: green;
    margin: 0.5em 0;
    font-style: italic;
  }
`;
