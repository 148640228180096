import { error, neutralLight } from 'assets/colors';
import { RoundedCornerButton } from 'components/atoms';
import styled from 'styled-components';

export const LoginPageStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const FieldStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  input {
    border: 1px solid rgb(${(props) => props.theme.colors.primary});
    box-shadow: 0px 1px 0px rgb(${(props) => props.theme.colors.primary});
  }
  input:focus {
    border: 2px solid rgb(${(props) => props.theme.colors.primary});
    background-color: ${neutralLight};
  }
  .errorState {
    border: 2px solid ${error}!important;
    box-shadow: 0px 1px 0px ${error};
    background-color: #fce8e8 !important;
  }
`;

export const FieldErrorStyled = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: ${error};
`;

export const FieldsWrapperStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 8px;
`;
export const LabelStyled = styled.label``;
export const SmallTextStyled = styled.p`
  font-size: 10px;
  line-height: 13px;
`;
export const LoginButtonStyled = styled(RoundedCornerButton)`
  margin-top: 24px;
`;
export const CreateAccountButtonStyled = styled(RoundedCornerButton)`
  /* margin-top: 32px; */
  margin-bottom: 32px;
  font-weight: 700;
`;
export const LoginFormStyled = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 32px;
`;

export const CreateDemoAccountButtonStyled = styled(RoundedCornerButton)`
  margin-top: 16px;
  margin-bottom: 32px;
  font-weight: 700;
`;
