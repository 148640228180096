import {
  borderColor,
  boxShadow,
  neutralDarker,
  primaryText,
} from 'assets/colors';
import { ArrowRightIcon } from '@maskott/tactileo.designsystem';
import styled from 'styled-components';

export const DisambiguatePageStyled = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const IdentityLinkStyled = styled.a`
  border: 2px solid ${borderColor};
  box-sizing: border-box;
  box-shadow: 0px 2px 0px ${boxShadow};
  border-radius: 20px;
  font-size: 17px;
  line-height: 20px;
  height: 72px;
  width: 100%;
  padding: 0 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: ${primaryText};
  text-decoration: none;
`;

export const IdentitiesListStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8px;
  width: 100%;
  margin: 16px 0;
`;

export const SmallTextStyled = styled.p`
  font-size: 10px;
  line-height: 13px;
`;

export const DescriptionStyled = styled.p`
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.35px;
  color: ${neutralDarker};
  margin: 0;
`;

export const ArrowRightIconStyled = styled(ArrowRightIcon)`
  width: 32px;
  height: 32px;
`;
