import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PublicLayout } from 'components/layout';
import {
  AfterLogoutPageStyled,
  ButtonsBlockStyled,
  ButtonStyled,
} from './AfterLogout.styles';
import { useSpacePath } from 'hooks';
import { routes } from 'router/routes';
import { useSetRecoilState } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

const AfterLogout = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.afterLogout',
  });

  const spacePath: string = useSpacePath();
  const queryParams = new URLSearchParams(window.location.search);
  const provider: string | null = queryParams.get('provider');
  const navigate = useNavigate();
  const setSessionSpaceState = useSetRecoilState(SessionSpaceState);

  useEffect(() => {
    setSessionSpaceState(undefined);
  }, [setSessionSpaceState]);

  const onClick = useCallback(() => {
    navigate(`/${spacePath}${routes.login.path}`);
  }, [navigate, spacePath]);

  return (
    <PublicLayout
      title={t('logoutDone')}
      breadcrumb={[
        {
          name: t('logoutDone'),
        },
      ]}
    >
      <AfterLogoutPageStyled>
        <br />
        <br />
        <br />
        <br />
        <div className="instruction">
          {provider === null && (
            <div>
              <p>{t('instruction1')}</p>
              <p>{t('instruction2')}</p>
            </div>
          )}
          {provider === 'true' && <p>{t('instructionWithProvider')}</p>}
          {provider === 'false' && (
            <div>
              <p>{t('instructionWithoutProvider')}</p>
            </div>
          )}
          {(provider === 'false' || provider === null) && (
            <ButtonsBlockStyled>
              <ButtonStyled
                state="primary"
                type="submit"
                label={t('login')}
                onClick={onClick}
                imgSide={undefined}
              />
            </ButtonsBlockStyled>
          )}
        </div>
      </AfterLogoutPageStyled>
    </PublicLayout>
  );
};

export default AfterLogout;
