import { useState, useCallback, FormEvent } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { LinkText } from 'components/atoms';
import { useMutation } from 'react-query';
import { importLearnerToClassroom } from 'api';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { StructureState } from 'recoilTools';
import { useRecoilValue } from 'recoil';
import { useError } from 'hooks';

/** StudentsAccounts COMPONENT *********************************************************/

const StudentsAccountsImport = ({
  disabled,
  onChange,
}: {
  disabled: boolean;
  onChange: (status: string) => void;
}) => {
  /* -- Const ------------------------------------------------------------- */
  const params = useParams();
  const classroomId: number = params['classroomId']
    ? parseInt(params['classroomId'])
    : -1;
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const { getMessage, codeToMessage } = useError();
  const IMPORT_ERRORS_MAX = 5;

  /* -- States ------------------------------------------------------------- */
  const structure = useRecoilValue(StructureState);
  const [fileToImport, setFileToImport] = useState(undefined);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const importLearnerToClassroomMutation = useMutation(
    'importLearnerToClassroom',
    importLearnerToClassroom,
    {
      retry: false,
      onSuccess: (response) => {
        onChange('imported');
        if (response.data) {
          if (response.data.is_success) {
            toast(t('importSuccess'), {
              type: 'success',
            });
          } else {
            if (response.data.error_code === 'INVALID_DATA') {
              const import_errors: string[] =
                Object.keys(response.data.import_errors) || [];
              const errorsMessages: string = import_errors
                .slice(0, IMPORT_ERRORS_MAX)
                .map((num) =>
                  t('importErrorLine', {
                    line: num,
                    message: codeToMessage(
                      response.data.import_errors[num],
                      'user.auth.students',
                    ),
                  }),
                )
                .join('\n');
              let message: string =
                import_errors.length > 1
                  ? t('importErrors', {
                      nb: import_errors.length,
                      message: errorsMessages,
                    })
                  : t('importError', {
                      message: errorsMessages,
                    });
              message =
                import_errors.length > IMPORT_ERRORS_MAX
                  ? message + '...'
                  : message;
              toast(message, {
                type: 'error',
                autoClose: false,
                closeButton: true,
              });
            } else {
              toast(getMessage(response?.data, 'user.auth.students'), {
                type: 'error',
                autoClose: false,
                closeButton: true,
              });
            }
          }
        }
      },
      onError: (
        error: AxiosError<{ error_message: string; reason: string[] | null }>,
      ) => {
        toast(getMessage(error.response?.data, 'user.auth.students'), {
          type: 'error',
          autoClose: false,
          closeButton: true,
        });
      },
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onFileChange = useCallback((event: FormEvent<HTMLInputElement>) => {
    const target: HTMLFormElement = event.target as HTMLFormElement;
    setFileToImport(target.files[0]);
  }, []);

  const onSubmitImport = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (structure?.structure_id && classroomId) {
        if (fileToImport) {
          const formData = new FormData();
          formData.append('file', fileToImport);
          onChange('importing');
          importLearnerToClassroomMutation.mutate({
            structure_id: structure?.structure_id,
            classroom_id: classroomId,
            formData: formData,
          });
        }
      }
    },
    [
      structure?.structure_id,
      classroomId,
      fileToImport,
      importLearnerToClassroomMutation,
      onChange,
    ],
  );

  /* -- Effects ------------------------------------------------------------- */

  /* -- Return ------------------------------------------------------------- */

  const mutating: boolean = importLearnerToClassroomMutation.isLoading;

  return (
    <>
      <h2>{t('importStudents')}</h2>
      <div>
        <Trans
          t={t}
          i18nKey="importStudentsText"
          components={{
            Link: <LinkText to="/modele.csv" title="modele.csv" />,
          }}
        />
      </div>
      <br />
      <div>
        <form
          encType="multipart/form-data"
          onSubmit={onSubmitImport}
          className="d-flex gap-3 justify-content-between"
        >
          <div>
            <input
              className="form-control"
              id="uploadInput"
              type="file"
              required
              onChange={onFileChange}
            />
          </div>
          <button
            type="submit"
            className="btn btn-outline-dark"
            disabled={disabled || mutating}
          >
            {t('importBtn')}
          </button>
        </form>
      </div>
    </>
  );
};

export default StudentsAccountsImport;
