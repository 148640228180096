import { useCallback, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { PrivateLayout } from 'components/layout';
import { StudentsClassroomsStyled } from './StudentsClassrooms.styles';
import { Breadcrumb } from 'components/atoms';
import { useQuery } from 'react-query';
import { getStructureClassrooms } from 'api';
import { routes } from 'router/routes';
import { StructureState } from 'recoilTools';
import { useRecoilValue } from 'recoil';
import StudentsClassroomsList from './StudentsClassroomsList';
import StudentsClassroomsAdd from './StudentsClassroomsAdd';
import { useNavigate } from 'react-router-dom';

/** StudentsClassrooms COMPONENT *********************************************************/

const StudentsClassrooms = () => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const navigate = useNavigate();

  /* -- States ------------------------------------------------------------- */

  const structure = useRecoilValue(StructureState);
  const [isMutating, setIsMutating] = useState<boolean>(false);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const structureClassroomsQuery = useQuery(
    ['structureClassroomsQuery', structure?.structure_id],
    () => {
      if (structure?.structure_id) {
        return getStructureClassrooms(structure?.structure_id);
      }
      return Promise.reject(null);
    },
    {
      refetchOnWindowFocus: false,
      enabled: false,
      retry: false,
      cacheTime: 0,
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onChange = useCallback(
    (status: string) => {
      setIsMutating(['adding', 'deleting', 'modifying'].includes(status));
      if (['added', 'deleted', 'modified'].includes(status)) {
        structureClassroomsQuery.refetch();
      }
    },
    [structureClassroomsQuery],
  );

  /* -- Effects ------------------------------------------------------------- */

  useEffect(() => {
    if (!structureClassroomsQuery.data && structure?.structure_id) {
      structureClassroomsQuery.refetch();
    }
  }, [structureClassroomsQuery, structure?.structure_id]);

  useEffect(() => {
    if (!structure) {
      navigate(`/${routes.home.path}`);
    }
  }, [structure, navigate]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <PrivateLayout>
      <div id="studentsHome-page">
        <StudentsClassroomsStyled>
          <div className="container p-4">
            <Breadcrumb
              homePath={routes.home.path}
              items={[
                {
                  name: t('studentsManagment'),
                  url: `/${routes.studentsHome.path}`,
                },
                { name: t('individualAccountsTitle') },
              ]}
            />
            <h1>{t('individualAccountsTitle')}</h1>
            <div>
              <Trans t={t} i18nKey="individualAccountsManagmentText" />
            </div>
            <StudentsClassroomsList
              list={structureClassroomsQuery.data}
              disabled={isMutating}
              onChange={onChange}
            />
            <StudentsClassroomsAdd disabled={isMutating} onChange={onChange} />
          </div>
        </StudentsClassroomsStyled>
      </div>
    </PrivateLayout>
  );
};

export default StudentsClassrooms;
