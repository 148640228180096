import styled from 'styled-components';
import { pxToRem, pl, pr } from '@maskott/tactileo.designsystem';

import { neutralWhite } from 'assets/colors';

export const ContainerStyled = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  gap: ${pxToRem(48)};
  width: 100%;
  height: ${pxToRem(80)};
  ${pl(3)};
  ${pr(2.5)};
  background: ${neutralWhite};
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.12);
  z-index: 1000;
`;
