import { getAppEnv } from './tools';

const MATOMO_SCRIPT: string = '//stats.maskott.com/matomo.js';
const MATOMO_TRACKER: string = '//stats.maskott.com/matomo.php';
const MATOMO_PROD_ID: number = 10;
const MATOMO_DEV_ID: number = 15;

declare global {
  // declare _paq in DOM window
  interface Window {
    _paq: any[];
  }
}

// INTERFACES
export interface TrackPageViewParams {
  documentTitle?: string;
  href?: string | Location;
}

export interface TrackEventParams extends TrackPageViewParams {
  category: string;
  action: string;
  name?: string;
  value?: number;
}
export interface CustomDimensions {
  domain: string;
  structure: string;
  bouquet: string;
  profile: string;
  type: string;
}
export interface CustomDimension {
  id: number;
  value: string;
}

// INIT
export const initMatomo = () => {
  if (!window._paq) {
    const { isProduction } = getAppEnv();
    window._paq = window._paq || [];
    window._paq.push(['setTrackerUrl', MATOMO_TRACKER]);
    window._paq.push([
      'setSiteId',
      isProduction ? MATOMO_PROD_ID : MATOMO_DEV_ID,
    ]);
    var d = document,
      g = d.createElement('script'),
      s = d.getElementsByTagName('script')[0];
    g.type = 'text/javascript';
    g.async = true;
    g.src = MATOMO_SCRIPT;
    s?.parentNode?.insertBefore(g, s);
  }
};

// TRACKERS
export const trackPageView = (trackPageViewData: TrackPageViewParams) => {
  window._paq.push(['setDocumentTitle', trackPageViewData.documentTitle]);
  window._paq.push(['trackPageView']);
};

export const trackEvent = (trackEventData: TrackEventParams) => {
  window._paq.push([
    'trackEvent',
    trackEventData.category,
    trackEventData.action,
    trackEventData.name,
    trackEventData.value,
  ]);
};

// CUSTOM DIMENSIONS
export const trackCustomDimensions = (
  customDimensions: Array<CustomDimension>,
) => {
  customDimensions.forEach((customDimension) => {
    trackCustomDimension(customDimension);
  });
};

export const trackCustomDimension = (customDimension: CustomDimension) => {
  if (customDimension.value) {
    window._paq.push([
      'setCustomDimension',
      customDimension.id,
      customDimension.value,
    ]);
  } else {
    window._paq.push(['deleteCustomDimension', customDimension.id]);
  }
};
