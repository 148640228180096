import faviconSvg from 'assets/favicon.svg';

export const getAppEnv = () => {
  const url: string = window.location.href;
  const isDev: boolean = url.indexOf('https://dev.learning-connect.com') === 0;
  const isPreprod: boolean =
    url.indexOf('https://preprod.learning-connect.com') === 0;
  const isProduction: boolean =
    url.indexOf('https://www.learning-connect.com') === 0;
  const isLocal: boolean = url.indexOf('http://localhost') === 0;
  return {
    isLocal,
    isDev,
    isPreprod,
    isProduction,
  };
};

export const getWayfApi = (): string => {
  const appEnv = getAppEnv();
  if (appEnv.isLocal) {
    // return 'http://localhost:3001';
    return 'https://tools.service.dev.maskott.com';
  } else if (appEnv.isDev) {
    return 'https://tools.service.dev.maskott.com';
  } else if (appEnv.isPreprod) {
    return 'https://tools.service.dev.maskott.com';
  } else if (appEnv.isProduction) {
    return 'https://tools.service.maskott.com';
  } else return '';
};

export const getRootUrl = (): string => {
  return `${document.location.protocol}//${document.location.hostname}${
    document.location.port ? ':' + document.location.port : ''
  }/`;
};

const emailRegex: RegExp =
  /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-_+-]+\.[A-Za-z]{2,}$/;

export const validateEmail = (email?: string) =>
  email ? emailRegex.test(email) : false;

const urlRegex = new RegExp(
  '^(https?:\\/\\/)?' + // validate protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // validate domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // validate OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // validate port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // validate query string
    '(\\#[-a-z\\d_]*)?$',
  'i',
);

export const validateUrl = (url: string) => !!urlRegex.test(url);

const zipCodeRegex: RegExp = /^[0-9]{5}$/;
export const removeSpecialCharsAndAccents = (input: string): string => {
  return input
    .normalize('NFD')
    .replace(/-/g, ' ')
    .replace(/[\u0300-\u036f]/g, '')
    .replace(/[^a-zA-Z ]/g, '');
};

export const validateZipCode = (zipCode?: string) =>
  zipCode ? zipCodeRegex.test(zipCode) : false;

export const replaceApos = (str: string) => str.replaceAll(/&apos;/g, "'");

export const getUniqueIdFromInitials = (
  firstName: string,
  lastName: string,
  alreadyUsed: string[] | undefined,
) => {
  // Supprimez les caractères spéciaux et les accents
  const cleanedFirstName: string = removeSpecialCharsAndAccents(firstName);
  const cleanedLastName: string = removeSpecialCharsAndAccents(lastName);

  // Divisez en mots
  const firstNameWords: string[] = cleanedFirstName.split(' ');
  const lastNameWords: string[] = cleanedLastName.split(' ');

  // Obtenez les initiales
  const firstNameInitials: string = firstNameWords
    .map((word) => word.charAt(0))
    .join('');
  const lastNameInitials: string = lastNameWords
    .map((word) => word.charAt(0))
    .join('');

  const initials: string =
    `${firstNameInitials}${lastNameInitials}`.toLowerCase();

  if (alreadyUsed) {
    let initialsWithIndex: string;
    let cp: number = 1;
    do {
      initialsWithIndex = initials + (cp === 1 ? '' : cp);
      cp++;
    } while (alreadyUsed.indexOf(initialsWithIndex) !== -1);
    return initialsWithIndex;
  }

  return initials;
};

export const stripeSpaceFromUrl = (url: string): string => {
  const tab: Array<string> = url.split('/');
  const pos: number = tab.indexOf('spaces');
  if (pos === -1) {
    return url;
  } else {
    tab.shift();
    tab.shift();
    tab.shift();
    return '/' + tab.join('/');
  }
};

export const getDomainFromEmail = (email: string) => {
  const emailTab: string[] = email?.split('@') || [];
  return emailTab?.length === 2 ? emailTab[1] : '';
};

export const setFavicon = async ({ color }: { color: string }) => {
  const links: NodeList = document.head.querySelectorAll(
    "[rel='shortcut icon']",
  );
  links.forEach((link) => {
    document.head.removeChild(link);
  });
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  canvas.width = 32;
  canvas.height = 32;
  const ctx: CanvasRenderingContext2D | null = canvas.getContext('2d');
  if (ctx) {
    ctx.fillStyle = `rgba(${color}, 1)`;
    ctx.beginPath();

    const img = new Image();
    img.src = faviconSvg;

    const svgContent = await fetch(faviconSvg);
    let svgText = await svgContent.text();
    svgText = svgText.replaceAll(/black|#000000/g, `rgb(${color})`);

    img.src = 'data:image/svg+xml,' + encodeURIComponent(svgText);

    img.onload = () => {
      ctx.drawImage(img, 0, 0);

      var link = document.createElement('link');
      link.type = 'image/png';
      link.rel = 'shortcut icon';
      link.href = canvas.toDataURL('image/png');
      document.head.appendChild(link);
    };
  }
};
