import styled from 'styled-components';

export const StudentsAccountsStyled = styled.div`
  height: 100%;

  .visual {
    background-color: lightgrey;
    background-color: rgba(${({ theme }) => theme.colors.primaryLight});
    min-height: 160px;
  }
  th {
    font-weight: normal;
  }
  td {
    vertical-align: middle;
  }
  .btn-reset {
    width: 45px;
  }
  .btn-print {
    width: 50px;
  }
`;
