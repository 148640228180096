import { SyntheticEvent, useEffect, useState, PropsWithChildren } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { WayfStructure } from 'models';
import { routes } from 'router/routes';
import { useSpacePath } from 'hooks';
import { Button } from '@maskott/tactileo.designsystem';
import { StructureDisplay } from './StructureDisplay';

const LinkText = (props: PropsWithChildren<{ to: string; title: string }>) => {
  return (
    <a
      href={props.to || '#'}
      target="_blank"
      title={props.title || ''}
      rel="noopener noreferrer"
    >
      {props.children}
    </a>
  );
};

export const StructureLink = ({
  structure,
  showBtn,
  onClose,
  onLink,
  demo,
}: {
  structure: WayfStructure;
  showBtn: boolean;
  onClose: () => void;
  onLink: () => void;
  demo: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.wayf',
  });

  const [urlWithStructure, setUrlWithStructure] = useState<string>(
    structure.urlValue,
  );

  const handleClose = (e: SyntheticEvent): void => {
    e.preventDefault();
    onClose();
  };

  const handleClick = () => {
    onLink();
  };

  const spacePath: string = useSpacePath();

  useEffect(() => {
    const url: string = demo
      ? `/${spacePath}${routes.createDemoAccount.path}?structure=${structure.uai}`
      : `/${spacePath}${routes.createAccount.path}?structure=${structure.uai}`;
    setUrlWithStructure(url);
  }, [spacePath, structure.uai, demo]);

  return (
    <div className="wayf_StructureLink">
      <div>
        <StructureDisplay structure={structure} />
        {showBtn && (
          <>
            {structure.isGar && !demo ? (
              structure.urlValue ? (
                <div>
                  {t('structureLink.garWithEnt.targetInformation')}
                  <div className="btn">
                    <Button
                      state="primary"
                      label={t('structureLink.garWithEnt.button')}
                      Icon={undefined}
                      onClick={() => {
                        document.location.href = structure.urlValue;
                      }}
                    />
                  </div>
                </div>
              ) : (
                <p className="wayf_nolink">
                  {t('structureLink.garWithoutEnt.instruction')}
                </p>
              )
            ) : structure.tne && !demo ? (
              <div className="wayf_tneMessage">
                <Trans
                  t={t}
                  i18nKey="structureLink.tne.instruction"
                  components={{
                    Link1: (
                      <LinkText to={t('structureLink.tne.link1')} title="TNE" />
                    ),
                    Link2: (
                      <LinkText
                        to={t('structureLink.tne.link2')}
                        title="Edugar"
                      />
                    ),
                  }}
                />
              </div>
            ) : (
              <div className="btn">
                <Button
                  state="primary"
                  label={t('structureLink.notGAR.button')}
                  Icon={undefined}
                  onClick={() => {
                    document.location.href = urlWithStructure;
                  }}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
export default StructureLink;
