import { PdfLayout } from 'components/layout';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

const DataProtection = () => {
  const sessionSpace = useRecoilValue(SessionSpaceState);

  return (
    <>
      {sessionSpace?.data_protection_uri && (
        <PdfLayout pdfFileUrl={sessionSpace?.data_protection_uri} />
      )}
    </>
  );
};

export default DataProtection;
