import { useState, useEffect, SyntheticEvent } from 'react';
import { WayfStructure } from 'models';
import { useTranslation } from 'react-i18next';
import { StructureDisplay } from './StructureDisplay';

export type SelectCallback = (structureSelected: WayfStructure) => void;

export const StructuresList = ({
  structures,
  onSelect,
}: {
  structures: Array<WayfStructure>;
  onSelect: SelectCallback;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.wayf',
  });
  const [category, setCategory] = useState('');
  const [filters, setFilters] = useState<string[]>([]);
  const [structuresFiltered, setStructuresFiltered] =
    useState<Array<WayfStructure>>();

  const handleSelect = (e: SyntheticEvent, structure: WayfStructure): void => {
    e.preventDefault();
    onSelect(structure);
  };
  const handleFilterSelect = (_category: string) => {
    setCategory(_category);
    setStructuresFiltered(
      structures.filter(
        (structure) => _category === '' || structure.category === _category,
      ),
    );
  };

  useEffect(() => {
    if (!structures) return;
    setStructuresFiltered(structures);
    const f: string[] = structures
      .reduce((acc: Array<string>, curr: WayfStructure) => {
        if (curr.category != '' && !acc.includes(curr.category))
          acc.push(curr.category);
        return acc;
      }, [])
      .sort((a, b) => (a < b ? -1 : a > b ? 1 : 0));
    if (f) setFilters(f);
  }, [structures]);

  return (
    <div className="wayf_StructuresList">
      {t('structuresList.instruction') && (
        <p className="wayf_instruction">{t('structuresList.instruction')}</p>
      )}
      <ul className="wayf_list">
        {structuresFiltered &&
          structuresFiltered.map((structure) => (
            <li key={structure.uuid}>
              <button
                onClick={(e: SyntheticEvent) => handleSelect(e, structure)}
              >
                <StructureDisplay structure={structure} />
              </button>
            </li>
          ))}
      </ul>
      <div className="wayf_filters">
        <ul>
          <li>
            <button
              onClick={() => handleFilterSelect('')}
              className={category === '' ? 'wayf_on' : ''}
            >
              Tous
            </button>
          </li>
          {filters.map((filter) => (
            <li key={filter}>
              <button
                onClick={() => handleFilterSelect(filter)}
                className={category === filter ? 'wayf_on' : ''}
              >
                {filter}
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default StructuresList;

import { css } from 'styled-components';

export const AppWrapperStyle = css`
  background-color: transparent;
  /* color: black; */
  text-align: left;

  .wayf_ZipCodeInput {
    input {
      font-size: 1.5em;
      letter-spacing: 0.5em;
      padding: 0.5em 0 0.5em 0.5em;
      /* padding: 0; */
      text-align: center;
      border: none;
      border-radius: 5px;
      width: 6em;
      border: solid 3px ${({ theme }) => theme.colors.primary};
    }
    input:focus {
      outline: 0;
    }
  }
`;
