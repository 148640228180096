import { useState, useEffect, useCallback, FormEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PublicLayout } from 'components/layout';
import { askActivationEmail, activateAccount } from 'api';
import { AccountActivationPageStyled } from './AccountActivation.styles';
import { useSpacePath, useCustomMatomo } from 'hooks';
import { routes } from 'router/routes';
import { FormInput } from '@maskott/tactileo.designsystem';
import { toast } from 'react-toastify';

// interface LocationState {
//   username: string;
// }

const AccountActivation = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.accountActivation',
  });
  const spacePath: string = useSpacePath();
  const navigate = useNavigate();
  const [code, setCode] = useState<string>('');
  const [error, setError] = useState<string | undefined>(undefined);
  const [errorResend, setErrorResend] = useState<string | undefined>(undefined);
  const { trackMatomoEvent } = useCustomMatomo();

  const urlParams = new URLSearchParams(window.location.search);
  const urlCode = urlParams.get('code');
  const username = urlParams.get('username');

  useEffect(() => {
    if (urlCode) {
      setCode(urlCode);
    }
    if (!username) {
      navigate(`/${spacePath}${routes.login.path}`);
    }
  }, [navigate, spacePath, urlCode, username]);

  const onChange = useCallback(
    (value: string) => {
      setCode(value);
    },
    [setCode],
  );

  const resetMessages = useCallback(() => {
    setError(undefined);
    setErrorResend(undefined);
  }, [setError, setErrorResend]);

  const onSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      resetMessages();
      activateAccount({
        username: `${username}`,
        activation_code: code,
      })
        .then((response) => {
          if (response.is_success) {
            setError(undefined);
            trackMatomoEvent({
              category: 'Setup',
              action: 'Create account',
              name: 'Standard',
            });
            navigate(
              `/${spacePath}${routes.accountActivated.path}?username=${username}`,
            );
          } else {
            setError(response?.error_code);
          }
        })
        .catch(() => {
          setError('unexpectedError');
        });
      return false;
    },
    [code, navigate, username, spacePath, resetMessages, trackMatomoEvent],
  );

  const onResendClick = () => {
    resetMessages();
    askActivationEmail({ username: `${username}` })
      .then(({ is_success, error_code }) => {
        if (is_success) {
          setErrorResend(undefined);
          toast(t('resendSuccess'));
        } else {
          setErrorResend(error_code);
        }
      })
      .catch(() => {
        setErrorResend('unexpectedError');
      });
  };

  return (
    <PublicLayout
      backButtonText={t('return')}
      title={t('title')}
      breadcrumb={[
        {
          name: t('title'),
        },
      ]}
    >
      <AccountActivationPageStyled>
        <div className="instruction">
          <p>{t('instruction')}</p>
          <form className="activation_form" onSubmit={onSubmit}>
            <FormInput
              state="success"
              leftIcon="padlock"
              placeholder={t('activationCode')}
              value={code}
              onChange={onChange}
            />
            <button type="submit">Activer</button>
          </form>
          {error && <p className="error">{t(error, { username: username })}</p>}
          <br />
          <br />
          <br />
          <div>{t('resendText')}</div>
          <button onClick={onResendClick}>{t('resendLink')}</button>
          {errorResend && (
            <p className="error">{t(errorResend, { username: username })}</p>
          )}
        </div>
      </AccountActivationPageStyled>
    </PublicLayout>
  );
};

export default AccountActivation;
