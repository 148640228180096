import { useCallback, useEffect, useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import LoginStudentView from './LoginStudent';
import { doLoginStudent } from 'api';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'router/routes';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AuthState, SessionSpaceState } from 'recoilTools';
import { useMutation } from 'react-query';
import { useSpacePath } from 'hooks';

type LoginLocationStateType = { from?: { pathname: string; search: string } };

/** LoginStudent COMPONENT *********************************************************/

const LoginStudent = () => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.loginStudent',
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state: locationState } = useLocation();
  const spacePath: string = useSpacePath();

  /* -- States ------------------------------------------------------------- */

  const [classroomValue, setClassroomValue] = useState('');
  const [loginValue, setLoginStudentValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const [authState, setAuthState] = useRecoilState(AuthState);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const doLoginStudentMutation = useMutation('doLoginStudent', doLoginStudent, {
    retry: false,
  });

  /* -- Callbacks ------------------------------------------------------------- */

  const loginRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const space_key: string | null =
        sessionSpace?.space_key && sessionSpace?.space_key !== 'default'
          ? sessionSpace?.space_key
          : null;

      doLoginStudentMutation.mutate({
        classroom_key: classroomValue,
        login_name: loginValue,
        password: passwordValue,
        continue: searchParams.get('continue'),
        space_key,
      });
    },
    [
      doLoginStudentMutation,
      classroomValue,
      loginValue,
      passwordValue,
      searchParams,
      sessionSpace?.space_key,
    ],
  );

  /* -- Effects ------------------------------------------------------------- */

  // login
  useEffect(() => {
    if (doLoginStudentMutation.isSuccess) {
      if (doLoginStudentMutation.data.is_success) {
        setAuthState(true);
      } else {
        setAuthState(null);
      }
    }
  }, [doLoginStudentMutation, navigate, spacePath, setAuthState]);

  // redirect if isAuthenticated
  useEffect(() => {
    if (authState) {
      if ((locationState as LoginLocationStateType)?.from) {
        const { from } = locationState as LoginLocationStateType;
        navigate(`${from?.pathname}${from?.search}`);
      } else {
        navigate(`/${routes.home.path}`);
      }
    }
  }, [locationState, navigate, authState]);

  // fix icon a11y
  useEffect(() => {
    const elements = document.querySelectorAll('.WithLeftIconIcon');
    for (let i = 0; i < elements.length; i++) {
      const icon = elements[i].nextSibling as HTMLElement;
      icon.setAttribute('aria-hidden', 'true');
    }
  }, []);

  /* -- Return ------------------------------------------------------------- */

  const error: string | undefined = doLoginStudentMutation.data?.error_code
    ? `${doLoginStudentMutation.data?.error_code}`
    : doLoginStudentMutation.error
    ? `${doLoginStudentMutation.error}`
    : undefined;

  return (
    <LoginStudentView
      t={t}
      classroomValue={classroomValue}
      setClassroomValue={setClassroomValue}
      loginValue={loginValue}
      setLoginStudentValue={setLoginStudentValue}
      passwordValue={passwordValue}
      setPasswordValue={setPasswordValue}
      loginDisabled={
        !classroomValue.length || !loginValue.length || !passwordValue.length
      }
      loginRequest={loginRequest}
      credentialsError={error}
      isLoading={doLoginStudentMutation.isLoading}
    />
  );
};

export default LoginStudent;
