import styled from 'styled-components';
import { pl } from '@maskott/tactileo.designsystem';

import { StructureService } from 'components/atoms';

interface StructureServiceStyledProps {
  isLastItem: boolean;
}

export const SpacesModulesGridList = styled.ul`
  list-style: none;
  ${pl(0)};
`;

export const SpacesModulesGridListItem = styled.li`
  margin: 30px 0;
`;

export const SpacesModulesGridLabel = styled.div`
  /* padding: 0 30px; */
  img {
    width: 100%;
    height: 60px;
    object-fit: contain;
    object-position: center center;
    margin-bottom: 15px;
  }
`;

export const SpacesModulesGridSpaceName = styled.span`
  font-weight: bold;
`;

export const SpacesModulesGridSpaceDescription = styled.span``;

export const SpacesModulesGridTextWrapper = styled.div``;

export const SpacesModulesGridSpaceLogo = styled.img``;

export const StyledMudulesGrid = styled.div``;

export const StructureServicesListWrapper = styled.div`
  padding: 30px;
  margin: 20px -30px;
`;

export const StructureServicesList = styled.ul`
  padding: 0;
  max-width: 1500px;
  margin: 0 auto;
  list-style-type: none;
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

export const StructureServicesListItem = styled.li`
  width: 275px;
  height: 320px;
  @media only screen and (max-width: 767px) {
    & {
      width: 100%;
      height: auto;
    }
  }
`;

export const StructureServiceStyled = styled(
  StructureService,
)<StructureServiceStyledProps>`
  ${({ isLastItem }) => {
    if (!isLastItem) {
      return `

      `;
    }
  }};
`;
