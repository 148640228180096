import { useTranslation } from 'react-i18next';
import { FormInput } from '@maskott/tactileo.designsystem';
import { RoundedCornerButton, Spinner } from 'components/atoms';
import { PublicLayout } from 'components/layout';

import {
  FieldStyled,
  LabelStyled,
  ForgotPasswordPageStyled,
  SmallTextStyled,
  FieldErrorStyled,
} from './ForgotPassword.styles';
import { FORGOT_PASSWORD_NO_USER_FOUND } from 'appConstants';
import { useSpacePath } from 'hooks';
import { routes } from 'router/routes';
import { Link } from 'react-router-dom';

type ForgotPasswordPropsType = {
  email: string;
  setEmail: (value: string) => any;
  onSubmit: () => any;
  errorMessage?: string;
  submitEnabled: boolean;
  isLoading: boolean;
};

const ForgotPassword = ({
  email,
  setEmail,
  onSubmit,
  errorMessage,
  submitEnabled,
  isLoading,
}: ForgotPasswordPropsType) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.forgotPassword',
  });

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    onSubmit();
  };

  const spacePath: string = useSpacePath();

  return (
    <PublicLayout
      backButtonText={t('goBack')}
      title={t('forgotPassword')}
      breadcrumb={[
        {
          name: t('forgotPassword'),
        },
      ]}
    >
      <form onSubmit={handleSubmit}>
        <ForgotPasswordPageStyled>
          <FieldStyled>
            <SmallTextStyled>{t('pleaseEnterYourEmail')}</SmallTextStyled>
            <LabelStyled htmlFor="UsernameInput">{t('username')}</LabelStyled>
            <FormInput
              leftIcon="email"
              placeholder={t('username')}
              id="UsernameInput"
              state={null}
              value={email}
              onChange={setEmail}
            />
            {errorMessage === FORGOT_PASSWORD_NO_USER_FOUND && (
              <FieldErrorStyled>{t('noAccountWasFound')}</FieldErrorStyled>
            )}
            {errorMessage && errorMessage !== FORGOT_PASSWORD_NO_USER_FOUND && (
              <FieldErrorStyled>{errorMessage}</FieldErrorStyled>
            )}
          </FieldStyled>
          <br />
          <div className="text-center">
            <div className="d-inline-block mb-4">
              <RoundedCornerButton
                type="submit"
                disabled={!submitEnabled}
                state="primary"
                label={t('send')}
                Icon={isLoading ? Spinner : undefined}
                imgSide={isLoading ? 'right' : undefined}
              />
            </div>
            <br />
            <Link to={`/${spacePath}${routes.forgotUsername.path}`}>
              {t('forgotUsername')}
            </Link>
          </div>
        </ForgotPasswordPageStyled>
      </form>
    </PublicLayout>
  );
};

export default ForgotPassword;
