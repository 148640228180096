import { PdfLayout } from 'components/layout';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

const LegalNotices = () => {
  const sessionSpace = useRecoilValue(SessionSpaceState);

  return (
    <>
      {sessionSpace?.legal_notices_uri && (
        <PdfLayout pdfFileUrl={sessionSpace?.legal_notices_uri} />
      )}
    </>
  );
};

export default LegalNotices;
