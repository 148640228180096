import styled from 'styled-components';

export const StudentsClassroomsStyled = styled.div`
  height: 100%;

  .visual {
    background-color: lightgrey;
    background-color: rgba(${({ theme }) => theme.colors.primaryLight});
    min-height: 160px;
  }
  th {
    font-weight: normal;
  }
  td {
    vertical-align: middle;
  }
  select {
    line-height: 1.2;
    height: 2.875rem;
    &.error {
      border: 0.0625rem solid rgb(193, 31, 31);
    }
  }
`;
