import { useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

export type UaiInputCallback = (value: string) => void;
export type UaiErrorCallback = (message: string) => void;
export const uaiFormat: RegExp = /[0-9]{7}[A-Z]{1}$/;

export const UaiInput = ({
  onUai,
  onError,
}: {
  onUai: UaiInputCallback;
  onError: UaiErrorCallback;
}) => {
  const [inputValue, setInputValue] = useState<string>('');
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.wayf',
  });

  const stripNonAllowed = (str: string): string => {
    return str.replace(/[^0-9a-zA-Z]/g, '');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let str = e.target.value;
    str = stripNonAllowed(str);
    str = str.toUpperCase();
    str = str.substring(0, 8);
    setInputValue(() => str);
  };

  const handleSubmit = () => {
    if (inputValue) {
      if (inputValue.match(uaiFormat)) {
        onUai(inputValue);
        onError('');
      } else {
        onUai('');
        onError('incorrectUaiFormat');
      }
    } else {
      onUai('');
      onError('emptyUaiInput');
    }
  };

  return (
    <div className="wayf_UaiInput">
      <p>
        {t('uaiInput.instruction') && (
          <label className="wayf_instruction" htmlFor="uai">
            {t('uaiInput.instruction')}
          </label>
        )}
      </p>
      <input
        type="text"
        name="uai"
        value={inputValue}
        autoComplete="off"
        onChange={handleChange}
        data-testid="UaiInput-input"
        placeholder="Code UAI"
      />
      <button type="submit" onClick={handleSubmit}>
        Ok
      </button>
    </div>
  );
};

export default UaiInput;
