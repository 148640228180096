import { useCallback, FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { addStructureClassroom } from 'api';
import { StructureState } from 'recoilTools';
import { useRecoilValue } from 'recoil';
import { AxiosError } from 'axios';
import { useError } from 'hooks';
import { toast } from 'react-toastify';
import StudentsClassroomsLineForm from './StudentsClassroomsLineForm';

/** StudentsClassrooms COMPONENT *********************************************************/

const StudentsClassroomsAdd = ({
  disabled,
  onChange,
}: {
  disabled: boolean;
  onChange: (status: string) => void;
}) => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const { getMessage } = useError();

  /* -- States ------------------------------------------------------------- */

  const structure = useRecoilValue(StructureState);
  const [inputError, setInputError] = useState<string>('');

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const addStructureAccountMutation = useMutation(
    'addStructureAccountMutation',
    addStructureClassroom,
    {
      retry: false,
      onSuccess: () => {
        toast(
          t('classroomAdded', {
            structure_name: `${structure?.structure_name} (${structure?.structure_identifier})`,
          }),
          {
            type: 'success',
          },
        );
        onChange('added');
      },
      onError: (
        error: AxiosError<{ error_message: string; reason: string[] | null }>,
      ) => {
        toast(getMessage(error.response?.data, 'user.auth.students'), {
          type: 'error',
          autoClose: false,
          closeButton: true,
        });
      },
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onSubmitAdd = useCallback(
    (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const target: HTMLFormElement = event.target as HTMLFormElement;
      if (structure?.structure_id) {
        if (target.level.value === '') {
          setInputError('noLevel');
        } else if (target.nameInput.value === '') {
          setInputError('noName');
        } else {
          setInputError('');
          onChange('adding');
          addStructureAccountMutation.mutateAsync({
            structure_id: structure.structure_id,
            level: target.level.value,
            name: target.nameInput.value,
          });
        }
      }
    },
    [structure?.structure_id, onChange, addStructureAccountMutation],
  );

  /* -- Effects ------------------------------------------------------------- */

  /* -- Return ------------------------------------------------------------- */

  const mutating: boolean = addStructureAccountMutation.isLoading;

  return (
    <>
      <h2>{t('addClassroom')}</h2>
      <form onSubmit={onSubmitAdd}>
        <div className="table-responsive-md">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">{t('classroomLevel')}</th>
                <th scope="col">{t('classroomName')}</th>
                <th scope="col">&nbsp;</th>
                <th scope="col">&nbsp;</th>
              </tr>
            </thead>
            <tbody>
              <StudentsClassroomsLineForm
                initialValue={{
                  level: '',
                  name: '',
                }}
                disabled={disabled || mutating}
                loading={addStructureAccountMutation.isLoading}
                error={inputError}
              />
            </tbody>
          </table>
          {inputError === 'noLevel' && (
            <div className="text-danger">{t('noLevel')}</div>
          )}
          {inputError === 'noName' && (
            <div className="text-danger">{t('noName')}</div>
          )}
        </div>
      </form>
    </>
  );
};

export default StudentsClassroomsAdd;
