import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { FormPasswordInput, Spinner } from 'components/atoms';
import {
  LabelStyled,
  SmallTextStyled,
  ConfirmButtonStyled,
  NewPasswordFieldsWrapper,
  ModalStyled,
  ModalTextStyled,
  CheckImageStyled,
  ModalContentStyled,
  SmallAlertStyled,
} from './ChangeForgottenPassword.styles';
import checkIcon from 'assets/images/check.svg';
import { routes } from 'router/routes';
import { SecurityIndicatorVariants } from 'appConstants';
import { PublicLayout } from 'components/layout';
import { useSpacePath } from 'hooks';

type ChangeForgottenPasswordPropsType = {
  newPassword: string;
  setNewPassword: (value: string) => unknown;
  repeatPassword: string;
  apiRequestError: string;
  setRepeatPassword: (value: string) => unknown;
  setShowModal: (value: boolean) => unknown;
  setPasswordQuality: (quality?: SecurityIndicatorVariants) => any;
  submitEnabled: boolean;
  onSubmit: () => unknown;
  passwordsAreEqual: boolean;
  showModal: boolean;
  isNewPasswordError: boolean;
  isRepeatPasswordError: boolean;
  passwordQuality?: SecurityIndicatorVariants;
  onNewPasswordUnfocused: () => unknown;
  onRepeatPasswordUnfocused: () => unknown;
  isLoading: boolean;
};

const ChangeForgottenPassword = ({
  newPassword,
  setNewPassword,
  repeatPassword,
  setRepeatPassword,
  setPasswordQuality,
  submitEnabled,
  onSubmit,
  passwordsAreEqual,
  showModal,
  setShowModal,
  onNewPasswordUnfocused,
  onRepeatPasswordUnfocused,
  apiRequestError,
  isLoading,
}: ChangeForgottenPasswordPropsType) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.forgotPassword',
  });
  const navigate = useNavigate();
  const spacePath = useSpacePath();

  return (
    <PublicLayout
      title={t('changePassword')}
      breadcrumb={[
        {
          name: t('changePassword'),
        },
      ]}
    >
      <NewPasswordFieldsWrapper>
        <LabelStyled htmlFor="NewPasswordInput">{t('newPassword')}</LabelStyled>
        <FormPasswordInput
          id="NewPasswordInput"
          value={newPassword}
          onChange={setNewPassword}
          placeholder={t('password')}
          state={null}
          qualityIndicator
          onQualityChanged={setPasswordQuality}
          onBlur={onNewPasswordUnfocused}
        />
        <SmallTextStyled>{t('yourPasswordShouldConsistOf')}</SmallTextStyled>
        <LabelStyled htmlFor="PasswordInput">
          {t('passwordConfirmation')}
        </LabelStyled>
        <FormPasswordInput
          onBlur={onRepeatPasswordUnfocused}
          id=""
          value={repeatPassword}
          onChange={setRepeatPassword}
          placeholder={t('passwordConfirmation')}
          state={passwordsAreEqual ? 'success' : null}
          qualityIndicator={newPassword === repeatPassword}
        />
        {repeatPassword && newPassword !== repeatPassword && (
          <SmallAlertStyled>{t('passwordsDifferent')}</SmallAlertStyled>
        )}
        {apiRequestError && (
          <SmallAlertStyled>{apiRequestError}</SmallAlertStyled>
        )}
      </NewPasswordFieldsWrapper>

      <ConfirmButtonStyled
        disabled={!submitEnabled}
        onClick={onSubmit}
        label={t('confirmBtn')}
        Icon={isLoading ? Spinner : undefined}
        imgSide={isLoading ? 'right' : undefined}
      />
      <ModalStyled
        isAriaHidden={`${!showModal}`}
        showHeader={false}
        size="XL"
        isOpen={showModal}
        ActionLabel={t('ok')}
        ActionFunc={() => navigate(`/${spacePath}${routes.login.path}`)}
        closeModal={() => setShowModal(false)}
      >
        <ModalContentStyled>
          <CheckImageStyled src={checkIcon} />
          <ModalTextStyled>{t('yourPasswordHasBeenSaved')}</ModalTextStyled>
        </ModalContentStyled>
      </ModalStyled>
    </PublicLayout>
  );
};

export default ChangeForgottenPassword;
