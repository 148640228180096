import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { ReplayIcon, PrintIcon } from '@maskott/tactileo.designsystem';
import { LearnerAccountType } from 'models';
import { toast } from 'react-toastify';
import { ReactComponent as CopyIcon } from 'assets/images/copy.svg';

/** StudentsAccountsLine COMPONENT *********************************************************/

const StudentsAccountsLine = ({
  account,
  onModify,
  onDelete,
  onPrint,
  onPassword,
  disabled,
  isDeleting,
  isGeneratingPassword,
}: {
  account: LearnerAccountType;
  onModify: (account: LearnerAccountType) => void;
  onDelete: (account: LearnerAccountType) => void;
  onPrint: (account: LearnerAccountType) => void;
  onPassword: (account: LearnerAccountType) => void;
  disabled: boolean;
  isDeleting: boolean;
  isGeneratingPassword: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });

  /* -- Callbacks ------------------------------------------------------------- */

  const onModifyClick = useCallback(() => {
    onModify(account);
  }, [onModify, account]);

  const onPasswordClick = useCallback(() => {
    onPassword(account);
  }, [onPassword, account]);

  const onClickCopyPassword = useCallback(() => {
    navigator.clipboard.writeText(account.password || '');
    toast(t('passwordCopied'), {
      type: 'success',
    });
  }, [account.password, t]);

  const onClickPrint = useCallback(() => {
    onPrint(account);
  }, [onPrint, account]);

  const onDeleteClick = useCallback(() => {
    onDelete(account);
  }, [onDelete, account]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <tr className="align-items-center">
      <td>{account.last_name}</td>
      <td>{account.first_name}</td>
      <td>{account.login_name}</td>
      <td>
        {account.password ? (
          <>
            <div className="input-group">
              <div className="input-group-prepend">
                <button
                  className="btn btn-outline-secondary btn-print"
                  type="button"
                  onClick={onClickCopyPassword}
                >
                  <CopyIcon className="inputIcon" height="25" />
                </button>
                <button
                  className="btn btn-outline-secondary btn-print"
                  type="button"
                  onClick={onClickPrint}
                >
                  <PrintIcon color="currentColor" />
                </button>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Recipient's username"
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
                value={account.password}
                readOnly
              />
            </div>
          </>
        ) : (
          <button
            type="button"
            className="btn btn-outline-dark btn-reset"
            onClick={onPasswordClick}
          >
            {isGeneratingPassword ? (
              <>
                {' '}
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />{' '}
              </>
            ) : (
              <ReplayIcon color="currentColor" />
            )}
          </button>
        )}
      </td>
      <td className="text-end">
        <button
          type="button"
          className="btn btn-outline-dark"
          onClick={onModifyClick}
          disabled={disabled}
        >
          {t('modify')}
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-outline-dark"
          onClick={onDeleteClick}
          disabled={disabled}
        >
          {' '}
          {isDeleting && (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />{' '}
            </>
          )}
          {t('delete')}
        </button>
      </td>
    </tr>
  );
};

export default StudentsAccountsLine;
