import { useState, useEffect, SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { WayfStructure } from 'models';
import { getWayfList, getWayfStructure } from 'api';
import ZipCodeInput, {
  ZipCodeInputCallback,
  ZipCodeErrorCallback,
} from './ZipCodeInput';
import UaiInput, { UaiInputCallback, UaiErrorCallback } from './UaiInput';
import StructuresList, { SelectCallback } from './StructuresList';
import StructureLink from './StructureLink';

import { WayfWrapperStyle } from './Wayf.styles';
import styled from 'styled-components';
import { ErrorMsg } from '../ErrorMsg/ErrorMsg';

const WayfWrapper = styled.div`
  ${WayfWrapperStyle}
`;

interface WayfType {
  onStructureSelect: (structure: WayfStructure | undefined) => void;
  domain: string;
  showBtn: boolean;
  demo: boolean;
}

const limit: number = 1000;

function Wayf({ onStructureSelect, domain, showBtn, demo }: WayfType) {
  const [inputMode, setInputMode] = useState<string>('zipCode'); // or 'uai'
  const [status, setStatus] = useState<string>('form');
  const [zipCode, setZipCode] = useState<string>('');
  const [error, setError] = useState<Error | undefined>(undefined);
  const [structures, setStructures] = useState<Array<WayfStructure>>([]);
  const [structure, setStructure] = useState<WayfStructure | null>(null);
  const [page, setPage] = useState<number>(1);
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'user.wayf',
  });

  const handleZipCode: ZipCodeInputCallback = (zipCodeValue: string) => {
    setError(undefined);
    setZipCode(zipCodeValue);
    setStructures([]);
  };
  const handleUai: UaiInputCallback = (uai) => {
    setError(undefined);
    setStructures([]);

    if (!uai) return;
    getWayfStructure({ uai, domain })
      .then((ets: WayfStructure) => {
        setStructure(ets);
        onStructureSelect(ets);
        setStatus('link');
      })
      .catch((error) => {
        if (error.response.status === 404) {
          setError({ ...new Error(), name: 'noStructure' } as Error);
        } else {
          setError(error);
        }
      });
  };

  useEffect(() => {
    if (!zipCode) return;
    setError(undefined);
    getWayfList({ zipCode, domain, page, limit }) // add domain email name user
      .then((result) => {
        if (result.structures.length) {
          setStructures((prevList) => {
            // this accumulutation feature is ready to support 'fetch more' on scroll feature
            const newList = [...prevList];
            result.structures.forEach(
              (item, i) => (newList[(page - 1) * limit + i] = item),
            );
            return newList;
          });
          setStatus('list');
        } else {
          setError({ ...new Error(), name: 'noStructures' } as Error);
        }
      })
      .catch((error: Error) => {
        setError(error);
      });
  }, [zipCode, domain, i18n, t, onStructureSelect, page]);

  const handleSubmit = (e: SyntheticEvent): void => {
    e.preventDefault();
  };
  const handleSelect: SelectCallback = (
    structureSelected: WayfStructure,
  ): void => {
    setStatus('link');
    setStructure(structureSelected);
    onStructureSelect(structureSelected);
  };

  const onChangeMode = (mode: string) => {
    setError(undefined);
    setInputMode(mode);
  };
  const onBack = () => {
    setError(undefined);
    setStatus('form');
    onStructureSelect(undefined);
  };
  const onZipCodeError: ZipCodeErrorCallback = (name: string) => {
    setError(name ? { ...new Error(), name } : undefined);
  };
  const onUaiError: UaiErrorCallback = (name: string) => {
    setError(name ? { ...new Error(), name } : undefined);
  };
  const handleCloseLink = () => {
    setStatus('form');
    onStructureSelect(undefined);
    setError(undefined);
  };
  const handleLink = () => {
    setError(undefined);
    // setStatus('form');
  };

  return (
    <WayfWrapper className="wayf_light">
      <div className="wayf">
        {status === 'form' && (
          <form onSubmit={handleSubmit}>
            <>
              {inputMode === 'zipCode' && (
                <ZipCodeInput
                  onZipCode={handleZipCode}
                  onError={onZipCodeError}
                />
              )}
              {inputMode === 'uai' && (
                <UaiInput onUai={handleUai} onError={onUaiError} />
              )}
              {status === 'form' && (
                <>
                  <div className="wayf_error">
                    {error && (
                      <span>
                        <ErrorMsg error={error} namespace="user.wayf.errors" />
                      </span>
                    )}
                  </div>
                  <p className="wayf_alternatives">
                    {t('alternatives')}
                    <br />
                    {inputMode === 'zipCode' && (
                      <button
                        className="simpleLink"
                        onClick={() => {
                          onChangeMode('uai');
                        }}
                      >
                        {t('gotoUai')}
                      </button>
                    )}
                    {inputMode === 'uai' && (
                      <button
                        className="simpleLink"
                        onClick={() => {
                          onChangeMode('zipCode');
                        }}
                      >
                        {t('gotoZipCode')}
                      </button>
                    )}
                  </p>
                </>
              )}
            </>
          </form>
        )}
        {!error && status === 'list' && (
          <>
            <p className="wayf_back" onClick={onBack}>
              {t(`back`)}
            </p>
            <StructuresList
              structures={structures}
              onSelect={(structure) => handleSelect(structure)}
            />{' '}
          </>
        )}
        {!error && status === 'link' && structure && (
          <>
            <p className="wayf_back" onClick={onBack}>
              {t(`back`)}
            </p>
            <StructureLink
              structure={structure}
              showBtn={showBtn}
              onClose={handleCloseLink}
              onLink={handleLink}
              demo={demo}
            />
          </>
        )}
      </div>
    </WayfWrapper>
  );
}

export default Wayf;
