import { DefaultTheme } from 'styled-components';
import assetsThemes from 'assets/themes.json';
import styled from 'styled-components';

const Themes = () => {
  const themes: DefaultTheme[] = assetsThemes.themes;

  return (
    <Wrapper>
      <h1>Themes keys</h1>
      <div className="colors">
        {themes.map((theme) => (
          <div key={theme.key} className="color">
            <div className="colorTitle">
              <p>{theme.key}</p>
            </div>
            <div
              className="stain"
              style={{ backgroundColor: `rgba(${theme.colors.primary})` }}
            >
              {/* {theme.colors.primary} */}
            </div>
            <div
              className="stain2"
              style={{
                backgroundColor: `rgba(${theme.colors.primaryLight})`,
              }}
            >
              {/* {theme.colors.primaryLight} */}
            </div>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 10px;
  .colors {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
  }
  .colorTitle {
    height: 70px;
    display: flex;
    p {
      align-self: flex-end;
    }
  }
  .color {
    width: 200px;
  }
  .stain {
    height: 70px;
  }
  .stain2 {
    height: 35px;
  }
`;

export default Themes;
