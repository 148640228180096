import { TFunction } from 'react-i18next';

import { PublicLayout } from 'components/layout';
import {
  FieldStyled,
  LoginPageStyled,
  LabelStyled,
  LoginButtonStyled,
  CreateAccountButtonStyled,
  FieldsWrapperStyled,
  LoginFormStyled,
  FieldErrorStyled,
} from './Login.styles';
import { FormPasswordInput, Spinner } from 'components/atoms';
import { routes } from 'router/routes';
import { FormInput } from '@maskott/tactileo.designsystem';
import { useSpacePath } from 'hooks';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

type LoginProps = {
  loginValue: string;
  setLoginValue: (value: string) => any;
  passwordValue: string;
  setPasswordValue: (value: string) => any;
  t: TFunction<'translation'>;
  loginDisabled: boolean;
  loginRequest: (e: React.FormEvent<HTMLFormElement>) => any;
  credentialsError: string | undefined;
  goToCreateAccount: () => any;
  isLoading: boolean;
};

const Login = ({
  t,
  loginValue,
  setLoginValue,
  passwordValue,
  setPasswordValue,
  loginDisabled,
  loginRequest,
  credentialsError,
  goToCreateAccount,
  isLoading,
}: LoginProps) => {
  const spacePath: string = useSpacePath();
  const sessionSpace = useRecoilValue(SessionSpaceState);

  return (
    <PublicLayout title={t('login')}>
      <LoginPageStyled>
        <LoginFormStyled onSubmit={loginRequest}>
          <FieldsWrapperStyled>
            <FieldStyled>
              <LabelStyled htmlFor="loginInput">{t('userName')}</LabelStyled>
              <FormInput
                leftIcon="user"
                placeholder={t('userName')}
                id="loginInput"
                value={loginValue}
                state={credentialsError ? 'error' : null}
                onChange={setLoginValue}
              />
            </FieldStyled>
            <FieldStyled>
              <LabelStyled htmlFor="passwordInput">{t('password')}</LabelStyled>
              <FormPasswordInput
                id="passwordInput"
                value={passwordValue}
                onChange={setPasswordValue}
                placeholder={t('password')}
                state={credentialsError ? 'error' : null}
              />
              {credentialsError && (
                <FieldErrorStyled>{t(credentialsError)}</FieldErrorStyled>
              )}
            </FieldStyled>
          </FieldsWrapperStyled>
          <LoginButtonStyled
            type="submit"
            disabled={loginDisabled}
            label={t('logIn')}
            Icon={isLoading ? Spinner : undefined}
            imgSide={isLoading ? 'right' : loginDisabled ? 'left' : undefined}
          />
          <br />
          <Link to={`/${spacePath}${routes.forgotPassword.path}`}>
            {t('forgotPassword')}
          </Link>
        </LoginFormStyled>
        <CreateAccountButtonStyled
          type="button"
          state="secondary"
          label={t('createAccount')}
          onClick={goToCreateAccount}
        />
        {sessionSpace?.open_to_user_demo && (
          <>
            <div className="text-center text-pre">{t('testDemoAccount')}</div>
            <div className="text-center">
              <Link to={`/${spacePath}${routes.wayfDemo.path}`}>
                {t('demoAccountButton')}
              </Link>
            </div>
          </>
        )}
      </LoginPageStyled>
    </PublicLayout>
  );
};

export default Login;
