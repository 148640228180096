import { useTranslation } from 'react-i18next';
import { FormInput } from '@maskott/tactileo.designsystem';
import { Spinner, FormCheckbox, FormPasswordInput } from 'components/atoms';
import { PublicLayout } from 'components/layout';
import WayfStructureDisplay from 'components/atoms/Wayf/StructureDisplay';
import {
  FieldStyled,
  FieldsWrapperStyled,
  LabelStyled,
  CreateAccountPageStyled,
  SmallLinkStyled,
  CreateAccountButtonStyled,
  TextStyled,
  SmallTextStyled,
  SmallAlertStyled,
  NameFieldsWrapper,
  PasswordFieldsWrapper,
  CheckboxWrapperStyled,
  CheckBoxLabelStyled,
  CancelButtonStyled,
  ButtonsBlockStyled,
  FieldErrorStyled,
  FormErrorStyled,
  CheckboxWrapperStyledTop,
} from './CreateAccount.styles';
import { useNavigate } from 'react-router-dom';
import {
  EMAIL_ERROR,
  SecurityIndicatorVariants,
  USER_NAME_ERROR,
} from 'appConstants';
import { routes } from 'router/routes';
import { SpaceType, Subscription, WayfStructure } from 'models';
import { generatePath } from 'react-router-dom';
import { useMedia } from 'hooks';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

type CreateAccountPropsType = {
  wayfStructure: WayfStructure | undefined;
  userName: string;
  setUserName: (value: string) => any;
  firstName: string;
  setFirstName: (value: string) => any;
  lastName: string;
  setLastName: (value: string) => any;
  email: string;
  setEmail: (value: string) => any;
  password: string;
  setPassword: (value: string) => any;
  confirmPassword: string;
  setConfirmPassword: (value: string) => any;
  setPasswordQuality: (quality?: SecurityIndicatorVariants) => any;
  conditionsChecked: boolean;
  toggleConditionsCheckbox: () => any;
  subscriptions: Subscription[];
  toggleSubscription: (identifier: string) => void;
  submitButtonAvailable?: boolean;
  isLoading: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => any;
  userNameError?: string;
  emailError?: string;
  errorCode?: string;
  allSpaces: SpaceType[];
};

const CreateAccount = ({
  wayfStructure,
  userName,
  setUserName,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  password,
  setPassword,
  setPasswordQuality,
  confirmPassword,
  setConfirmPassword,
  conditionsChecked,
  toggleConditionsCheckbox,
  subscriptions,
  toggleSubscription,
  submitButtonAvailable,
  onSubmit,
  isLoading,
  userNameError,
  emailError,
  errorCode,
  allSpaces,
}: CreateAccountPropsType) => {
  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'user.auth.createAccount',
  });
  const { isLargerThan767 } = useMedia();

  const navigate = useNavigate();
  const sessionSpace = useRecoilValue(SessionSpaceState);
  return (
    <PublicLayout
      backButtonText={t('return')}
      title={t('accountCreation')}
      breadcrumb={[
        {
          name: t('accountCreation'),
        },
      ]}
    >
      <CreateAccountPageStyled>
        <form onSubmit={onSubmit}>
          <TextStyled>{sessionSpace?.login_text}</TextStyled>
          <br />
          <FieldsWrapperStyled>
            <FieldStyled>
              <LabelStyled htmlFor="StructureInput">
                {t('structure')}
              </LabelStyled>
              <WayfStructureDisplay structure={wayfStructure} />
            </FieldStyled>
            <FieldStyled>
              <LabelStyled htmlFor="UserNameInput">{t('userName')}</LabelStyled>
              <FormInput
                leftIcon="user"
                placeholder={t('userNameExample')}
                id="UserNameInput"
                state={null}
                value={userName}
                onChange={setUserName}
              />
              {userNameError && (
                <FieldErrorStyled>
                  {userNameError === USER_NAME_ERROR
                    ? t('userNameError')
                    : userNameError}
                </FieldErrorStyled>
              )}
            </FieldStyled>
            <NameFieldsWrapper isLargerThan767={isLargerThan767}>
              <FieldStyled>
                <LabelStyled htmlFor="FirstNameInput">
                  {t('firstName')}
                </LabelStyled>
                <FormInput
                  placeholder={t('firstName')}
                  id="FirstNameInput"
                  state={null}
                  value={firstName}
                  onChange={setFirstName}
                />
              </FieldStyled>
              <FieldStyled>
                <LabelStyled htmlFor="LastNameInput">
                  {t('lastName')}
                </LabelStyled>
                <FormInput
                  placeholder={t('lastName')}
                  id="LastNameInput"
                  state={null}
                  value={lastName}
                  onChange={setLastName}
                />
              </FieldStyled>
            </NameFieldsWrapper>

            <FieldStyled>
              <LabelStyled htmlFor="EmailInput">{t('email')}</LabelStyled>
              <FormInput
                leftIcon="email"
                placeholder={t('emailExample')}
                id="EmailInput"
                state={emailError ? 'error' : null}
                value={email}
                onChange={setEmail}
              />
              {emailError && (
                <FieldErrorStyled>
                  {emailError === EMAIL_ERROR ? t('emailError') : emailError}
                </FieldErrorStyled>
              )}
            </FieldStyled>

            <PasswordFieldsWrapper>
              <LabelStyled htmlFor="PasswordInput">{t('password')}</LabelStyled>
              <SmallTextStyled>{t('passwordTooltip')}</SmallTextStyled>
              <FormPasswordInput
                id="PasswordInput"
                value={password}
                onChange={setPassword}
                placeholder={t('password')}
                state={null}
                qualityIndicator={true}
                onQualityChanged={setPasswordQuality}
              />
              <LabelStyled htmlFor="ConfirmPasswordInput">
                {t('passwordConfirmation')}
              </LabelStyled>
              <FormPasswordInput
                id="ConfirmPasswordInputs"
                value={confirmPassword}
                onChange={setConfirmPassword}
                placeholder={t('passwordConfirmation')}
                state={null}
                qualityIndicator={password === confirmPassword}
              />
              {confirmPassword && password !== confirmPassword && (
                <SmallAlertStyled>{t('passwordsDifferent')}</SmallAlertStyled>
              )}
            </PasswordFieldsWrapper>
          </FieldsWrapperStyled>

          {sessionSpace && sessionSpace.space_key === 'default' && (
            <CheckboxWrapperStyledTop>
              <FormCheckbox
                id="TermsAndConditionsCheckbox"
                checked={conditionsChecked}
                toggleCheckbox={() => {
                  toggleConditionsCheckbox();
                }}
              />
              <CheckBoxLabelStyled htmlFor="TermsAndConditionsCheckbox">
                {t('iHaveRead')}
                <ul>
                  {allSpaces?.map(
                    (space: SpaceType) =>
                      space.terms_of_use_uri && (
                        <li key={space.space_key}>
                          <SmallLinkStyled
                            target="_blank"
                            to={generatePath(
                              `/spaces/${space.space_key}/${routes.cguPage.path}`,
                              {
                                space: space.space_key,
                              },
                            )}
                          >
                            {t('serviceConditions')} {space.name || '[no name]'}
                          </SmallLinkStyled>
                        </li>
                      ),
                  )}
                  <li>
                    <SmallLinkStyled
                      target="_blank"
                      to={`/${routes.dataProtection.path}`}
                    >
                      {t('personalDatas')}
                    </SmallLinkStyled>
                  </li>
                </ul>
              </CheckBoxLabelStyled>
            </CheckboxWrapperStyledTop>
          )}

          {subscriptions.map((subscription: Subscription) => (
            <CheckboxWrapperStyled key={subscription.identifier}>
              <FormCheckbox
                id={`subscribe_${subscription.identifier}`}
                checked={subscription.accepted || false}
                toggleCheckbox={() => {
                  toggleSubscription(subscription.identifier);
                }}
              />
              <CheckBoxLabelStyled
                htmlFor={`subscribe_${subscription.identifier}`}
              >
                {i18n.exists(
                  `user.auth.createAccount.mailinglists.label_${subscription.identifier}`,
                )
                  ? t(`mailinglists.label_${subscription.identifier}`)
                  : t('mailinglists.label_default', {
                      name: subscription.identifier,
                    })}
              </CheckBoxLabelStyled>
            </CheckboxWrapperStyled>
          ))}

          <ButtonsBlockStyled isLargerThan767={isLargerThan767}>
            <CancelButtonStyled
              disabled={isLoading}
              onClick={() => navigate('..')}
              state="secondary"
              label={t('cancel')}
            />
            <CreateAccountButtonStyled
              disabled={!submitButtonAvailable || isLoading}
              type="submit"
              state="primary"
              label={t('createMyAccount')}
              Icon={isLoading ? Spinner : undefined}
              imgSide={
                isLoading
                  ? 'right'
                  : !submitButtonAvailable
                  ? 'left'
                  : undefined
              }
            />
          </ButtonsBlockStyled>
          {errorCode && <FormErrorStyled>{t(errorCode)}</FormErrorStyled>}
        </form>
      </CreateAccountPageStyled>
    </PublicLayout>
  );
};

export default CreateAccount;
