import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

/************************************/
/* ERROR : Try to translate error messages */

export const useError = () => {
  const { i18n, t } = useTranslation();

  const codeToMessage = useCallback(
    (code: string, namespace: string): string =>
      i18n.exists(`${namespace}.${code}`) ? t(`${namespace}.${code}`) : '',
    [i18n, t],
  );

  const errorToMessage = useCallback(
    (error: any, namespace: string): string => {
      let message: string = '';
      if (error.code) {
        message = codeToMessage(error.code, namespace);
      }
      if (!message && error.name) {
        message = codeToMessage(error.name, namespace);
      }
      if (!message && error.message) {
        message = codeToMessage(error.message, namespace);
      }
      if (!message && error.error_message) {
        message = codeToMessage(error.error_message, namespace);
      }
      if (!message) {
        message = codeToMessage('defaultError', namespace);
      }
      if (!message) {
        message = codeToMessage('defaultError', 'errors');
      }

      if (error.reasons) {
        message +=
          '\n' +
          error.reasons
            .map((reason: string) => {
              const txt: string = codeToMessage(reason, namespace);
              return txt ? txt : reason;
            })
            .join('\n');
      }

      return message;
    },
    [codeToMessage],
  );

  const getMessage = useCallback(
    (error: any, namespace: string): string => {
      if (error.errors && error.errors.length) {
        return error.errors
          .map((error: any) => errorToMessage(error, namespace))
          .join('<br/>');
      } else if (error) {
        return errorToMessage(error, namespace);
      } else {
        return codeToMessage('defaultError', 'errors');
      }
    },
    [errorToMessage, codeToMessage],
  );

  return { getMessage, codeToMessage };
};
