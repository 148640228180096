import { useTranslation } from 'react-i18next';
import { CguPageStyled } from './CguList.styles';
import { routes } from 'router/routes';
import { SpaceType } from 'models';
import { PublicLayout } from 'components/layout';

type CguListPropsType = {
  allSpaces: SpaceType[];
};

const CguList = ({ allSpaces }: CguListPropsType) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.cgu',
  });

  return (
    <PublicLayout backButtonText={t('return')} title={t('title')}>
      <CguPageStyled>
        <ul>
          {allSpaces?.map(
            (space: SpaceType) =>
              space.terms_of_use_uri && (
                <li key={space.space_key}>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    href={`/spaces/${space.space_key}/${routes.cguPage.path}`}
                  >
                    {t('serviceConditions')} {space.name || '[no name]'}
                  </a>
                </li>
              ),
          )}
        </ul>
      </CguPageStyled>
    </PublicLayout>
  );
};

export default CguList;
