import { useMemo, useState } from 'react';

import { resetPassword } from 'api';
import { SecurityIndicatorVariants } from 'appConstants';
import ChangeForgottenPasswordView from './ChangeForgottenPassword';
import { useSearchParams } from 'react-router-dom';

const ChangeForgottenPassword = () => {
  const [newPassword, setNewPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiRequestError, setApiRequestError] = useState('');
  const [isNewPasswordError, setIsNewPasswordError] = useState(false);
  const [isRepeatPasswordError, setIsRepeatPasswordError] = useState(false);
  const [passwordQuality, setPasswordQuality] =
    useState<SecurityIndicatorVariants>();

  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const onNewPasswordUnfocused = () =>
    setIsNewPasswordError(passwordQuality !== SecurityIndicatorVariants.HARD);

  const onRepeatPasswordUnfocused = () =>
    setIsRepeatPasswordError(newPassword !== repeatPassword);

  const submitEnabled = useMemo(
    () =>
      !!newPassword &&
      !isLoading &&
      newPassword === repeatPassword &&
      passwordQuality === SecurityIndicatorVariants.HARD,
    [isLoading, newPassword, repeatPassword, passwordQuality],
  );

  const onSubmit = () => {
    if (!submitEnabled) return;
    setIsLoading(true);
    resetPassword({
      new_password: newPassword,
      token,
    })
      .then(() => {
        setIsLoading(false);
        setShowModal(true);
      })
      .catch(({ response }) => {
        setApiRequestError(response.data.error_message);
        setIsLoading(false);
      });
  };

  return (
    <ChangeForgottenPasswordView
      newPassword={newPassword}
      setNewPassword={setNewPassword}
      repeatPassword={repeatPassword}
      setRepeatPassword={setRepeatPassword}
      setPasswordQuality={setPasswordQuality}
      submitEnabled={submitEnabled}
      onSubmit={onSubmit}
      apiRequestError={apiRequestError}
      passwordQuality={passwordQuality}
      showModal={showModal}
      setShowModal={setShowModal}
      isNewPasswordError={isNewPasswordError}
      isRepeatPasswordError={isRepeatPasswordError}
      passwordsAreEqual={!!newPassword && newPassword === repeatPassword}
      onNewPasswordUnfocused={onNewPasswordUnfocused}
      onRepeatPasswordUnfocused={onRepeatPasswordUnfocused}
      isLoading={isLoading}
    />
  );
};

export default ChangeForgottenPassword;
