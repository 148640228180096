import { useTranslation } from 'react-i18next';

import { PublicLayout } from 'components/layout';
import checkIcon from 'assets/images/check.svg';
import {
  ForgotPasswordPageStyled,
  BoldTextStyled,
  SuccessIconStyled,
  BigTextStyled,
} from './ForgotPassword.styles';

const EmailSent = ({ email }: { email: string }) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.forgotPassword',
  });

  return (
    <PublicLayout>
      <ForgotPasswordPageStyled>
        <SuccessIconStyled src={checkIcon} />
        <BigTextStyled>{t('theLinkWasSentTo')}</BigTextStyled>
        <BoldTextStyled>{email}</BoldTextStyled>
      </ForgotPasswordPageStyled>
    </PublicLayout>
  );
};

export default EmailSent;
