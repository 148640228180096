import { css } from 'styled-components';

export const WayfWrapperStyle = css`
  background-color: transparent;
  padding: 1px;
  text-align: center;

  .wayf_back {
    display: block;
    font-weight: normal;
    text-align: center;
    cursor: pointer;
    /* height: 0;
    padding: 5px 10px; */
  }

  .wayf_alternatives {
    display: block;
    .simpleLink {
      cursor: pointer;
      background: none !important;
      border: none;
      padding: 0 !important;
      text-decoration: underline;
      font-size: 1em;
      cursor: pointer;
    }
  }

  .wayf_ZipCodeInput {
    input {
      font-size: 1.5em;
      letter-spacing: 0.5em;
      padding: 0.5em 0 0.5em 0.5em;
      /* padding: 0; */
      text-align: center;
      border: none;
      border-radius: 5px;
      width: 6em;
      border: solid 3px ${({ theme }) => `rgb(${theme.colors.primary})`};
    }
    input:focus {
      outline: 0;
    }
  }

  .wayf_UaiInput {
    input {
      font-size: 1.5em;
      padding: 0.5em 0.25em;
      text-align: center;
      border: none;
      border-radius: 5px;
      width: 6em;
      border: solid 3px ${({ theme }) => `rgb(${theme.colors.primary})`};
    }
    button {
      font-size: 1.5em;
      padding: 0.5em;
      text-align: center;
      border: none;
      border-radius: 5px;
      background-color: white;
      color: ${({ theme }) => `rgb(${theme.colors.primary})`};
      border: solid 3px ${({ theme }) => `rgb(${theme.colors.primary})`};
      margin-left: 0.5em;
    }
    input:focus {
      outline: 0;
    }
  }

  .wayf_StructuresList {
    text-align: center;
    .wayf_instruction {
      color: black;
    }
    .wayf_filters ul {
      list-style-type: none;
      padding: 0;
      display: block;
      display: none;
      li {
        display: inline-block;
      }
      button {
        background-color: transparent;
        border: none;
        margin: 0 10px;
        white-space: nowrap;
      }
      button.wayf_on {
        color: ${({ theme }) => `rgb(${theme.colors.primary})`};
      }
      button:before {
        content: ' ';
        display: inline-block;
        width: 10px;
        height: 10px;
        border: solid 2px black;
        border-radius: 2px;
        margin-right: 5px;
        vertical-align: -10%;
      }
      button.wayf_on:before {
        border: solid 2px ${({ theme }) => `rgb(${theme.colors.primary})`};
        background-color: ${({ theme }) => `rgb(${theme.colors.primary})`};
      }
    }
    .wayf_list {
      list-style-type: none;
      padding: 0;
      margin: 0;
      height: 80vh;
      max-height: 300px;
      overflow-y: auto;
      color: black;
      padding: 0 15px;
      button {
        background-color: transparent;
        border: none;
        padding: 0;
        margin: 0;
        margin: 0.25em 0;
      }
      button:hover {
        background-color: ${({ theme }) => `rgb(${theme.colors.primary})`};
        color: white;
      }
    }
    .wayf_close {
      text-decoration: none;
    }
  }

  .wayf_StructureLink {
    .wayf_back {
      background-color: white;
      color: ${({ theme }) => `rgb(${theme.colors.primary})`};
      border: solid 2px ${({ theme }) => `rgb(${theme.colors.primary})`};
      text-decoration: none;
      border-radius: 100px;
      margin: 5px auto;
      width: 20px;
      height: 20px;
      line-height: 17px;
      text-align: center;
      padding: 0;
    }
    .wayf_id {
      font-size: 0.9em;
      font-weight: normal;
    }
    .btn {
      display: flex;
      justify-content: center;
    }
    .wayf_link {
      background-color: ${({ theme }) => `rgb(${theme.colors.primary})`};
      color: white;
      text-decoration: none;
      padding: 5px 1em;
      border-radius: 5px;
      font-size: 1.5em;
    }
  }

  .wayf_Popin {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 99999999;
    display: flex;
    align-items: center;
    justify-content: center;
    .wayf_overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.25);
    }
    .wayf_close {
      position: absolute;
      display: block;
      top: 15px;
      right: 15px;
      width: 30px;
      height: 30px;
      line-height: 30px;
      background-color: transparent;
      padding: 0;
      border: 0;
      font-size: 36px;
    }
    .wayf_inner {
      position: relative;
      background-color: white;
      width: 80%;
      min-width: 400px;
      max-width: 600px;
    }
  }
  &.wayf_dark {
    h1 {
      color: white;
    }
    .wayf_ZipCodeInput label.wayf_instruction {
      color: white;
    }
    .wayf_UaiInput label.wayf_instruction {
      color: white;
    }
    .wayf_StructuresList .wayf_instruction {
      color: black;
    }
    .wayf_StructuresList .wayf_filters ul button:before {
      border: solid 2px white;
    }
    .wayf_list {
      color: white;
    }
    .wayf_alternatives {
      color: white;
      .simpleLink {
        color: white;
      }
    }
    .wayf_StructureLink {
      color: white;
      .wayf_link {
        background-color: white;
        color: ${({ theme }) => `rgb(${theme.colors.primary})`};
      }
    }
  }

  .wayf_error {
    color: red;
    padding: 10px 0;
    height: 70px;
  }
`;
