import { useCallback, useEffect, useState, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import LoginView from './Login';
import { doLogin } from 'api';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { routes } from 'router/routes';
import { useRecoilState, useRecoilValue } from 'recoil';
import { AuthState, SessionSpaceState } from 'recoilTools';
import { useSpacePath } from 'hooks';
import { useMutation } from 'react-query';

type LoginLocationStateType = { from?: { pathname: string; search: string } };

/** Login COMPONENT *********************************************************/

const Login = () => {
  /* -- Const ------------------------------------------------------------- */
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.login',
  });
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { state: locationState } = useLocation();
  const spacePath: string = useSpacePath();

  /* -- States ------------------------------------------------------------- */

  const [loginValue, setLoginValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const [authState, setAuthState] = useRecoilState(AuthState);

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const doLoginMutation = useMutation('doLogin', doLogin, {
    retry: false,
  });

  /* -- Callbacks ------------------------------------------------------------- */

  const loginRequest = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      const space_key: string | null =
        sessionSpace && sessionSpace.space_key ? sessionSpace.space_key : null;

      doLoginMutation.mutate({
        username: loginValue,
        password: passwordValue,
        continue: searchParams.get('continue'),
        space_key,
      });
    },
    [doLoginMutation, loginValue, passwordValue, searchParams, sessionSpace],
  );

  const goToCreateAccount = useCallback(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const structure = urlParams.get('structure');
    if (structure) {
      navigate(
        `/${spacePath}${routes.createAccount.path}?structure=${structure}`,
      );
    } else {
      navigate(`/${spacePath}${routes.wayf.path}`);
    }
  }, [navigate, spacePath]);

  /* -- Effects ------------------------------------------------------------- */

  // set AuthState if login success
  useEffect(() => {
    if (doLoginMutation.isSuccess) {
      if (doLoginMutation.data.is_success) {
        setAuthState(true);
      } else {
        setAuthState(null);
        if (doLoginMutation.data.error_code === 'PENDING_ACTIVATION') {
          navigate(
            `/${spacePath}${routes.accountActivation.path}?username=${doLoginMutation.variables?.username}`,
          );
        }
      }
    }
  }, [doLoginMutation, navigate, spacePath, setAuthState]);

  // redirect if isAuthenticated
  useEffect(() => {
    if (authState) {
      if ((locationState as LoginLocationStateType)?.from) {
        const { from } = locationState as LoginLocationStateType;
        navigate(`${from?.pathname}${from?.search}`);
      } else {
        navigate(`/${routes.home.path}`);
      }
    }
  }, [locationState, navigate, authState]);

  // fix icon a11y
  useEffect(() => {
    const elements = document.querySelectorAll('.WithLeftIconIcon');
    for (let i = 0; i < elements.length; i++) {
      const icon = elements[i].nextSibling as HTMLElement;
      icon.setAttribute('aria-hidden', 'true');
    }
  }, []);

  /* -- Return ------------------------------------------------------------- */

  const error: string | undefined = doLoginMutation.data?.error_code
    ? `${doLoginMutation.data?.error_code}`
    : doLoginMutation.error
    ? `${doLoginMutation.error}`
    : undefined;

  return (
    <LoginView
      t={t}
      loginValue={loginValue}
      setLoginValue={setLoginValue}
      passwordValue={passwordValue}
      setPasswordValue={setPasswordValue}
      loginDisabled={!loginValue.length || !passwordValue.length}
      loginRequest={loginRequest}
      credentialsError={error}
      goToCreateAccount={goToCreateAccount}
      isLoading={doLoginMutation.isLoading}
    />
  );
};

export default Login;
