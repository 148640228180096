export const LANGUAGES = {
  EN: 'en',
  FR: 'fr',
};

export const INITIAL_LANGUAGE =
  navigator.language.split('-')[0] || LANGUAGES.FR;

export const AUTHENTICATED = {
  YES: 'true',
  NO: 'false',
};

export enum SecurityIndicatorVariants {
  EASY = 'easy',
  MEDIUM = 'medium',
  HARD = 'hard',
}

export enum LocalStorageKeys {
  CURRENT_SPACE = 'current_space',
  IS_AUTHENTICATED = 'is_authenticated',
}
