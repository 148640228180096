import { neutralDarker } from 'assets/colors';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FooterStyled = styled.footer`
  margin-top: auto;
  padding-top: 42px;
  padding-bottom: 16px;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;

export const SmallLinkStyled = styled(Link)`
  display: inline;
  text-decoration: none;
  color: ${neutralDarker};
  margin: 0 3px;
  white-space: nowrap;
  &:hover {
    text-decoration-line: underline;
    color: inherit;
  }
`;
