import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routes } from 'router/routes';
import { useRecoilValue } from 'recoil';
import { AuthState } from 'recoilTools';
import { useSpacePath } from 'hooks';

interface AuthorizedRouteProps {
  component: () => JSX.Element | null;
}

const AuthorizedRoute = ({
  component: Component,
  ...props
}: AuthorizedRouteProps) => {
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const spacePath = useSpacePath();

  const authState = useRecoilValue(AuthState);
  const isAuthenticated = !!authState; // from recoil

  useEffect(() => {
    if (!isAuthenticated) {
      navigate(`/${spacePath}${routes.login.path}`, {
        state: { from: { pathname, search } },
      });
    }
  }, [isAuthenticated, navigate, pathname, search, spacePath]);

  return isAuthenticated ? <Component {...props} /> : <></>;
};

export default AuthorizedRoute;
