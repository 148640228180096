import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { ml, pxToRem, mt } from '@maskott/tactileo.designsystem';

import { neutralMedium, secondaryText } from 'assets/colors';

export const HomeStyled = styled.div``;

export const WelcomeTextStyled = styled.div`
  justify-content: flex-start;
  font-weight: 900;
  font-size: ${pxToRem(12)};
  line-height: ${pxToRem(17)};
  text-transform: uppercase;
  color: ${secondaryText};
  margin-bottom: ${pxToRem(16)};
  margin-top: ${pxToRem(150)};
`;

export const BoldTextStyled = styled.span`
  font-weight: 700;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(32)};
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: ${pxToRem(4)};
`;

export const TitleButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  column-gap: ${pxToRem(24)};
  margin-bottom: 20px;
  @media only screen and (max-width: 767px) {
    justify-content: center;
  }
`;

export const LinkStyled = styled(Link)`
  display: inline;
  font-weight: 700;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(32)};
  ${ml(1.6)};
  color: rgb(${(p) => p.theme.colors.primary});
`;

export const StructuresContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: stretch;
`;

export const ServicesSectionStyled = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 30px;
  width: 100%;
`;

export const ServicesListWrapper = styled.div`
  padding: 30px;
  margin: 20px -30px;
`;

export const ServicesListStyled = styled.ul`
  padding: 0;
  list-style-type: none;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  max-width: 1500px;
  margin: 0 auto;
  gap: 20px;
  justify-content: center;

  li {
    width: 275px;
    height: 320px;
    @media only screen and (max-width: 767px) {
      & {
        width: 100%;
        height: auto;
      }
    }
  }
`;

export const NoServicesAvailableLabelStyled = styled.h3`
  font-weight: 700;
  font-size: ${pxToRem(24)};
  line-height: ${pxToRem(32)};
  text-align: center;
  width: 100%;
  ${mt(16)};
  color: ${neutralMedium};
  white-space: pre;
`;
