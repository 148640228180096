// import { useTranslation } from 'react-i18next';
import {
  useState,
  useEffect,
  SyntheticEvent,
  useRef,
  useCallback,
} from 'react';
import { useSwipeable, SwipeableHandlers } from 'react-swipeable';
import { AnimalsPasswordStyle } from './AnimalsPassword.styles';

/* const ***************************/

export const SPECIES: Array<string> = [
  'Grenouille',
  'Chien',
  'Cochon',
  'Mouton',
  'Souris',
  'Chat',
  // 'Renard',
  // 'Singe',
  // 'Hippopotame',
  // 'Panda',
  // 'Vache',
  // 'Cerf',
  // 'Elephant',
  // 'Koala',
];
export const ANIMALS_NUMBER: number = 4;

/* AnimalsPassword main Component ***************************/

const AnimalsPassword = ({
  password,
  changeCallback,
}: {
  password?: string; // set password show password (ex. '4-Chat-Hippo-Chat-Hippo')
  changeCallback?: (value: string) => void; // if this callbak is defined, password can be modified
}) => {
  const [tiles, setTiles] = useState<Array<number>>([]);

  useEffect(() => {
    if (password) {
      const initialTiles: Array<number> = password
        ?.split('-')
        .slice(1)
        .map((animal: string) => SPECIES.indexOf(animal));
      setTiles(initialTiles);
    } else {
      const someInitialTiles: Array<number> = Array(ANIMALS_NUMBER)
        .fill(0)
        .map((elt, num) => num % SPECIES.length);
      setTiles(someInitialTiles);
    }
  }, [password]);

  const onChange = useCallback((pos: number, value: number): void => {
    setTiles((tiles) => {
      let tilesCp = [...tiles];
      tilesCp.splice(pos, 1, value);
      return tilesCp;
    });
  }, []);

  useEffect(() => {
    if (changeCallback && tiles.length === ANIMALS_NUMBER) {
      changeCallback(
        ANIMALS_NUMBER + '-' + tiles.map((tile) => SPECIES[tile]).join('-'),
      );
    }
  }, [tiles, changeCallback]);

  return (
    <AnimalsPasswordStyle>
      <div className="tiles">
        {tiles.map((tile, num) => (
          <div
            key={num}
            className="tilesColumn"
            style={{ width: `${100 / ANIMALS_NUMBER}%` }}
          >
            <Tile
              id={num}
              images={SPECIES}
              initialValue={tiles[num]}
              onChange={changeCallback ? onChange : undefined}
            />
          </div>
        ))}
      </div>
    </AnimalsPasswordStyle>
  );
};

/* Tile Component ***************************/

const Tile = ({
  id,
  images,
  initialValue,
  onChange,
}: {
  id: number;
  images: Array<string>;
  initialValue: number;
  onChange?: (id: number, value: number) => void;
}) => {
  const [value, setValue] = useState<number>(initialValue);
  const [animationStatus, setAnimationStatus] = useState<string>('fixed'); // fixed, movingUp, movingDown
  const tileRef = useRef<HTMLDivElement>(null);
  const animationDuration: number = 500; // ms // delay to synchronize with css transition-delay

  // trigger animation by changing animationStatus
  // (triggering useEffect and changing className)
  const showPrev = useCallback((): void => {
    if (!onChange) return;
    setAnimationStatus((animationStatus) =>
      animationStatus === 'fixed' ? 'movingDown' : animationStatus,
    );
  }, [onChange]);

  const showNext = useCallback((): void => {
    if (!onChange) return;
    setAnimationStatus((animationStatus) =>
      animationStatus === 'fixed' ? 'movingUp' : animationStatus,
    );
  }, [onChange]);

  // click events
  const onClickPrev: (e: SyntheticEvent) => void = (e: SyntheticEvent) => {
    if (!onChange) return;
    e.preventDefault();
    showPrev();
  };

  const onClickNext: (e: SyntheticEvent) => void = (e: SyntheticEvent) => {
    if (!onChange) return;
    e.preventDefault();
    showNext();
  };

  // swipe events (with react-swipeable)
  const handlers: SwipeableHandlers = useSwipeable({
    onSwipedDown: () => showPrev(),
    onSwipedUp: () => showNext(),
    swipeDuration: 500,
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  // mouse wheel events
  // onWhell attribute cannot be used in html because react uses default passive mode.
  // passive mode cannot prevent page scroll because e.preventDefault() fires an exception.
  // so when component is mounted or unmounted, we set/unset listener for wheel event.
  const onWheelEvent = useCallback(
    (e: WheelEvent) => {
      if (!onChange) return;
      e.preventDefault(); // prevent page scroll
      if (e.deltaY > 0) {
        showPrev();
      } else if (e.deltaY < 0) {
        showNext();
      }
    },
    [onChange, showPrev, showNext],
  );

  useEffect(() => {
    const tag = tileRef?.current;
    tag?.addEventListener('wheel', onWheelEvent, {
      passive: false, // disable passive mode
    });
    return () => {
      tag?.removeEventListener('wheel', onWheelEvent);
    };
  }, [onWheelEvent]);

  // when animationStatus changes,
  // if not fixed, then reset animationStatus after a delay (when css transition is finished)
  // and set new value
  useEffect(() => {
    if (animationStatus !== 'fixed') {
      setTimeout(() => {
        if (animationStatus === 'movingUp') {
          setValue((value) => (value + 1) % images.length);
        } else if (animationStatus === 'movingDown') {
          setValue((value) => (value + images.length - 1) % images.length);
        }
        setAnimationStatus(() => 'fixed');
      }, animationDuration);
    }
    return;
  }, [animationStatus, images.length]);

  // fires callback if value has changed
  useEffect(() => {
    if (onChange) {
      onChange(id, value);
    }
  }, [id, value, onChange]);

  return (
    <div className={`tile ${animationStatus}`} {...handlers} ref={tileRef}>
      {onChange && (
        <button className="prev" onClick={onClickPrev}>
          &lt;
        </button>
      )}
      <div className="frame">
        <div className="slider">
          <div
            className="before"
            style={{
              backgroundImage: `url(/images/AnimalsPassword/${
                images[(value + images.length - 1) % images.length]
              }.svg)`,
            }}
          />
          <div
            className="current"
            style={{
              backgroundImage: `url(/images/AnimalsPassword/${images[value]}.svg)`,
            }}
          />
          <div
            className="after"
            style={{
              backgroundImage: `url(/images/AnimalsPassword/${
                images[(value + images.length + 1) % images.length]
              }.svg)`,
            }}
          />
        </div>
      </div>
      {onChange && (
        <button className="next" onClick={onClickNext}>
          &gt;
        </button>
      )}
      {!onChange &&
        (images[value] ? (
          <p className="animalName">{images[value]}</p>
        ) : (
          <p className="animalName">?</p>
        ))}
    </div>
  );
};

export default AnimalsPassword;
