import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { getLearnerClassroomNb } from 'api';
import { routes } from 'router/routes';
import { Link, generatePath } from 'react-router-dom';
import { StructureClassroomType } from 'models';
import { useSpacePath } from 'hooks';

/** StudentClassroomLine COMPONENT *********************************************************/

const StudentsClassroomsLine = ({
  structureId,
  classroom,
  onModify,
  onDelete,
  disabled,
  isDeleting,
}: {
  structureId: number;
  classroom: StructureClassroomType;
  onModify: (classroom: StructureClassroomType) => void;
  onDelete: (classroom: StructureClassroomType) => void;
  disabled: boolean;
  isDeleting: boolean;
}) => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.students',
  });
  const spacePath = useSpacePath();
  const [count, setCount] = useState<number | undefined | 'loading' | 'error'>(
    undefined,
  );

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const getLearnerClassroomNbQuery = useMutation(
    'getLearnerClassroomNb' + classroom.classroom_id,
    getLearnerClassroomNb,
    {
      retry: false,
      onSuccess: (data) => {
        setCount(data.data >= 0 ? data.data : undefined);
      },
      onError: () => {
        setCount('error');
      },
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const onModifyClick = useCallback(() => {
    onModify(classroom);
  }, [onModify, classroom]);

  const onDeleteClick = useCallback(() => {
    onDelete(classroom);
  }, [onDelete, classroom]);

  /* -- Return ------------------------------------------------------------- */

  useEffect(() => {
    if (
      classroom.classroom_id &&
      !getLearnerClassroomNbQuery.isLoading &&
      !getLearnerClassroomNbQuery.data &&
      count === undefined
    ) {
      setCount('loading');
      getLearnerClassroomNbQuery.mutate({
        structure_id: structureId,
        classroom_id: classroom.classroom_id,
      });
    }
  }, [structureId, classroom, getLearnerClassroomNbQuery, count]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <tr>
      <td>{classroom.level}</td>
      <td>{classroom.name}</td>
      <td>
        {count === 'loading'
          ? '...'
          : count === 'error'
          ? '&times'
          : count !== undefined
          ? count
          : ''}
      </td>
      <td className="text-end">
        <Link
          to={generatePath(`/${spacePath}${routes.studentsAccounts.path}`, {
            classroomId: classroom.classroom_id,
          })}
        >
          <button type="button" className="btn btn-primary" disabled={disabled}>
            {t('manageStudents')}
          </button>
        </Link>
        &nbsp;
        <button
          type="button"
          className="btn btn-primary"
          onClick={onModifyClick}
          disabled={disabled}
        >
          {t('modify')}
        </button>
        &nbsp;
        <button
          type="button"
          className="btn btn-outline-dark"
          onClick={onDeleteClick}
          disabled={disabled}
        >
          {' '}
          {isDeleting && (
            <>
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />{' '}
            </>
          )}
          {t('deleteClassroom')}
        </button>
      </td>
    </tr>
  );
};

export default StudentsClassroomsLine;
