import styled from 'styled-components';

export const ClassroomsStyled = styled.div`
  height: 100%;

  .visual {
    background-color: lightgrey;
    background-color: rgba(${({ theme }) => theme.colors.primaryLight});
    min-height: 160px;
  }
  th {
    font-weight: normal;
  }
  td {
    vertical-align: middle;
  }
  .inputIcon {
    width: 25px;
    height: 25px;
    margin: 0px;
    object-fit: contain;
  }
`;
