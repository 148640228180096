import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { RecoilRoot } from 'recoil';
import { ToastContainer, toast } from 'react-toastify';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ThemeProvider } from 'styled-components';
// import 'bootstrap/dist/css/bootstrap.css';
import 'index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { GlobalFonts } from '@maskott/tactileo.designsystem';

import MainRouter from 'router/MainRouter';

import { DefaultTheme } from 'styled-components';

import WithAuth from 'components/core/WithAuth';
import WithUserProfile from 'components/core/WithUserProfile';
import WithSpace from 'components/core/WithSpace';
import WithStructures from 'components/core/WithStructures';
import { INITIAL_LANGUAGE, LOADING_THEME } from 'appConstants';
import 'i18n';
import { GlobalStyle } from './App.styles';
import { setFavicon } from 'utils';

const queryClient = new QueryClient();

const App = () => {
  const { i18n } = useTranslation();

  const [theme, setTheme] = useState<DefaultTheme>(LOADING_THEME);
  useEffect(() => {
    if (theme.colors.primary) {
      setFavicon({ color: theme.colors.primary });
    }
  }, [theme.colors.primary]);

  useEffect(() => {
    i18n.changeLanguage(INITIAL_LANGUAGE);
  }, [i18n]);

  return (
    <QueryClientProvider client={queryClient}>
      <RecoilRoot>
        <WithAuth>
          <WithUserProfile>
            <WithStructures>
              <WithSpace onThemeChange={setTheme}>
                {theme !== undefined ? (
                  <ThemeProvider theme={theme}>
                    <GlobalFonts />
                    <GlobalStyle />
                    <MainRouter />
                    <ToastContainer
                      hideProgressBar
                      autoClose={3000}
                      closeButton={false}
                      theme="colored"
                      position={toast.POSITION.BOTTOM_CENTER}
                      style={{ width: '400px' }}
                    />
                  </ThemeProvider>
                ) : (
                  <> </>
                )}
              </WithSpace>
            </WithStructures>
          </WithUserProfile>
        </WithAuth>
      </RecoilRoot>
    </QueryClientProvider>
  );
};

export default App;
