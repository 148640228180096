import { useCallback, useState, useEffect } from 'react';
import { useNavigate, generatePath } from 'react-router-dom';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';

import CreateDemoAccountView from 'pages/public/CreateDemoAccount/CreateDemoAccount';
import { createDemoAccount, getMailinglists, getWayfStructure } from 'api';
import { SecurityIndicatorVariants } from 'appConstants';
import { validateEmail } from 'utils/tools';
import { routes } from 'router/routes';
import { useSpacePath, useCustomMatomo } from 'hooks';
import { Mailinglist, Subscription } from 'models';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

const CreateDemoAccount = () => {
  /* -- Const ------------------------------------------------------------- */

  const navigate = useNavigate();
  const sessionSpace = useRecoilValue(SessionSpaceState);
  const spacePath: string = useSpacePath();
  const { trackMatomoEvent } = useCustomMatomo();

  /* -- States ------------------------------------------------------------- */

  const [userName, setUserName] = useState<string>('');
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [userNameError, setUserNameError] = useState<string>();
  const [emailError, setEmailError] = useState<string>();
  const [personalInformationsRequired, setPersonalInformationsRequired] =
    useState<boolean>(false);
  const [emailExpirationAccepted, setEmailExpirationAccepted] =
    useState<boolean>(false);
  const [conditionsAccepted, setConditionsAccepted] = useState<boolean>(false);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [passwordQuality, setPasswordQuality] =
    useState<SecurityIndicatorVariants>();

  /* -- Queries and Mutations ------------------------------------------------------------- */

  const createDemoAccountMutation = useMutation(
    'createDemoAccount',
    createDemoAccount,
  );

  const getWayfStructureMutation = useMutation(
    'getWayfStructure',
    getWayfStructure,
    {
      retry: false,
    },
  );

  /* -- Callbacks ------------------------------------------------------------- */

  const toggleExpirationCheckbox = useCallback(
    () => setEmailExpirationAccepted((prevState) => !prevState),
    [setEmailExpirationAccepted],
  );
  const toggleConditionsCheckbox = useCallback(
    () => setConditionsAccepted((prevState) => !prevState),
    [setConditionsAccepted],
  );

  const isInputValid = useCallback((): boolean => {
    const requiredFieldsValid: boolean =
      !!userName &&
      !!password &&
      !!confirmPassword &&
      password === confirmPassword &&
      passwordQuality === SecurityIndicatorVariants.HARD &&
      conditionsAccepted;

    const atListOneChecked: boolean =
      emailExpirationAccepted ||
      !!subscriptions.filter((sub) => sub.accepted).length;

    const personalFieldsValid: boolean =
      (!personalInformationsRequired &&
        (!atListOneChecked || (atListOneChecked && validateEmail(email)))) ||
      (personalInformationsRequired &&
        !!firstName &&
        !!lastName &&
        validateEmail(email));

    return requiredFieldsValid && personalFieldsValid;
  }, [
    conditionsAccepted,
    confirmPassword,
    email,
    emailExpirationAccepted,
    password,
    passwordQuality,
    subscriptions,
    userName,
    personalInformationsRequired,
    firstName,
    lastName,
  ]);

  const onSubmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (
        !isInputValid() ||
        createDemoAccountMutation.isLoading ||
        !getWayfStructureMutation.data
      )
        return;

      createDemoAccountMutation.mutate({
        uai_code: getWayfStructureMutation.data.uai,
        username: userName.replace(/-demo$/, ''),
        last_name: lastName,
        first_name: firstName,
        email,
        password,
        space_key:
          sessionSpace?.space_key && sessionSpace.space_key !== 'default'
            ? sessionSpace.space_key
            : null,
        accepts_terms_of_use: conditionsAccepted,
        accepts_demo_expiry_email: emailExpirationAccepted,
        subscriptions: subscriptions,
      });
    },
    [
      userName,
      email,
      lastName,
      password,
      firstName,
      conditionsAccepted,
      emailExpirationAccepted,
      subscriptions,
      isInputValid,
      createDemoAccountMutation,
      sessionSpace?.space_key,
      getWayfStructureMutation?.data,
    ],
  );

  const onUsernameChange = useCallback((str: string) => {
    setUserName(() => {
      const suffixDeletion = /(-emo|-dmo|-deo|-dem)$/; // check if partial suffix deletion
      if (str.match(suffixDeletion)) {
        str = str.replace(suffixDeletion, '');
        str = str.substring(0, str.length - 1);
        str += '-demo';
      }
      const match: any = /(.*?)(-demo(.*))?$/.exec(str); // merge string before and after -demo
      return (match[1] || '') + (match[3] || '') + '-demo';
    });
  }, []);

  const toggleSubscription = useCallback(
    (id: string) => {
      const subs = subscriptions.map((sub) => {
        if (sub.identifier === id) sub.accepted = !sub.accepted;
        return sub;
      });
      setSubscriptions(subs);
    },
    [subscriptions],
  );

  /* -- Effects ------------------------------------------------------------- */

  useEffect(() => {
    setPersonalInformationsRequired(
      !!getWayfStructureMutation.data &&
        !getWayfStructureMutation.data.isGar &&
        !getWayfStructureMutation.data.tne,
    );
  }, [getWayfStructureMutation.data]);

  // // load structure
  useEffect(() => {
    if (sessionSpace) {
      if (sessionSpace.open_to_user_demo) {
        const urlParams = new URLSearchParams(window.location.search);
        const uai = urlParams.get('structure');
        if (uai) {
          if (
            !getWayfStructureMutation.data &&
            !getWayfStructureMutation.isLoading
          ) {
            getWayfStructureMutation.mutate({ uai, domain: '' });
          }
        } else {
          if (sessionSpace.space_key === 'default') {
            navigate(`/${routes.wayfDemo.path}`);
          } else {
            navigate(
              `/spaces/${sessionSpace.space_key}/${routes.wayfDemo.path}`,
            );
          }
        }
      } else {
        if (sessionSpace.space_key === 'default') {
          navigate(`/${routes.page404.path}`);
        } else {
          navigate(`/spaces/${sessionSpace.space_key}/${routes.page404.path}`);
        }
      }
    }
  }, [navigate, spacePath, getWayfStructureMutation, sessionSpace]);

  useEffect(() => {
    if (!createDemoAccountMutation.isSuccess) return;
    trackMatomoEvent({
      category: 'Setup',
      action: 'Create account',
      name: 'Demo',
    });

    if (createDemoAccountMutation.data?.activation_required && email) {
      navigate(
        generatePath(
          `/${spacePath}${routes.accountActivation.path}?username=${createDemoAccountMutation.data.username}`,
        ),
      );
    } else {
      navigate(`/${spacePath}${routes.confirmDemoAccount.path}`, {
        state: { userName: userName, password: password },
      });
    }
  }, [
    createDemoAccountMutation,
    trackMatomoEvent,
    spacePath,
    email,
    navigate,
    userName,
    password,
  ]);

  useEffect(() => {
    if (!createDemoAccountMutation.error) return;
    const reasons: string[] =
      (
        createDemoAccountMutation.error as AxiosError<{
          error_message?: string;
          reasons?: string[];
        }>
      )?.response?.data?.reasons || [];
    const newEmailError = reasons?.find((err) => err.includes('email'));
    const newUserNameError = reasons?.find((err) => err.includes('username'));

    setEmailError(newEmailError);
    setUserNameError(newUserNameError);
  }, [createDemoAccountMutation]);

  useEffect(() => {
    const spaceKey: string | null =
      sessionSpace?.space_key && sessionSpace.space_key !== 'default'
        ? sessionSpace.space_key
        : null;
    if (!spaceKey) return;
    getMailinglists(spaceKey).then((mls: Mailinglist[]) => {
      if (mls) {
        setSubscriptions(
          mls
            .filter((ml: Mailinglist) => ml.open_to_demo_user)
            .map((ml: Mailinglist) => ({
              identifier: `${ml.identifier}`,
              accepted: false,
            })),
        );
      }
    });
  }, [sessionSpace?.space_key]);

  /* -- Return ------------------------------------------------------------- */

  return (
    <>
      {!!sessionSpace && (
        <CreateDemoAccountView
          personalInformationsRequired={personalInformationsRequired}
          userName={userName}
          setUserName={onUsernameChange}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          isExpirationCheckboxChecked={emailExpirationAccepted}
          toggleExpirationCheckbox={toggleExpirationCheckbox}
          conditionsChecked={conditionsAccepted}
          toggleConditionsCheckbox={toggleConditionsCheckbox}
          subscriptions={subscriptions}
          toggleSubscription={toggleSubscription}
          setPasswordQuality={setPasswordQuality}
          isInputValid={isInputValid()}
          isLoading={createDemoAccountMutation.isLoading}
          onSubmit={onSubmit}
          userNameError={userNameError}
          emailError={emailError}
        />
      )}
    </>
  );
};

export default CreateDemoAccount;
