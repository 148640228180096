import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FormInput } from '@maskott/tactileo.designsystem';
import { Spinner, FormCheckbox, FormPasswordInput } from 'components/atoms';
import { PublicLayout } from 'components/layout';
import {
  EMAIL_ERROR,
  SecurityIndicatorVariants,
  USER_NAME_ERROR,
} from 'appConstants';

import { useMedia, useSpacePath } from 'hooks';
import { routes } from 'router/routes';
import { Subscription } from 'models';

import {
  Field,
  CreateDemoAccountForm,
  FieldLabel,
  CreateDemoAccountPage,
  SmallLinkStyled,
  CheckboxWrapperStyledTop,
  CreateDemoAccountButton,
  SmallText,
  NameFieldsWrapper,
  PasswordFieldsWrapper,
  CheckboxWrapper,
  CheckBoxLabelStyled,
  CancelButton,
  ButtonsWrapper,
  FieldError,
  FieldSet,
  Legend,
  SmallAlert,
} from 'pages/public/CreateDemoAccount/CreateDemoAccount.styles';

import { generatePath } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { SessionSpaceState } from 'recoilTools';

interface CreateDemoAccountProps {
  personalInformationsRequired: boolean;
  userName: string;
  setUserName: (value: string) => unknown;
  firstName: string;
  setFirstName: (value: string) => unknown;
  lastName: string;
  setLastName: (value: string) => unknown;
  email: string;
  setEmail: (value: string) => unknown;
  password: string;
  setPassword: (value: string) => unknown;
  confirmPassword: string;
  setConfirmPassword: (value: string) => unknown;
  setPasswordQuality: (quality?: SecurityIndicatorVariants) => unknown;
  isExpirationCheckboxChecked: boolean;
  toggleExpirationCheckbox: () => void;
  conditionsChecked: boolean;
  toggleConditionsCheckbox: () => any;
  subscriptions: Subscription[];
  toggleSubscription: (identifier: string) => void;
  isInputValid?: boolean;
  isLoading: boolean;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => unknown;
  userNameError?: string;
  emailError?: string;
}

const CreateDemoAccount = ({
  personalInformationsRequired,
  userName,
  setUserName,
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  setPasswordQuality,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  isExpirationCheckboxChecked,
  toggleExpirationCheckbox,
  conditionsChecked,
  toggleConditionsCheckbox,
  subscriptions,
  toggleSubscription,
  isInputValid,
  onSubmit,
  isLoading,
  userNameError,
  emailError,
}: CreateDemoAccountProps) => {
  const spacePath: string = useSpacePath();

  const { t, i18n } = useTranslation('translation', {
    keyPrefix: 'user.auth.createAccount',
  });
  const { t: tDemo } = useTranslation('translation', {
    keyPrefix: 'user.auth.createDemoAccount',
  });

  const sessionSpace = useRecoilValue(SessionSpaceState);

  const { isLargerThan767 } = useMedia();
  const navigate = useNavigate();

  const CreateButtonIcon = useMemo(
    () => (isLoading ? Spinner : undefined),
    [isLoading],
  );

  const createButtonImgSide = (() => {
    if (isLoading) return 'right';
    if (!isInputValid) return 'left';

    return undefined;
  })();

  return (
    <PublicLayout
      backButtonText={t('return')}
      title={tDemo('accountCreation')}
      breadcrumb={[
        {
          name: t('accountCreation'),
        },
      ]}
    >
      <CreateDemoAccountPage>
        <p>{sessionSpace?.description}</p>
        <br />
        <CreateDemoAccountForm onSubmit={onSubmit}>
          <Field>
            <FieldLabel htmlFor="UserNameInput">{t('userName')}</FieldLabel>
            <FormInput
              id="UserNameInput"
              placeholder={t('userNameExample') + '-demo'}
              leftIcon="user"
              state={null}
              value={userName}
              onChange={setUserName}
            />
            {userNameError && (
              <FieldError>
                {userNameError === USER_NAME_ERROR
                  ? t('userNameError')
                  : userNameError}
              </FieldError>
            )}
          </Field>
          <PasswordFieldsWrapper>
            <FieldLabel htmlFor="PasswordInput">{t('password')}</FieldLabel>
            <SmallText>{t('passwordTooltip')}</SmallText>
            <FormPasswordInput
              id="PasswordInput"
              placeholder={t('password')}
              state={null}
              value={password}
              qualityIndicator
              onChange={setPassword}
              onQualityChanged={setPasswordQuality}
            />
            <FieldLabel htmlFor="PasswordInput">
              {t('passwordConfirmation')}
            </FieldLabel>
            <FormPasswordInput
              id="ConfirmPasswordInput"
              placeholder={t('passwordConfirmation')}
              state={null}
              value={confirmPassword}
              qualityIndicator={password === confirmPassword}
              onChange={setConfirmPassword}
            />
            {confirmPassword && password !== confirmPassword && (
              <SmallAlert>{t('passwordsDifferent')}</SmallAlert>
            )}
          </PasswordFieldsWrapper>

          <FieldSet
            className={personalInformationsRequired ? 'required' : 'optional'}
          >
            {!personalInformationsRequired && (
              <Legend>{tDemo('optionalInformations')}</Legend>
            )}
            <NameFieldsWrapper isLargerThan767={isLargerThan767}>
              <Field>
                <FieldLabel htmlFor="FirstNameInput">
                  {t('firstName')}
                </FieldLabel>
                <FormInput
                  id="FirstNameInput"
                  placeholder={t('firstName')}
                  state={null}
                  value={firstName}
                  onChange={setFirstName}
                />
              </Field>
              <Field>
                <FieldLabel htmlFor="LastNameInput">{t('lastName')}</FieldLabel>
                <FormInput
                  placeholder={t('lastName')}
                  id="LastNameInput"
                  state={null}
                  value={lastName}
                  onChange={setLastName}
                />
              </Field>
            </NameFieldsWrapper>
            <NameFieldsWrapper isLargerThan767={isLargerThan767}>
              <Field>
                <FieldLabel htmlFor="EmailInput">{t('email')} </FieldLabel>
                <FormInput
                  id="EmailInput"
                  placeholder={t('emailExample')}
                  leftIcon="email"
                  state={
                    emailError ||
                    ((isExpirationCheckboxChecked ||
                      subscriptions.filter((sub) => sub.accepted).length) &&
                      !email)
                      ? 'error'
                      : null
                  }
                  value={email}
                  onChange={setEmail}
                />
                {emailError && (
                  <FieldError>
                    {emailError === EMAIL_ERROR ? t('emailError') : emailError}
                  </FieldError>
                )}
              </Field>
            </NameFieldsWrapper>

            <div>
              {
                <CheckboxWrapper>
                  <FormCheckbox
                    id="expirationCheckbox"
                    checked={isExpirationCheckboxChecked}
                    toggleCheckbox={toggleExpirationCheckbox}
                  />
                  <CheckBoxLabelStyled htmlFor="expirationCheckbox">
                    {personalInformationsRequired
                      ? tDemo('iWantExpirationPersonnalsRequired', {
                          space: sessionSpace?.name || '',
                        })
                      : tDemo('iWantExpiration', {
                          space: sessionSpace?.name || '',
                        })}
                  </CheckBoxLabelStyled>
                </CheckboxWrapper>
              }
              {subscriptions.map((subscription: Subscription) => (
                <CheckboxWrapper key={subscription.identifier}>
                  <FormCheckbox
                    id={`subscribe_${subscription.identifier}`}
                    checked={subscription.accepted || false}
                    toggleCheckbox={() => {
                      toggleSubscription(subscription.identifier);
                    }}
                  />
                  <CheckBoxLabelStyled
                    htmlFor={`subscribe_${subscription.identifier}`}
                  >
                    {i18n.exists(
                      `user.auth.createAccount.mailinglists.label_${subscription.identifier}`,
                    )
                      ? t(`mailinglists.label_${subscription.identifier}`)
                      : t('mailinglists.label_default', {
                          name: subscription.identifier,
                        })}
                  </CheckBoxLabelStyled>
                </CheckboxWrapper>
              ))}
            </div>
          </FieldSet>

          <CheckboxWrapperStyledTop>
            <FormCheckbox
              id="TermsAndConditionsCheckbox"
              checked={conditionsChecked}
              toggleCheckbox={() => {
                toggleConditionsCheckbox();
              }}
            />
            <CheckBoxLabelStyled htmlFor="TermsAndConditionsCheckbox">
              {t('iHaveRead')}
              <ul>
                {
                  <li>
                    <SmallLinkStyled
                      target="_blank"
                      to={generatePath(`/${spacePath}${routes.cguPage.path}`, {
                        space: sessionSpace?.space_key,
                      })}
                    >
                      {t('serviceConditions')}{' '}
                      {sessionSpace?.name || '[no name]'}
                    </SmallLinkStyled>
                  </li>
                }
                <li>
                  <SmallLinkStyled
                    target="_blank"
                    to={`/${routes.dataProtection.path}`}
                  >
                    {t('personalDatas')}
                  </SmallLinkStyled>
                </li>
              </ul>
            </CheckBoxLabelStyled>
          </CheckboxWrapperStyledTop>
          <ButtonsWrapper isLargerThan767={isLargerThan767}>
            <CancelButton
              state="secondary"
              label={t('cancel')}
              disabled={isLoading}
              onClick={() => navigate('..')}
            />
            <CreateDemoAccountButton
              type="submit"
              state="primary"
              label={t('createMyAccount')}
              disabled={!isInputValid}
              Icon={CreateButtonIcon}
              imgSide={createButtonImgSide}
            />
          </ButtonsWrapper>
        </CreateDemoAccountForm>
      </CreateDemoAccountPage>
    </PublicLayout>
  );
};

export default CreateDemoAccount;
