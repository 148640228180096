import {
  EyeCloseIcon,
  EyeIcon,
  LockIcon,
} from '@maskott/tactileo.designsystem';
import { error } from 'assets/colors';
import styled, { css } from 'styled-components';

const iconStyles = css`
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  width: 20px;
  height: 20px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
`;

export const FormInputStyle = styled.input`
  box-sizing: border-box;
  height: 46px;
  width: 100%;
  border-radius: 10px;
  padding-left: 44px;
  color: black;
  border: 1px solid rgb(234, 237, 241);
  background-color: rgb(249, 250, 251);
  display: inline-flex;
  align-items: center;
  text-align: center;
  flex-direction: row;
  text-align: left;
  outline: none;
  &&[type='password'] {
    font: small-caption;
  }
  border: 1px solid rgb(${(props) => props.theme.colors.primary});
  .errorState {
    border: 1px solid ${error};
  }
`;

export const ContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 6rem;
  input.easy {
    border: 1px solid rgb(193, 31, 31);
    box-shadow: 0px 1px 0px rgb(193, 31, 31);
  }
  input.medium {
    border: 1px solid rgb(193, 166, 31);
    box-shadow: 0px 1px 0px rgb(193, 166, 31);
  }
  input.hard {
    border: 1px solid rgb(147, 193, 31);
    box-shadow: 0px 1px 0px rgb(147, 193, 31);
  }
  input.errorState {
    border: 1px solid ${error};
  }
`;

export const EyeIconStyled = styled(EyeIcon)`
  right: 10px;
  ${iconStyles}
`;
export const EyeCloseIconStyled = styled(EyeCloseIcon)`
  right: 10px;
  ${iconStyles}
`;
export const LockIconStyled = styled(LockIcon)`
  left: 10px;
  ${iconStyles}
`;
export const EyeLeftIconStyled = styled(EyeIcon)`
  left: 10px;
  ${iconStyles}
`;
export const SecurityIndicatorsContainerStyle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  padding-left: 10px;
  padding-right: 10px;
  .easy {
    background-color: rgb(193, 31, 31);
  }
  .medium {
    background-color: rgb(193, 166, 31);
  }
  .hard {
    background-color: rgb(147, 193, 31);
  }
`;

export const SecurityIndicatorItemStyle = styled.div`
  flex: 1;
  height: 4px;
  border-radius: 8px;
`;
