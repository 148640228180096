import { Modal } from '@maskott/tactileo.designsystem';
import {
  error,
  neutralDarker,
  neutralLight,
  secondaryText,
} from 'assets/colors';
import { RoundedCornerButton } from 'components/atoms';
import styled from 'styled-components';
interface StyledComponentsProps {
  isLargerThan767: boolean;
  primaryColor?: string;
}
export const NewsletterEdugoPageStyled = styled.div<StyledComponentsProps>`
  width: ${({ isLargerThan767 }) => (isLargerThan767 ? '450px' : '100%')};
  padding: ${({ isLargerThan767 }) => (isLargerThan767 ? '0' : '0 20px')};
  margin: auto;
  flex: 1;
  display: flex;
  flex-direction: column;
  ${({ isLargerThan767, primaryColor }) =>
    isLargerThan767
      ? `
    input {
      border: 1px solid rgb(${primaryColor});
      box-shadow: 0px 1px 0px rgb(${primaryColor});
    }
    input:focus {
      border: 2px solid rgb(${primaryColor});
      background-color: ${neutralLight};
    }
    .errorState {
      border: 2px solid ${error}!important;
      box-shadow: 0px 1px 0px ${error};
      background-color: #fce8e8 !important;
    }`
      : ''}
`;

export const TitleStyled = styled.h3`
  color: ${neutralDarker};
  font-weight: bold;
  font-size: 24px;
  line-height: 24px;
  letter-spacing: 0.35px;
  margin-bottom: 8px;
`;

export const BackButtonStyled = styled.button`
  margin-top: 32px;
  margin-bottom: 16px;
  border: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  background: none;
  color: ${secondaryText};
  font-weight: 900;
  font-size: 12px;
  line-height: 17px;
  text-transform: uppercase;
`;

export const BackImageStyled = styled.img`
  width: 12px;
  height: 12px;
`;

export const FieldStyled = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export const FieldErrorStyled = styled.span`
  font-size: 12px;
  line-height: 15px;
  color: ${error};
`;

export const LabelStyled = styled.label`
  color: black;
`;

export const SmallTextStyled = styled.p`
  font-size: 12px;
  line-height: 15px;
  color: black;
  margin: 0;
`;

export const ConfirmButtonStyled = styled(RoundedCornerButton)`
  margin-top: 24px;
  align-self: center;
`;

export const NewPasswordFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 16px;
`;

export const ModalTextStyled = styled.h3`
  margin: 0;
  width: 100%;
  font-weight: 900;
  font-size: 32px;
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.35px;
  color: black;
`;

export const ModalStyled = styled(Modal)`
  button {
    border-radius: 2.5rem;
  }
`;

export const CheckImageStyled = styled.img`
  width: 48px;
  height: 48px;
  margin-bottom: 32px;
`;

export const ModalContentStyled = styled.div`
  padding: 143px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
