import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PublicLayout } from 'components/layout';
import {
  Page404Styled,
  ButtonsBlockStyled,
  ButtonStyled,
} from './Page404.styles';
import { useSpacePath } from 'hooks';
import { routes } from 'router/routes';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { SessionSpaceState, ProfileState } from 'recoilTools';

const Page404 = () => {
  const { t } = useTranslation('translation', {
    keyPrefix: 'user.auth.page404',
  });

  const spacePath: string = useSpacePath();
  const navigate = useNavigate();
  const setSessionSpaceState = useSetRecoilState(SessionSpaceState);
  const profileState = useRecoilValue(ProfileState);

  useEffect(() => {
    setSessionSpaceState(undefined);
  }, [setSessionSpaceState]);

  const onClick = useCallback(() => {
    navigate(
      profileState?.id
        ? `/${routes.home.path}`
        : `/${spacePath}${routes.login.path}`,
    );
  }, [navigate, spacePath, profileState]);

  return (
    <PublicLayout
      title={t('title')}
      breadcrumb={[
        {
          name: t('title'),
        },
      ]}
    >
      <Page404Styled>
        <br />
        <br />
        <br />
        <br />
        <div className="instruction">{t('instruction')}</div>

        <ButtonsBlockStyled>
          <ButtonStyled
            state="primary"
            type="submit"
            label={profileState?.id ? t('home') : t('login')}
            onClick={onClick}
            imgSide={undefined}
          />
        </ButtonsBlockStyled>
      </Page404Styled>
    </PublicLayout>
  );
};

export default Page404;
